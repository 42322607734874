// AddressAutocomplete.jsx
import React, { useState } from 'react';
import { IoLocationSharp } from "react-icons/io5";

const AddressAutocomplete = ({ onAddressSelect, handleInputChangeAll }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        console.log("AddressAutocomplete value :", value)
        console.log("AddressAutocomplete e.target :", e.target)
        updateQuery(value);
        handleInputChangeAll(e);
    };

    function updateQuery(value) {
        setQuery(value);
        if (value.length > 2) {
            fetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    }

    const fetchSuggestions = async (query) => {
        const url = `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`;
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Réponse réseau non ok.');
            const data = await response.json();
            setSuggestions(data.features);
        } catch (error) {
            console.error("Erreur lors de la récupération des suggestions d'adresse :", error);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const fullAddress = suggestion.properties.label;
        setQuery(fullAddress);
        setSuggestions([]);
        const simulatedEvent = {
            target: {
                value: fullAddress,
                name: "address"
            }
        };
        handleInputChangeAll(simulatedEvent);
        if (onAddressSelect) {
            onAddressSelect(suggestion);
        }
    };

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const customInputStyle = {
        // boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)', // Ombre interne
        border: isFocused ? '3px solid #06094F' : '1px solid #d1d5db', // Bordure personnalisée
    };


    return (
        <>
            <div
                className={`flex items-center ${isFocused ? 'border-2 shadow-inner border-[#b5d4f4]' : 'border border-[#06094F] '} rounded-lg bg-white py-1 text-gray-800 w-full`}
                style={customInputStyle}
                onFocus={handleFocus}
                onBlur={handleBlur}
                tabIndex={0} // Permet de détecter le focus sur la div
            >
                <IoLocationSharp className="ml-2 text-lg" />
                <input
                    type="text"
                    value={query}
                    name="address"
                    id="adresse-f"
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Adresse"
                    className="rounded-lg w-full text-black flex-1 p-2 outline-none bg-white"
                />
            </div>
            {suggestions.length > 0 &&
                <ul className="text-input p-2">
                    {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)} className="cursor-pointer hover:bg-gray-100">
                            {suggestion.properties.label}
                        </li>
                    ))}
                </ul>
            }
        </>
    );
};

export default AddressAutocomplete;
