import React, { useState, useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import NestedCheckbox from "./NestedCheckbox";

const SelectedParentIds = ({ selectedParentIdsData, setSelectedParentIdsData }) => {

  const { parentIds, selectedParentIds } = useAppContext();


  const [tmpSelectedParents, setTmpSelectedParents] =
    useState(selectedParentIds);

  useEffect(() => {
    setTmpSelectedParents(selectedParentIds);
  }, [selectedParentIds]);

  const [names, setNames] = useState({});

  useEffect(() => {
    const new_names = { ...parentIds };
    console.log('new_name: ', new_names);
    setNames(new_names);
  }, [parentIds]);



  useEffect(() => {
    const parentIds_key = Object.keys(parentIds);
    console.log("parentIds_key: ", parentIds_key);
    const fiches_renforcement_key = parentIds_key.filter((key) =>
      key.startsWith("fiche_renforcement")
    );
    const legifrance_keys = parentIds_key.filter((key) =>
      key.startsWith("legitext")
    );
    const new_data = {
      fiche_renforcement: fiches_renforcement_key.reduce((obj, item) => {
        obj[item] = tmpSelectedParents.includes(item);
        return obj;
      }, {}),
      greffe_infogreffe: {
        guide_des_formalites_infogreffe: tmpSelectedParents.includes(
          "guide_des_formalites_infogreffe"
        ),

        "signatures-service-client_infogreffe": tmpSelectedParents.includes(
          "signatures-service-client_infogreffe"
        )
      },




      administration_publique: {
        bulletin_officiel_des_finances_publiques_bofip:
          tmpSelectedParents.includes(
            "bulletin_officiel_des_finances_publiques_bofip"
          ),
        ecologiegouvfr__politiques_publiques: tmpSelectedParents.includes(
          "ecologiegouvfr__politiques_publiques"
        ),
        travailemploigouvfr: tmpSelectedParents.includes(
          "travailemploigouvfr"
        ),
        economie_gouv: {
          economiegouvfr__fiches_pratiques_particuliers:
            tmpSelectedParents.includes(
              "economiegouvfr__fiches_pratiques_particuliers"
            ),
          economiegouvfr__fiches_pratiques_entreprises:
            tmpSelectedParents.includes(
              "economiegouvfr__fiches_pratiques_entreprises"
            ),
          dgccrf: tmpSelectedParents.includes(
            "dgccrf"
          ),
        },
        service_public: {
          vos_droits_entreprises: tmpSelectedParents.includes(
            "vos_droits_entreprises"
          ),
          vos_droits_particuliers: tmpSelectedParents.includes(
            "vos_droits_particuliers"
          ),
          vos_droits_solutions: tmpSelectedParents.includes(
            "vos_droits_solutions"
          ),
        },
        impots_gouv_fr: {
          impotsgouvfr_actualite: tmpSelectedParents.includes(
            "impotsgouvfr_actualite"
          ),
          impotsgouvfr_questions: tmpSelectedParents.includes(
            "impotsgouvfr_questions"
          ),
        },
      },

      jurisprudence: {
        avis_de_coordination_du_registre_du_commerce_et_des_societes_ccrcs: tmpSelectedParents.includes(
          "avis_de_coordination_du_registre_du_commerce_et_des_societes_ccrcs"
        ),
        jurisprudence_anil: tmpSelectedParents.includes(
          'jurisprudence_anil'
        )
      },

      legifrance: legifrance_keys.reduce((obj, item) => {
        obj[item] = tmpSelectedParents.includes(item);
        return obj;
      }, {}),
    };
    console.log("new_data", new_data);
    setSelectedParentIdsData(new_data);
  }, [parentIds, tmpSelectedParents]);

  return (
    <form className="flex flex-col items-center w-full">
      <div className="overflow-y-scroll max-h-80 mb-8">
        <NestedCheckbox data={selectedParentIdsData} setData={setSelectedParentIdsData} names={names} />
      </div>

    </form>
  );
};

export default SelectedParentIds;
