import React, { useState } from "react";
import SolutionsCarrousel from "./SolutionsCarrousel";
import { fetchData } from "../../utils/ask_api";

import { useColorsContext } from "../../context/GlobalContext";
import { FaStar } from "react-icons/fa";
import ContactExpertInformationForm from "./ContactExpertInformationForm";
const ListExperts = ({ experts }) => {

    const handle_expert_click = async (id) => {
        await fetchData(`click_on_expert/${id}`, null, "GET");
    }
    const [modalContactExpertInformationOpen, setModalContactExpertInformationOpen] = useState(false);



    return (

        <>
            {experts.length > 0 ?
                <div className={`w-[100%] right-panel-liens-utiles ${experts.length > 0 ? 'in' : ''}`}>
                    {/* <div className={`${subdomain == "infogreffe" ? "" : "hidden "} w-[100%] right-panel-liens-utiles ${experts.length > 0 ? 'in' : ''}`}> */}
                    <div className='right-panel-list-experts w-[100%] space-y-1 rounded-2xl bg-[#8EBAEA] p-2'>
                        <div className="flex space-x-2 px-4 items-center">
                            {/* <div className={`flex items-center justify-center h-8 w-8 rounded-full text-black bg-first-oliver`}>
                                <span className="text-lg font-bold">{experts.length}</span>
                            </div> */}
                            <div className={`font-bold text-[#06094F]`}>Se rapprocher du partenaire adapté</div>

                        </div>
                        <div className={`px-4 py-2 flex items-center gap-x-2 w-full rounded-md cursor-pointer  text-xs`}>
                            <span
                                htmlFor="setting-modal"
                                className={`px-2 py-1 flex w-full gap-x-2 rounded-md cursor-pointer bg-[#7FF9D8] hover:bg-[#54E1BB]`}
                            >
                                <div className="cursor-pointer duration-300 text-white text-xs">
                                    <FaStar fill="#06094F" />
                                </div>
                                <h1 className={`text-[#06094F] text-xs font-bold`}
                                    onClick={() => { setModalContactExpertInformationOpen(true) }}>
                                    Me référencer en tant qu'expert
                                </h1>
                            </span>
                        </div>

                        {experts.map((expert, index) => {
                            // Condition to hide blacklisted experts
                            // if (!expert?.black_list?.includes(subdomain)) {
                            return (
                                // JSX modifié
                                <div className="px-4 pb-2">
                                    <div className="right-panel-expert w-[100%] border-[2.2px] rounded-lg border-[#06094F]" key={index}>
                                        <div className="right-panel-expert-top relative"> {/* Ajout de relative ici */}
                                            <a href={expert.url_site} target='_blank' className="w-[25%] aspect-square rounded-lg flex items-center bg-white rounded">
                                                <img src={expert.url_logo} alt={expert.name} className='bg-white p-2 rounded-lg w-full h-auto' />
                                            </a>
                                            <div className="relative pt-1 ps-3 mt-[-5px] w-[75%]">
                                                <h1 className="text-lg text-[#06094F] font-bold">
                                                    <a href={expert.url_site} target='_blank' onClick={() => handle_expert_click(expert.id)}>{expert.name}</a>
                                                </h1>
                                                <p className="text-[#06094F] truncate">{expert.type_structure}</p>
                                                <div className="expertises-container"> {/* Nouveau conteneur */}
                                                    <div className="flex w-[100%] expertises mt-1">
                                                        {expert.expertises.map((expertise, expIndex) => (
                                                            <div key={`e${expIndex}`} className="text-sm mr-2 mb-1 text-[#06094F] bg-white rounded-xl px-2 whitespace-nowrap">{expertise}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <SolutionsCarrousel expert_id={expert.id} solutions={expert.solutions} handle_expert_click={handle_expert_click} />
                                    </div>
                                </div>
                            );
                            // }
                            // return null; // Return null if expert is blacklisted for the current subdomain
                        })}

                    </div>
                </div > : null
            }
            <ContactExpertInformationForm modalContactExpertInformationOpen={modalContactExpertInformationOpen} setModalContactExpertInformationOpen={setModalContactExpertInformationOpen} />

        </>


        // <div className="carousel-container relative w-full mx-auto">
        //   <div className="carousel-wrapper flex">
        //     {items.map((item, index) => (
        //       <div
        //         key={index}
        //         className={`carousel-item flex-none w-full ${
        //           index === currentIndex ? "visible" : "hidden"
        //         }`}
        //       >
        //         {item}
        //       </div>
        //     ))}
        //   </div>
        //   <button className="prev-btn absolute top-1/2 left-4 transform -translate-y-1/2" onClick={handlePrev}>
        //     &#8249;
        //   </button>
        //   <button className="next-btn absolute top-1/2 right-4 transform -translate-y-1/2" onClick={handleNext}>
        //     &#8250;
        //   </button>
        //   <div className="indicators absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        //     {items.map((_, index) => (
        //       <div
        //         key={index}
        //         className={`indicator h-2 w-2 rounded-full ${index === currentIndex ? "bg-blue-500" : "bg-gray-300"}`}
        //       ></div>
        //     ))}
        //   </div>
        // </div>
    );
};

export default ListExperts;


// import React, { useState } from 'react';

// const ListExperts = ({ experts }) => {
//     const [currentIndex, setCurrentIndex] = useState(0);

//     const handleNextClick = () => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % experts.length);
//     };

//     const handlePrevClick = () => {
//         setCurrentIndex((prevIndex) => (prevIndex - 1 + experts.length) % experts.length);
//     };


//     return (
//         <>
//             {experts.length > 0 ?
//                 <div className={`right-panel-liens-utiles ${experts.length > 0 ? 'in' : ''}`}>
//                     <div className='right-panel-list-experts'>
//                         <h1 className='text-orange-logo-color font-bold mb-2'>Experts pour vous accompagner</h1>

//                         {experts.map((expert, index) => (
//                             <div className="right-panel-expert" key={index}>
//                                 <div className="right-panel-expert-top">
//                                     <img src={expert.url_logo} alt="" className='bg-white p-2 w-16 h-16' />
//                                     <div className="right-panel-expert-top-right px-2">
//                                         <h1 className="text-blue-logo-color font-bold"><a href={expert.url_site} target='_blank'>{expert.name}</a></h1>
//                                         <p className="text-gray-500 w-44 truncate">{expert.type_structure}</p>
//                                     </div>
//                                 </div>
//                                 <div className="carousel-container relative w-full flex items-center justify-center">

//                                     <button className="carousel-button prev-button absolute left-2 top-1/2 transform -translate-y-1/2" onClick={handlePrevClick}>
//                                         &#9001;
//                                     </button>
//                                     <div className='w-56'>

//                                         {expert.solutions.map((solution, index) => (
//                                             // JSX structure for each solution here
//                                             <div key={index}
//                                             className={`carousel-slide transform transition-transform duration-300 ease-in-out ${index === currentIndex ? 'translate-x-0' : 'translate-x-full'
//                                                 }`}>
//                                                 <h3 className="text-blue-logo-color font-bold text-sm  w-60 truncate"><a href={solution.url} target='_blank'>{solution.titre}</a></h3>
//                                                 <div className="flex justify-between">
//                                                     <p className='text-white text-[0.6rem]'>{solution.type}</p>
//                                                     <p
//                                                         // data-BsLink45Degip-id={solution.prix.length > 20 ? "my-tooltip" : ""}
//                                                         // data-tooltip-content={solution.prix}
//                                                         // data-tooltip-place="bottom"

//                                                         className='text-[0.6rem] text-green-400'>{solution.prix.length > 20 ? `${solution.prix.substring(0, 20)}...` : solution.prix}</p>



//                                                 </div>
//                                                 <p className="text-[0.65rem] w-60 truncate">{solution.description}</p>

//                                             </div>

//                                         ))}
//                                     </div>
//                                     <button className="carousel-button next-button absolute right-2 top-1/2 transform -translate-y-1/2" onClick={handleNextClick}>
//                                         &#9002;
//                                     </button>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div> : null}
//         </>
//     )

// }

// export default ListExperts;
