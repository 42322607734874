import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a context with initial values
const ColorsContext = createContext();

// Create a provider component to wrap your app with the context provider
export const ColorsContextProvider = ({ children }) => {

    const subdomain = window.location.hostname.split('.')[0];

    // let link = document.querySelector("link[rel~='icon']");
    // if (subdomain == "infogreffe") {
    //     document.title = "infogreffe.oliver.legal | Notre illustre assistant juridique basé sur l'IA";
    //     link.href = 'https://oliver.legal/public/oliverInfogreffeFavicon.png';
    // }


    
    return (
        <ColorsContext.Provider value={{
            subdomain
        }}>
            {children}
        </ColorsContext.Provider>
    );
};

// Custom hook to access the context values
export const useColorsContext = () => {
    const context = useContext(ColorsContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};
