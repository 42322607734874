import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";
import { useAppContext } from "../../context/AppContext";
const Document = () => {
  const { parentIds } = useAppContext();

  const [nomDocument, setNomFiche] = useState("");
  const [contenu, setContenu] = useState("");

  const [nomUrl, setNomUrl] = useState("");
  const [url, setUrl] = useState("");

  const [parentId, setParentId] = useState("");
  const [links, setLinks] = useState([{ link: "", name: "" }]);

  const [datePublication, setDatePublication] = useState(""); // Added state for date

  const [statusMessage, setStatusMessage] = useState("");
  const [statusColor, setStatusColor] = useState("");

  const tmpDocumentParentIds = Object.keys(parentIds)
    .filter((key) => !key.startsWith("fiche_renforcement"))
    .sort((a, b) => parentIds[a].titre.localeCompare(parentIds[b].titre));

  const documentParentIds = tmpDocumentParentIds.reduce((obj, key) => {
    const valueWithKey = `${parentIds[key].titre} (${key})`;
    if (Object.values(obj).some((value) => value.startsWith(parentIds[key].titre))) {
      // If a similar value already exists, update all similar values by appending their keys
      for (let existingKey in obj) {
        if (obj[existingKey].startsWith(parentIds[key].titre)) {
          obj[existingKey] = `${parentIds[existingKey].titre} (${existingKey})`;
        }
      }
      obj[key] = valueWithKey;
    } else {
      // Otherwise, just set the value
      obj[key] = parentIds[key].titre;
    }
    return obj;
  }, {});

  const clearStatusMessage = () => {
    setStatusMessage("");
  };
  useEffect(() => {
    let timer;
    if (statusMessage) {
      timer = setTimeout(clearStatusMessage, 4000); // 4000 milliseconds = 4 seconds
    }
    return () => clearTimeout(timer); // This will clear the timer when the component unmounts or before setting a new timer
  }, [statusMessage]);

  const handleLinkChange = (index, field, value) => {
    const updatedLinks = [...links];
    updatedLinks[index][field] = value;
    setLinks(updatedLinks);
  };

  const handleAddLink = () => {
    setLinks([...links, { link: "", name: "" }]);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("fr-FR", options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if name and content fields are empty
    if (
      !nomDocument.trim() ||
      !contenu.trim() ||
      !parentId.trim() ||
      !nomUrl.trim() ||
      !url.trim()
    ) {
      setStatusMessage(
        "Veuillez remplir tous les champs nom, contenu et identifiant parent."
      );
      setStatusColor("red");
      return;
    }

    try {
      const payload = {
        name: nomDocument,
        content: contenu,
        useful_links: links,
        url_name: nomUrl,
        url: url,
        parent_id: parentId,
        date_update: new Date(),
        date_maj: formatDate(datePublication)
      };

      // Handle add logic here
      await fetchData("add_document_from_admin", payload, "POST");
      setStatusMessage("Document mis en ligne avec succès");

      setStatusColor("green");
    } catch (error) {
      setStatusMessage(
        "Erreur lors de la mise en ligne ou de la mise à jour du document"
      );
      setStatusColor("red");
      console.error("Error:", error);
    }
  };

  return (
    <div className="ficheRenforcement">
      <form className="form-control" onSubmit={handleSubmit}>
        <div className="ficheRenforcementForm">
          <div>
            <p>Titre</p>
            <input
              className="w-full p-1 input input-bordered	"
              value={nomDocument}
              onChange={(e) => setNomFiche(e.target.value)}
            />
          </div>
          <div>
            <p>Description</p>
            <textarea
              className="p-1 h-40 w-full input input-bordered"
              value={contenu}
              onChange={(e) => setContenu(e.target.value)}
            />
          </div>
          <div>
            <p>Titre source</p>
            <input
              className="w-full p-1 input input-bordered	"
              value={nomUrl}
              onChange={(e) => setNomUrl(e.target.value)}
            />
          </div>
          <div>
            <p>Url source</p>
            <input
              className="w-full p-1 input input-bordered	"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>

          <div>
            <p>Date de publication</p>
            <input
              type="date"
              className="w-full p-1 input input-bordered"
              value={datePublication}
              onChange={(e) => { setDatePublication(e.target.value); }}
            />
          </div>

          <div>
            <p>Identifiant Parent</p>
            <div className="w-[48%] flex">
              <select
                className="w-full p-3 bg-inherit border border-gray-300 rounded cursor-pointer"
                onChange={(e) => setParentId(e.target.value)}
                value={parentId}
              >
                <option value="">Sélectionner</option>
                {Object.keys(documentParentIds).map((parent_name, index) => (
                  <option key={index} value={parent_name}>
                    {documentParentIds[parent_name]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <p>Liens utiles</p>
            {links.map((link, index) => (
              <div key={index} className="flex mb-2">
                <input
                  className="w-[48%] p-1 mr-2 input input-bordered"
                  placeholder="Lien"
                  value={link.link}
                  onChange={(e) =>
                    handleLinkChange(index, "link", e.target.value)
                  }
                />
                <input
                  className="w-[48%] p-1 mr-2 input input-bordered"
                  placeholder="Nom"
                  value={link.name}
                  onChange={(e) =>
                    handleLinkChange(index, "name", e.target.value)
                  }
                />
                <button
                  type="button"
                  className="bg-red-500 text-white px-2 py-1 rounded"
                  onClick={() => handleRemoveLink(index)}
                >
                  -
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-green-500 text-white px-2 py-1 rounded"
              onClick={handleAddLink}
            >
              +
            </button>
          </div>
          {statusMessage && (
            <div style={{ color: statusColor, marginTop: "10px" }}>
              {statusMessage}
            </div>
          )}
          <button
            type="submit"
            className="p-1 rounded-md cursor-pointer btn text-base border btn-primary"
          >
            Mettre en ligne
          </button>
        </div>
      </form>
    </div>
  );
};

export default Document;
