import React, { useEffect, useState } from 'react';
import EditableRoweDevis from './EditableRoweDevis';
import { fetchData } from '../../utils/ask_api';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import Modal from '../Modal';
import logoInfogreffe from "../../assets/logoOliver_vanillaDark3.jpg";
import logoMesFormalites from "../../assets/MesFormalites.Legal4.png";
import { useAppContext } from '../../context/AppContext';



function FeesTable({ fraisAll, eDevisIsSand, showHonoraires }) {
    const { location } = useAppContext();

    const getPrixHT = (prix, isFranceMetropolitan) => {
        if (typeof prix === 'object') {
            return isFranceMetropolitan ? prix.fraisAnnonceLegaleCreation_FRmetro : prix.fraisAnnonceLegaleCreation_ReuMay;
        }
        return prix || 0;
    };


    // Filtrer et trier les clés en fonction de l'ordre défini
    const sortOrder = ['Prix', 'Frais', 'Taxe'];

    const getSortOrder = (key) => {
        console.log("fraisAll: ", fraisAll);
        const index = sortOrder.findIndex(item => fraisAll[key].description.includes(item));
        return index !== -1 ? index : sortOrder.length;
    };

    let keys = [];
    for (let key in fraisAll) {
        if (fraisAll.hasOwnProperty(key)) {
            if (showHonoraires ? key.startsWith('honoraires') : !key.startsWith('honoraires')) {
                keys.push(key);
            }
        }
    }

    const sortedKeys = keys.sort((a, b) => getSortOrder(a) - getSortOrder(b));

    console.log(sortedKeys);


    return (
        <>
            {sortedKeys.map(key => (
                <tr key={key}>
                    <td className='p-1 border-r border-s border-b border-black'>{fraisAll[key].description}</td>
                    {!eDevisIsSand && (
                        <>
                            <td className='p-1 border-r border-b text-right border-black'>{fraisAll[key]?.quantity || 1}</td>
                            <td className='p-1 border-r border-b text-right border-black'>{getPrixHT(fraisAll[key].prix, location.isFranceMetropolitan)} €</td>
                            <td className='p-1 border-r border-b text-right border-black'>{fraisAll[key].tva * 100} %</td>
                        </>
                    )}
                    <td className='p-1 border-r border-b text-right border-black'>{getPrixHT(fraisAll[key].prix, location.isFranceMetropolitan) * (fraisAll[key]?.quantity || 1)} €</td>
                    {!eDevisIsSand && (
                        <>
                            <td className="p-2 border-black py-1"></td>
                        </>
                    )}
                </tr>
            ))}
        </>
    );
}


const EditableeDevisTable = ({
    formality,
    rowsAccompanyingCostsA,
    rowsLegalCostsA,
    setModalDossierOpen,
    eDevisIsSand,
    updateFormalities
    // handleAddRow,
    // updateRow,
    // handleAddRowLegalCosts,
    // updateRowLegalCosts,
    // editStatusRowsAccompanyingCosts,
    // setEditingRowsAccompanyingCosts,
    // editStatusRowsLegalCosts,
    // setEditingRowsLegalCosts,
    // deleteRowAccompanyingCosts,
    // deleteRowLegalCosts,
}) => {

    const [fraisAll, setfraisAll] = useState(null);
    const [rowsAccompanyingCosts, setRowsAccompanyingCosts] = useState(rowsAccompanyingCostsA || []);
    const [rowsLegalCosts, setRowsLegalCosts] = useState(rowsLegalCostsA || []);

    const [editStatusRowsAccompanyingCosts, setEditStatusRowsAccompanyingCosts] = useState({});
    const [editStatusRowsLegalCosts, setEditStatusRowsLegalCosts] = useState({});
    const { subdomain, subDomains } = useAppContext();

    const handleAddRow = () => {
        const newRow = { id: rowsAccompanyingCosts.length + 1, description: '', qty: 1, unitPrice: 0, tax: 20 };
        setRowsAccompanyingCosts([...rowsAccompanyingCosts, newRow]);
        setEditStatusRowsAccompanyingCosts(prev => ({ ...prev, [newRow.id]: true }));
    };

    const updateRow = (id, updatedRow) => {
        const updatedRowsAccompanyingCosts = rowsAccompanyingCosts.map(row => row.id === id ? updatedRow : row);
        setRowsAccompanyingCosts(updatedRowsAccompanyingCosts);
    };

    const handleAddRowLegalCosts = () => {
        const newRow = { id: rowsLegalCosts.length + 1, description: '', qty: 1, unitPrice: 0, tax: 20 };
        setRowsLegalCosts([...rowsLegalCosts, newRow]);
        setEditStatusRowsLegalCosts(prev => ({ ...prev, [newRow.id]: true }));
    };

    const updateRowLegalCosts = (id, updatedRow) => {
        const updatedRowsLegalCosts = rowsLegalCosts.map(row => row.id === id ? updatedRow : row);
        setRowsLegalCosts(updatedRowsLegalCosts);
    };

    const setEditingRowsAccompanyingCosts = (id, editing) => {
        setEditStatusRowsAccompanyingCosts(prev => ({ ...prev, [id]: editing }));
    };

    const setEditingRowsLegalCosts = (id, editing) => {
        setEditStatusRowsLegalCosts(prev => ({ ...prev, [id]: editing }));
    };

    const deleteRowAccompanyingCosts = (id) => {
        const updatedRows = rowsAccompanyingCosts.filter(row => row.id !== id);
        setRowsAccompanyingCosts(updatedRows);
        const newEditStatus = { ...editStatusRowsAccompanyingCosts };
        delete newEditStatus[id];
        setEditStatusRowsAccompanyingCosts(newEditStatus);
    };

    const deleteRowLegalCosts = (id) => {
        const updatedRows = rowsLegalCosts.filter(row => row.id !== id);
        setRowsLegalCosts(updatedRows);
        const newEditStatus = { ...editStatusRowsLegalCosts };
        delete newEditStatus[id];
        setEditStatusRowsLegalCosts(newEditStatus);
    };

    console.log("formality: ", formality)
    console.log("editStatusRowsAccompanyingCosts : ", editStatusRowsAccompanyingCosts)
    console.log("editStatusRowsLegalCosts : ", editStatusRowsLegalCosts)

    const [totals, setTotals] = useState({ totalHT: 0, TVA: 0, totalTTC: 0 });
    const [isModalOpen, setModalOpen] = useState(false);


    const calculateTotals = () => {
        let totalHT = 0;
        let totalTVA = formality.montantTVA || 0;

        [...rowsAccompanyingCosts, ...rowsLegalCosts].forEach(row => {
            const lineTotal = row.qty * row.unitPrice;
            totalHT += lineTotal;
            totalTVA += lineTotal * (row.tax / 100);
        });
        totalHT += formality.montantHT;

        const totalTTC = totalHT + totalTVA;
        console.log("calculateTotals, eDevisIsSand: ", eDevisIsSand, "totalTTC :", totalTTC);
        if (eDevisIsSand) {
            setTotals({ totalHT: formality.montantHT, TVA: formality?.montantTVA || totalTVA, totalTTC: formality.montantTTC || totalTTC });
        } else {
            setTotals({ totalHT, TVA: totalTVA, totalTTC });
        }
    };

    const addeDevisFormality = async (formality, eDevisData) => {
        const uid = formality.id.replace(/\d{14}$/, '');
        const formalityRef = doc(db, `formalities/${uid}`);
        try {
            const docSnap = await getDoc(formalityRef);
            if (docSnap.exists()) {
                // Mise à jour du statut de la formalité
                let userData = docSnap.data();
                let formalityData = userData[formality.id];
                formalityData.status = "eDevis validés";
                formalityData["eDevis"] = eDevisData
                formalityData["montantHT"] = totals.totalHT
                formalityData["montantTTC"] = totals.totalTTC
                formalityData["montantTVA"] = totals.TVA
                await updateDoc(formalityRef, { [formality.id]: formalityData });

                console.log("le eDevis est ajouter à la formalite avec succes.");
            } else {
                console.error("La formalité spécifiée n'existe pas dans Firestore.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut et l'ajout du eDevis de la formalité dans Firestore:", error);
        }
    };

    const handleSendDevis = async () => {
        try {
            const eDevisData = {
                customer_last_name: formality.nom,
                customer_first_name: formality.prenom,
                customer_email: formality.email_contact,
                amount_devis: totals.totalTTC,
                rowsAccompanyingCosts: rowsAccompanyingCosts,
                rowsLegalCosts: rowsLegalCosts
            };
            console.log("fetch eDevisData:", eDevisData)
            const data = await fetchData("sand-eDevis", eDevisData, "POST");
            console.log(data);
            addeDevisFormality(formality, eDevisData)
            console.log("eDevis envoyer");
            // setModalDossierOpen(false);
            // setModalOpen(true);
            alert("L'eDevis est envoyé avec succès.")
            updateFormalities(formality.id);
            setModalDossierOpen(false);

        } catch (error) {
            console.error('Erreur lors de l\'envoi du eDevis:', error);
            alert('Échec de l\'envoi du eDevis.');
        }

    }

    // Appeler calculateTotals chaque fois que rowsAccompanyingCosts ou rowsLegalCosts change
    useEffect(() => {
        calculateTotals();
    }, [rowsAccompanyingCosts, rowsLegalCosts]);


    return (
        <>

            <div className="flex flex-col  bg-white rounded-lg">
                <table className="w-full text-left">
                    <thead className="text-gray-700">
                        <tr>
                            <th scope="col" className="p-1 border-b border-black font-bold py-2 w-2/3">Honoraires d'accompagnement</th>
                            {!eDevisIsSand && (
                                <>
                                    <th scope="col" className="p-1 border-b border-black font-bold py-1 w-1/12">Qty</th>
                                    <th scope="col" className="p-1 border-b border-black font-bold py-1 w-1/12">PU HT</th>
                                    <th scope="col" className="p-1 border-b border-black font-bold py-1 w-1/12">TVA</th>
                                </>
                            )}

                            <th scope="col" className="p-1 border-b border-black whitespace-nowrap font-bold py-1 w-1/12">Total HT</th>
                            {/* {formality.status === "En attente du devis" &&
                                <> */}
                            {!eDevisIsSand && (
                                <>
                                    <th scope="col" className="p-1  font-bold py-1 w-1/12"></th>
                                    <th scope="col" className="p-1  font-bold py-1 w-1/12"></th>
                                </>
                            )}
                            {/* </>
                            } */}
                        </tr>
                    </thead>


                    <tbody>
                        {/* <tr>
                            <td className='p-1 border-r border-s border-b border-black'> {formality?.fraisAll?.honoraires?.description || formality.titreFormalitie} </td>
                            {!eDevisIsSand && (
                                <>
                                    <td className='p-1 border-r text-right border-b border-black'>1</td>
                                    <td className='p-1 border-r text-right border-b border-black'>{formality?.fraisAll?.honoraires?.prix || formality.montantHT} €</td>
                                    <td className='p-1 border-r text-right border-b border-black'>{formality?.fraisAll?.honoraires?.tva * 100 || 20} %</td>
                                </>
                            )
                            }
                            <td className='p-1 border-r border-b text-right border-black'>{formality?.fraisAll?.honoraires?.prix || formality.montantHT} €</td>
                            {formality.status === "En attente du devis" &&
                                <td className="p-2 border-black py-1"></td>
                            }
                        </tr> */}
                        {formality?.fraisAll && <FeesTable fraisAll={formality.fraisAll} eDevisIsSand={eDevisIsSand} showHonoraires={true} />}

                        {rowsAccompanyingCosts.map(row => (
                            <>
                                {console.log("row: ", row)}
                                {console.log("editStatusRowsAccompanyingCosts: ", editStatusRowsAccompanyingCosts)}
                                <EditableRoweDevis
                                    key={row.id}
                                    row={row}
                                    updateRow={updateRow}
                                    isEditing={(editStatusRowsAccompanyingCosts && editStatusRowsAccompanyingCosts[row.id] && formality.status === "En attente du devis") || false}
                                    setEditing={setEditingRowsAccompanyingCosts}
                                    formality={formality}
                                    deleteRow={deleteRowAccompanyingCosts}
                                    eDevisIsSand={eDevisIsSand}

                                />
                            </>
                        ))}
                        {formality.status === "En attente du devis" &&
                            <tr>
                                <td colSpan="1" className="text-right">
                                    <button onClick={handleAddRow} className="mt-2 text-blue-600 hover:border-b hover:border-blue-600">Ajouter une ligne</button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

                <table className="w-full text-left">
                    <thead className="text-gray-700">
                        <tr>
                            <th scope="col" className="px-1 border-b border-black py-2 mt-2 w-2/3">Frais légaux</th>
                            {!eDevisIsSand && (
                                <>
                                    <th scope="col" className="px-1 border-b border-black py-1 w-1/12"></th>
                                    <th scope="col" className="px-1 border-b border-black py-1 w-1/12"></th>
                                    <th scope="col" className="px-1 border-b border-black py-1 w-1/12"></th>
                                </>
                            )
                            }
                            <th scope="col" className="p-1 border-b border-black whitespace-nowrap text-white font-bold py-1 w-1/12">Total HT</th>
                            {formality.status === "En attente du devis" &&
                                <>
                                    <th scope="col" className="p-1 font-bold border-black py-1 w-1/12"></th>
                                    <th scope="col" className="p-1 font-bold border-black py-1 w-1/12"></th>
                                </>
                            }
                        </tr>
                    </thead>


                    <tbody>
                        {formality?.fraisAll && <FeesTable fraisAll={formality.fraisAll} eDevisIsSand={eDevisIsSand} showHonoraires={false} />}
                        {rowsLegalCosts.map(row => (
                            <>
                                {console.log("row: ", row)}
                                {console.log("editStatusRowsLegalCosts: ", editStatusRowsLegalCosts)}
                                < EditableRoweDevis
                                    key={row.id}
                                    row={row}
                                    updateRow={updateRowLegalCosts}
                                    isEditing={(editStatusRowsLegalCosts && editStatusRowsLegalCosts[row.id] && formality.status === "En attente du devis") || false}
                                    setEditing={setEditingRowsLegalCosts}
                                    formality={formality}
                                    deleteRow={deleteRowLegalCosts}
                                    eDevisIsSand={eDevisIsSand}
                                />
                            </>
                        ))}
                        {formality.status === "En attente du devis" &&

                            <tr>
                                <td colSpan="1" className="text-right">
                                    <button onClick={handleAddRowLegalCosts} className="text-blue-600 hover:border-b hover:border-blue-600">Ajouter une ligne</button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>


            </div>
            <div className={`flex mt-2 ${eDevisIsSand ? "justify-between" : "justify-end  mr-4"}`}>
                <table className={`text-black ${eDevisIsSand ? "w-full" : ""}`}>
                    <tbody>
                        <tr>
                            <td className="p-1 font-bold py-2">Total net HT : </td>
                            <td className="p-1 font-bold py-2 text-right"><span>{totals.totalHT.toFixed(2)} €</span></td>
                        </tr>
                        <tr>
                            <td className='p-1'>TVA</td>
                            <td className='p-1 text-right'>
                                {console.log(totals)}
                                {totals.TVA.toFixed(2)} €

                            </td>
                        </tr>
                        <tr>
                            <td className='p-1 font-bold py-2"'>Montant total TTC : </td>
                            <td className='p-1 font-bold py-2 text-right'>{totals.totalTTC.toFixed(2)} €</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {formality.status === "En attente du devis" && (
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={handleSendDevis}
                        className={`bg-blue-500 hover:bg-blue-700 text-white ${formality.status === "En attente du devis" ? "text-blue-600 hover:border-b cursor-pointer" : "text-gray-400 cursor-not-allowed"} items-end font-bold py-2 px-4 rounded`}
                        disabled={!(formality.status === "En attente du devis")}
                    >
                        Envoyer le eDevis
                    </button>
                </div>
            )}


            <Modal
                title=""
                modalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                modalSize={"auto"}
            >
                <div className="flex flex-col items-center justify-center p-4">
                    <img src={`${subDomains.includes(subdomain) ? logoMesFormalites : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />
                    <p className="text-center mb-6"> L'eDevis est envoyé avec succès.</p>
                    <button
                        onClick={() => {
                            setModalOpen(false);
                            setModalDossierOpen(false);
                        }

                        }
                        className="signupFormContainerContinuer text-white font-bold"
                    >
                        OK
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default EditableeDevisTable;