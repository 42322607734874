import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";
import { MdDelete } from "react-icons/md";
import { GrDocumentUpdate } from "react-icons/gr";

const ViewExperts = ({ setTmpId, setTmpOption }) => {
  const [experts, setExperts] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchDocs = async (nb_experts) => {
    setIsLoading(true);
    try {
      const response = await fetchData(
        "list_experts",
        {
          nb_experts: nb_experts, // Number of documents to fetch per request
          last_timestamp: lastTimestamp,
        },
        "POST"
      );
      const data = await response;
      setLastTimestamp(data.last_timestamp);
      if (data.experts.length > 0) {
        setExperts([...experts, ...data.experts]);
      }
    } catch (error) {
      console.error("Error fetching experts: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet expert ?"
    );
    if (isConfirmed) {
      try {
        // Call the API to delete the document by ID

        await fetchData(
          "delete_expert",
          {
            expert_id: id,
          },
          "POST"
        );
        // Remove the document from the state
        setExperts(experts.filter((expert) => expert.id !== id));
      } catch (error) {
        alert("Erreur lors de la supression de l'expert: ", error);
      }
    }
  };

  const handleUpdate = async (id) => {
    try {
      setTmpId(id);
      setTmpOption("modifier_expert");
    } catch (error) {
      alert("Erreur lors de la mise à jour de l'expert: ", error);
    }
  };

  useEffect(() => {
    fetchDocs("5"); // Initial fetch when the component mounts
    // Cleanup function
    return () => {
      // Cleanup tasks, if any
    };
  }, []); // Fetch documents whenever lastTimestamp changes

  const handleLoadMore = () => {
    if (lastTimestamp === "") {
      return;
    }
    // Fetch the next batch of documents when the "Load More" button is clicked
    fetchDocs(null);
  };
  return (
    <div className="flex flex-col px-4">
      <div>
        <button
          onClick={() => setTmpOption("ajouter_expert")}
          className="bg-[#3ED400] p-1 px-2 text-white font-semibold rounded mb-2"
        >
          AJOUTER
        </button>
      </div>
      <div className=" space-y-4">
        {experts.map((expert, index) => (
          <div
            className="bg-white rounded-lg p-4 shadow-md flex justify-between items-center"
            key={index}
          >
            <div>
              <h2 className="text-xl font-semibold mb-2">{expert.name}</h2>
              <p className="text-gray-600 mb-2">
                Date d'ajout: {new Date(expert.timestamp).toLocaleString()}
              </p>
            </div>

            <div>
              <div>Recommandations : {expert.recommandations}</div>
              <div>Clicks : {expert.clicks}</div>
            </div>
            <div className="space-x-4">
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={() => handleUpdate(expert.id)}
              >
                <GrDocumentUpdate />
              </button>
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() => handleDelete(expert.id)}
              >
                <MdDelete />
              </button>
            </div>
          </div>
        ))}
        {isLoading && <p>Loading...</p>}
        {!isLoading && (
          <button
            className="p-1 rounded-md cursor-pointer btn text-base"
            onClick={handleLoadMore}
            disabled={lastTimestamp === ""}
          >
            Charger tous
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewExperts;
