import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase.config';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


export function RequestsChart() {
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  const [totalRequests, setTotalRequests] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [totalRequestsForMonth, setTotalRequestsForMonth] = useState(0);
  const [viewMode, setViewMode] = useState('monthly');  // 'monthly' ou 'daily'
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());



  const modelColors = {
    'Open Mixtral 8x7b': '#36A2EB',  // Bleu 
    'Mistral medium': '#FFCE56',        // Jaune
    'GPT 3.5': '#4BC0C0',    // Vert d'eau
    'GPT 4 turbo': '#FF6384' // Rouge
  };

  const handleClickOnBar = (event, elements, chart) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      if (viewMode === 'monthly') {
        const label = chart.data.labels[index];
        setSelectedMonth(label);
        setViewMode('daily');
      } else {

      }
    }
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            if (tooltipItems.length > 0 && this.chart.data.labels) {
              return this.chart.data.labels[tooltipItems[0].dataIndex];
            }
            return '';
          }
        }
      },
    },
    onClick: handleClickOnBar,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    if (viewMode === 'monthly') {
      const loadMonthlyData = async () => {
        const q = query(collection(db, "requests"));
        const querySnapshot = await getDocs(q);
        const modelData = {};
        let totalRequests = 0;

        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          Object.entries(docData).forEach(([key, value]) => {
            const { model_info, timestamp } = value;
            if (model_info && timestamp) {
              const timestampDate = timestamp.toDate();
              const yearOfData = timestampDate.getFullYear();

              if (yearOfData == selectedYear) {
                const modelName = model_info.name;
                const monthLabel = `${timestampDate.getMonth() + 1}/${yearOfData}`;

                if (!modelData[modelName]) {
                  modelData[modelName] = { totalCount: 0 };
                }
                if (!modelData[modelName][monthLabel]) {
                  modelData[modelName][monthLabel] = 0;
                }
                modelData[modelName][monthLabel] += 1;
                modelData[modelName].totalCount += 1;
                totalRequests += 1;
              }
            }
          });
        });

        console.log("modelData: ", modelData);

        const labels = Array.from(
          new Set(
            Object.values(modelData)
              .flatMap(md => Object.keys(md).filter(key => key !== 'totalCount'))
          )
        ).sort((a, b) => {
          const [monthA, yearA] = a.split('/').map(Number);
          const [monthB, yearB] = b.split('/').map(Number);
          const dateA = new Date(yearA, monthA - 1);
          const dateB = new Date(yearB, monthB - 1);
          return dateA - dateB;
        });
        console.log("modelData", modelData);
        console.log("labels", labels);

        const datasets = Object.keys(modelData).map(modelName => ({
          label: `${modelName} (${modelData[modelName].totalCount})`,
          data: labels.map(label => modelData[modelName][label] || 0),
          backgroundColor: modelColors[modelName] || 'rgba(0, 0, 0, 0.5)'
        }));

        setData({
          labels,
          datasets
        });
        setTotalRequests(totalRequests);
      };

      loadMonthlyData();
    } else if (selectedMonth && viewMode === 'daily') {
      const loadDailyData = async () => {
        if (!selectedMonth) return;

        const [month, year] = selectedMonth.split('/').map(Number);
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);

        const q = query(collection(db, "requests"));
        const querySnapshot = await getDocs(q);
        const modelData = {};
        let totalRequests = 0;

        querySnapshot.forEach((doc) => {
          const entries = Object.entries(doc.data());

          entries.forEach(([key, value]) => {
            const { model_info, timestamp } = value;
            if (model_info && timestamp) {
              const timestampDate = timestamp.toDate();
              if (timestampDate >= startDate && timestampDate <= endDate) {
                const modelName = model_info.name;
                const day = timestampDate.getDate();
                const dayLabel = `${day}/${month}/${year}`;
                totalRequests++;

                if (!modelData[modelName]) {
                  modelData[modelName] = {};
                }
                if (!modelData[modelName][dayLabel]) {
                  modelData[modelName][dayLabel] = 0;
                }
                modelData[modelName][dayLabel]++;
              }
            }
          });
        });
        setTotalRequestsForMonth(totalRequests);

        const allDays = new Set();

        Object.values(modelData).forEach(model => {
          Object.keys(model).forEach(dayLabel => {
            if (!dayLabel.includes('totalCount')) {
              allDays.add(dayLabel);
            }
          });
        });

        const labels = Array.from(allDays).sort((a, b) => {
          const [dayA, monthA, yearA] = a.split('/').map(Number);
          const [dayB, monthB, yearB] = b.split('/').map(Number);
          const dateA = new Date(yearA, monthA - 1, dayA);
          const dateB = new Date(yearB, monthB - 1, dayB);
          return dateA - dateB;
        });

        const datasets = Object.keys(modelData).map(modelName => {
          return {
            label: modelName,
            data: labels.map(label => modelData[modelName][label] || 0),
            backgroundColor: modelColors[modelName] || 'rgba(0, 0, 0, 0.5)',
          };
        });

        setData({ labels, datasets });
      };


      loadDailyData();
    }
  }, [viewMode, selectedYear, selectedMonth]);


  return (
    <div className="max-w-4xl mx-auto my-4">
      {viewMode === 'daily' ? (
        <div className="text-right">
          <button
            className="border border-blue-logo-color text-blue-logo-color font-semibold rounded border-2 p-1 text-xs "
            onClick={() => setViewMode('monthly')}
          >
            Retour aux statistiques mensuelles
          </button>
          <div className="text-xs text-blue-logo-color pt-2">Requêtes sur la période {selectedMonth} : {totalRequestsForMonth}</div>
        </div>
      ) : (
        <div className="flex justify-end items-center space-x-4 my-4">
          <div className='border-2 rounded-lg'>
            <button
              className={`text-gray-600 font-bold py-2 px-4 rounded-l ${selectedYear <= new Date().getFullYear() - 1 && 'opacity-50 cursor-not-allowed'}`}
              onClick={() => selectedYear > new Date().getFullYear() - 1 && setSelectedYear(prevYear => prevYear - 1)}
              disabled={selectedYear <= new Date().getFullYear() - 1}
            >
              &lt;
            </button>
            <span className="text-lg font-semibold">{selectedYear}</span>
            <button
              className={`text-gray-600 font-bold py-2 px-4 rounded-r ${selectedYear >= new Date().getFullYear() && 'opacity-50 cursor-not-allowed'}`}
              onClick={() => selectedYear < new Date().getFullYear() && setSelectedYear(prevYear => prevYear + 1)}
              disabled={selectedYear >= new Date().getFullYear()}
            >
              &gt;
            </button>
          </div>
        </div>
      )}
      <Bar options={options} data={data} />
      {viewMode === 'monthly' && (
        <p className="mt-2 text-sm text-center">Total des requêtes sur l'année {selectedYear} : {totalRequests}</p>
      )}
    </div>
  );


}
