import { useEffect, useState } from 'react';
import {
    BsLink45Deg
    // MdOutlineCoffee,
} from 'react-icons/bs';
import { useColorsContext } from "../../context/GlobalContext";


const ListLiensUtiles = ({ liensUtiles }) => {


    const addBlankTargetToLinks = (html) => {

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const links = doc.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
        });

        return doc.body.innerHTML;
    };
    const [renderedLinks, setRenderedLinks] = useState([]);



    useEffect(() => {
        setRenderedLinks([]);
        const timeoutIds = liensUtiles.map((lien, index) => {
            return setTimeout(() => {
                setRenderedLinks(prevLinks => [...prevLinks, lien]);
            }, index * 100); // Adjust the delay time (in milliseconds) as needed
        });

        // Clear timeouts to avoid memory leaks when the component is unmounted
        return () => {
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, [liensUtiles]); // Empty dependency array ensures useEffect runs once after initial render

    return (
        <>
            {liensUtiles.length > 0 ? (
                <div className={`right-panel-liens-utiles bg-[#8EBAEA] rounded-2xl px-6 m-2 ${renderedLinks.length > 0 ? 'in' : ''}`}>
                    <div className="flex space-x-2 mb-4 items-center">
                        {/* <div className={`flex items-center justify-center h-8 w-8 rounded-full text-black bg-first-oliver`}>
                        <span className="text-lg font-bold">{liensUtiles.length}</span>
                    </div> */}
                        <div className={`font-bold text-[#06094F]`}>Liens utiles</div>
                    </div>
                    <div className="list-links overflow-x-hidden">
                        {renderedLinks.map((lien, index) => (
                            <div className="link" key={index}>
                                <BsLink45Deg id="icon" />
                                <div className='w-[90%]' dangerouslySetInnerHTML={{ __html: addBlankTargetToLinks(lien) }} />
                            </div>
                        ))}
                    </div>
                </div>) : null}
        </>
    );
}

export default ListLiensUtiles