import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";
import { useAppContext } from "../../context/AppContext";
const FicheRenforcement = ({ id }) => {

    const { parentIds } = useAppContext()

    const [isUpdateMode, setIsUpdateMode] = useState(!!id);
    const [nomFiche, setNomFiche] = useState("");
    const [contenu, setContenu] = useState("");
    const [parentId, setParentId] = useState("");
    const [links, setLinks] = useState([{ link: "", name: "" }]);

    const [commentaire, setCommentaire] = useState("");

    const [statusMessage, setStatusMessage] = useState("");
    const [statusColor, setStatusColor] = useState("");

    const [addNewParentId, setAddNewParentId] = useState(false)

    // const fichesRenforcementParentIds = Object.entries(parentIds).filter(([key, value]) => key.startsWith('fiche_renforcement'));
    // console.log(fichesRenforcementParentIds)
    const fichesRenforcementParentIds = Object.keys(parentIds)
        .filter(key => key.startsWith('fiche_renforcement'))
        .reduce((obj, key) => {
            obj[key] = parentIds[key].titre;
            return obj;
        }, {});

    useEffect(() => {
        const fetchDataFromApi = async () => {
            if (id) {
                try {
                    const data = await fetchData(`get_fiche_renforcement/${id}`, null, "GET");
                    setNomFiche(data.name);
                    setContenu(data.content);
                    setLinks(data.useful_links);
                    setParentId(fichesRenforcementParentIds[data.parent_id]);
                    setCommentaire(data.commentaire || " ");
                } catch (error) {
                    console.error("Error fetching data: ", error);
                    // Handle error state here if necessary
                }
            }
        };

        fetchDataFromApi();
    }, [id]);
    const clearStatusMessage = () => {
        setStatusMessage("");
    };
    useEffect(() => {
        let timer;
        if (statusMessage) {
            timer = setTimeout(clearStatusMessage, 4000); // 4000 milliseconds = 4 seconds
        }
        return () => clearTimeout(timer); // This will clear the timer when the component unmounts or before setting a new timer
    }, [statusMessage]);


    const handleLinkChange = (index, field, value) => {
        const updatedLinks = [...links];
        updatedLinks[index][field] = value;
        setLinks(updatedLinks);
    };

    const handleAddLink = () => {
        setLinks([...links, { link: "", name: "" }]);
    };

    const handleRemoveLink = (index) => {
        const updatedLinks = [...links];
        updatedLinks.splice(index, 1);
        setLinks(updatedLinks);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if name and content fields are empty
        if (!nomFiche.trim() || !contenu.trim() || !parentId.trim()) {
            setStatusMessage("Veuillez remplir tous les champs nom, contenu et identifiant parent.");
            setStatusColor("red");
            return;
        }

        try {
            const payload = {
                name: nomFiche,
                content: contenu,
                useful_links: links,
                parent_id: parentId,
                commentaire: commentaire,
            };

            if (isUpdateMode) {
                payload.id = id;
                // Handle update logic here (use `initialData.id` or a similar identifier)
                await fetchData(`update_fiche_renforcement`, payload, "POST");
                setStatusMessage("Fiche de renforcemant mis à jour avec succès");
            } else {
                // Handle add logic here
                await fetchData("add_fiche_renforcement", payload, "POST");
                setStatusMessage("Fiche de renforcemant mis en ligne avec succès");
            }

            setStatusColor("green");
        } catch (error) {
            setStatusMessage("Erreur lors de la mise en ligne ou de la mise à jour de la fiche de renforcemant");
            setStatusColor("red");
            console.error("Error:", error);
        }
    };


    return (
        <div className="ficheRenforcement">
            <form className="form-control" onSubmit={handleSubmit}>
                <div className="ficheRenforcementForm">
                    <div>
                        <p>Nom de la fiche</p>
                        <input className="w-full p-1 input input-bordered	" value={nomFiche} onChange={(e) => setNomFiche(e.target.value)} />
                    </div>
                    <div>
                        <p>Contenu</p>
                        <textarea className="p-1 h-40 w-full input input-bordered" value={contenu} onChange={(e) => setContenu(e.target.value)} />
                    </div>
                    {/* <div>
                        <p>Identifiant Parent</p>
                        <input className="w-1/2 p-1 input input-bordered" value={parentId} onChange={(e) => setParentId(e.target.value)} />
                    </div>
                     */}



                    <div>
                        <p>Identifiant Parent</p>
                        <div className="w-[48%] flex">

                            {!addNewParentId ?
                                <select
                                    className="w-full p-3 bg-inherit border border-gray-300 rounded cursor-pointer"
                                    onChange={(e) => setParentId(e.target.value)}
                                    value={parentId}
                                // className={`w-full ${compagnyInfos["forme_juridique"] === null ? 'text-gray-400' : ''}`}
                                >
                                    <option value="">Sélectionner</option>
                                    {
                                        Object.keys(fichesRenforcementParentIds).map((parent_name, index) => (
                                            <option key={index} value={fichesRenforcementParentIds[parent_name]}>{fichesRenforcementParentIds[parent_name]}</option>
                                        ))
                                    }
                                </select>

                                :
                                <input
                                    className="w-full p-1 input input-bordered"
                                    onChange={(e) => {
                                        setParentId(e.target.value)
                                    }}
                                    placeholder="Identifiant"
                                />}

                            {
                                <button
                                    type="button"
                                    className={`${addNewParentId ? "bg-red-500" : "bg-green-500"}  text-white px-2 py-1 rounded ml-2`}
                                    onClick={() => setAddNewParentId(!addNewParentId)}
                                >
                                    +
                                </button>
                            }


                        </div>
                    </div>




                    <div>
                        <p>Liens utiles</p>
                        {links.map((link, index) => (
                            <div key={index} className="flex mb-2">
                                <input
                                    className="w-[48%] p-1 mr-2 input input-bordered"
                                    placeholder="Lien"
                                    value={link.link}
                                    onChange={(e) => handleLinkChange(index, "link", e.target.value)}
                                />
                                <input
                                    className="w-[48%] p-1 mr-2 input input-bordered"
                                    placeholder="Nom"
                                    value={link.name}
                                    onChange={(e) => handleLinkChange(index, "name", e.target.value)}
                                />
                                <button
                                    type="button"
                                    className="bg-red-500 text-white px-2 py-1 rounded"
                                    onClick={() => handleRemoveLink(index)}
                                >
                                    -
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            className="bg-green-500 text-white px-2 py-1 rounded"
                            onClick={handleAddLink}
                        >
                            +
                        </button>
                    </div>
                    <div>
                        <p>Commentaire</p>
                        <textarea className="p-1 h-20 w-full input input-bordered" value={commentaire} onChange={(e) => setCommentaire(e.target.value)} />
                    </div>
                    {statusMessage && (
                        <div style={{ color: statusColor, marginTop: '10px' }}>
                            {statusMessage}
                        </div>
                    )}
                    <button type="submit" className="p-1 rounded-md cursor-pointer btn text-base border btn-primary">
                        {id ? "Modifier la fiche" : "Mettre en ligne"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FicheRenforcement;
