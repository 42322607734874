import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { collection, getDocs, query } from "firebase/firestore";
import { db } from '../../firebase.config';

ChartJS.register(ArcElement, Tooltip, Legend);

const EvaluationStats = () => {
    const [stats, setStats] = useState({});
    const [selectedModel, setSelectedModel] = useState("Tous les modèles");
    const [modelOptions, setModelOptions] = useState(["Tous les modèles"]);
    const [totalEvaluations, setTotalEvaluations] = useState(0);

    useEffect(() => {
        const fetchEvaluations = async () => {
            const q = query(collection(db, "evaluations"));
            const querySnapshot = await getDocs(q);
            const evaluations = querySnapshot.docs.map(doc => doc.data());

            const modelStats = evaluations.reduce((acc, curr) => {
                const modelName = curr.model_info.name || "Inconnu";
                const rating = curr.rating;

                if (!acc[modelName]) {
                    acc[modelName] = [0, 0, 0, 0];
                }
                if (rating >= 1 && rating <= 4) {
                    acc[modelName][rating - 1]++;
                }
                return acc;
            }, {});

            setStats(modelStats);
            setModelOptions(["Tous les modèles", ...Object.keys(modelStats)]);
        };

        fetchEvaluations();
    }, []);

    const filteredStats = selectedModel === "Tous les modèles"
        ? Object.values(stats).reduce((acc, curr) => acc.map((val, index) => val + curr[index]), [0, 0, 0, 0])
        : stats[selectedModel] || [0, 0, 0, 0];

    const total = filteredStats.reduce((acc, curr) => acc + curr, 0);
    const percentages = filteredStats.map(rating => total > 0 ? (rating / total * 100).toFixed(2) : 0);

    useEffect(() => {
        setTotalEvaluations(total);
    }, [filteredStats]);

    const data = {
        labels: ['1 étoile', '2 étoiles', '3 étoiles', '4 étoiles'],
        datasets: [{
            data: percentages,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
        }]
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="w-full max-w-xs mx-auto">
                <select
                    value={selectedModel}
                    onChange={(e) => setSelectedModel(e.target.value)}
                    className="w-full p-2 mb-4 text-center bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
                >
                    {modelOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </div>
            <div className="w-full max-w-sm mx-auto">
                <Doughnut data={data} />
                <p className="mt-2 text-sm text-center">Total des évaluations : {totalEvaluations}</p>
            </div>
        </div>
    );
};

export default EvaluationStats;
