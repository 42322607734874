import { useState, useEffect } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import { db } from "../../firebase.config";
import { useAppContext } from "../../context/AppContext";
import RegistreFormalitiesTable from "./RegistreFormalitiesTable";

const FormalitiesAll = ({ setOpen }) => {
    const [formalities, setFormalities] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(db, "formalities"),
            (querySnapshot) => {
                const formalitiesList = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    console.log(data);
                    const formalitiesWithId = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key,
                    }));

                    formalitiesList.push(formalitiesWithId);
                });

                console.log(formalitiesList.flat());
                setFormalities(formalitiesList.flat());
            }
        );

        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="w-full">
                {formalities.length > 0 ? (
                    <RegistreFormalitiesTable formalities={formalities} setFormalities={setFormalities} />
                ) : (
                    <p>Aucune formalité disponible.</p>
                )}
            </div>
        </>
    );
};

export default FormalitiesAll;