import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";

const UpdatePrompt = ({ }) => {

    const [prompt, setPrompt] = useState("");


    const [statusMessage, setStatusMessage] = useState("");
    const [statusColor, setStatusColor] = useState("");


    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const data = await fetchData(`get_main_prompt`, null, "GET");
                setPrompt(data.main_prompt)
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        }
        fetchDataFromApi();
    }, []);

    const clearStatusMessage = () => {
        setStatusMessage("");
    };
    useEffect(() => {
        let timer;
        if (statusMessage) {
            timer = setTimeout(clearStatusMessage, 4000); // 4000 milliseconds = 4 seconds
        }
        return () => clearTimeout(timer); // This will clear the timer when the component unmounts or before setting a new timer
    }, [statusMessage]);
    

    const handleModifyPrompt = async () => {
        try {
            await fetchData(`update_main_prompt`, { main_prompt: prompt }, "POST");
            setStatusMessage("Prompt modifié avec succès");
            setStatusColor("green");
        } catch (error) {
            console.error("Error fetching data: ", error);
            setStatusMessage("Erreur lors de la modification du prompt veuillez vérifier que vous avez bien inséré les balises nécessaires");
            setStatusColor("red");
        }
    }

    return (
        <div className="p-4 space-y-4">
            <div>
                <h1 className="underline font-extrabold text-xl mb-4">Infos</h1>
                <p>Vous pouvez mopdifier le prompt envoyé au LLM ci dessous. Il est important de ne pas oublier les balises suivantes :</p>
                <ul className="ml-4">
                    <li><span className="font-bold">- {'{'}documents{'}'} : </span> Contiendra les documents à passer au LLM qui proviennent de la base de données.</li>
                    <li><span className="font-bold">- {'{'}compagny_infos{'}'} : </span> Contiendra les informations renseignées par l'utilisateur à propos de sa société (Secteur d'activité principale/Forme juridique/Effectif moyen/Chiffre d'affaires/Bilan).</li>
                </ul>
            </div>
            <div>
                <h1 className="underline font-extrabold text-xl mb-2">Prompt</h1>
                <textarea className="p-2 h-80 w-full input input-bordered" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </div>
            <div className="flex items-center flex-col">
                <button onClick={()=>{handleModifyPrompt()}} className="btn btn-primary">Modifier</button>
                {statusMessage && <div  className={"mt-4"} style={{ color: statusColor}}> {statusMessage} </div>}
            </div>
        </div>
    );
};

export default UpdatePrompt;
