import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";

const Expert = ({ id }) => {
    const [isUpdateMode, setIsUpdateMode] = useState(!!id);


    const [nomExpert, setNomExpert] = useState("");
    const [urlLogoExpert, setUrlLogoExpert] = useState("");
    const [typeStructureExpert, setTypeStructureExpert] = useState("");
    const [newExpertiseExpert, setNewExpertiseExpert] = useState("");
    const [expertisesExpert, setExpertisesExpert] = useState([]);
    const [urlSiteExpert, setUrlSiteExpert] = useState("");
    const [descriptionExpert, setDescriptionExpert] = useState("");
    const [newMotCle, setNewMotCle] = useState("");
    const [motsCles, setMotsCles] = useState([]);

    const [solutions, setSolutions] = useState([
        { titre: "", type: "", description: "", prix: "", url: "", mots_cles: [] }
    ]);

    const [motsClesSolution, setMotsClesSolution] = useState([]);
    const [newMotsClesSolution, setNewMotsClesSolution] = useState(Array(solutions.length).fill(""));



    const [statusMessage, setStatusMessage] = useState("");
    const [statusColor, setStatusColor] = useState("");

    
    
    useEffect(() => {
        const fetchDataFromApi = async () => {
            if (id) {
                try {
                    const data = await fetchData(`get_expert/${id}`, null, "GET");
                    setNomExpert(data.name);
                    setUrlLogoExpert(data.url_logo);
                    setUrlSiteExpert(data.url_site);
                    setDescriptionExpert(data.description);
                    setTypeStructureExpert(data.type_structure);
                    setExpertisesExpert(data.expertises);
                    setMotsCles(data.mots_cles);
                    setSolutions(data.solutions);
                } catch (error) {
                    console.error("Error fetching data: ", error);
                    // Handle error state here if necessary
                }
            }
        };

        fetchDataFromApi();
    }, [id]);


    const clearStatusMessage = () => {
        setStatusMessage("");
    };
    useEffect(() => {
        let timer;
        if (statusMessage) {
            timer = setTimeout(clearStatusMessage, 4000); // 4000 milliseconds = 4 seconds
        }
        return () => clearTimeout(timer); // This will clear the timer when the component unmounts or before setting a new timer
    }, [statusMessage]);
    



    const handleAddMotCle = () => {
        if (newMotCle && !motsCles.includes(newMotCle)) {
            setMotsCles([...motsCles, newMotCle]);
            setNewMotCle("");
        }
    };

    const handleRemoveMotCle = (index) => {
        const updatedKeywords = [...motsCles];
        updatedKeywords.splice(index, 1);
        setMotsCles(updatedKeywords);
    };




    const handleAddExpertise = () => {
        if (newExpertiseExpert && !expertisesExpert.includes(newExpertiseExpert)) {
            setExpertisesExpert([...expertisesExpert, newExpertiseExpert]);
            setNewExpertiseExpert("");
        }
    };

    const handleRemoveExpertise = (index) => {
        const updatedExpertises = [...expertisesExpert];
        updatedExpertises.splice(index, 1);
        setExpertisesExpert(updatedExpertises);
    };

    const handleSolutionChange = (index, field, value) => {
        const updatedSolutions = [...solutions];
        updatedSolutions[index][field] = value;
        setSolutions(updatedSolutions);
    };

    const handleAddSolutions = () => {
        setSolutions([...solutions, { titre: "", type: "", description: "", url: "", prix: "", mots_cles: [] }]);
    };
    const handleAddMotCleSolution = (solutionIndex) => {
        if (newMotsClesSolution[solutionIndex]) {
            setSolutions(prevSolutions => {
                const updatedSolutions = [...prevSolutions];
                const updatedSolution = { ...updatedSolutions[solutionIndex] };
                updatedSolution.mots_cles = [...updatedSolution.mots_cles, newMotsClesSolution[solutionIndex]];
                updatedSolutions[solutionIndex] = updatedSolution;
                return updatedSolutions;
            });

            // Clear the input after adding the keyword
            const updatedNewMotsClesSolution = [...newMotsClesSolution];
            updatedNewMotsClesSolution[solutionIndex] = "";
            setNewMotsClesSolution(updatedNewMotsClesSolution);
        }
    };

    const handleRemoveMotCleSolution = (solutionIndex, motClesIndex) => {
        setSolutions(prevSolutions => {
            const updatedSolutions = [...prevSolutions];
            const updatedSolution = { ...updatedSolutions[solutionIndex] };
            updatedSolution.mots_cles = updatedSolution.mots_cles.filter((_, index) => index !== motClesIndex);
            updatedSolutions[solutionIndex] = updatedSolution;
            return updatedSolutions;
        });
    };




    const handleRemoveSolution = (index) => {
        const updatedSolutions = [...solutions];
        updatedSolutions.splice(index, 1);
        setSolutions(updatedSolutions);
    };

    const SolutionHasDuplicateTitles = solutions.some((solution, index, array) => {
        // Use filter to find solutions with the same title
        const duplicateSolutions = array.filter((item, i) => item.titre === solution.titre && i !== index);
        // If there are duplicates, return true
        return duplicateSolutions.length > 0;
    });


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if name and content fields are empty
        if (!nomExpert.trim() || !urlLogoExpert.trim() || !descriptionExpert.trim() || !typeStructureExpert.trim()) {
            setStatusMessage("Veuillez remplir tous les champs.");
            setStatusColor("red");
            return;
        }
        if (SolutionHasDuplicateTitles) {
            setStatusMessage("Veuillez choisir des titres différents pour vos solutions.");
            setStatusColor("red");
            return;
        }


        try {
            const payload = {
                name: nomExpert,
                url_logo: urlLogoExpert,
                url_site: urlSiteExpert,
                description: descriptionExpert,
                type_structure: typeStructureExpert,
                expertises: expertisesExpert,
                mots_cles: motsCles,
                solutions: solutions
            };

            if (isUpdateMode) {
                payload.id = id;
                // Handle update logic here (use `initialData.id` or a similar identifier)
                await fetchData(`update_expert`, payload, "POST");
                setStatusMessage("Expert mis à jour avec succès");
            } else {
                // Handle add logic here
                await fetchData("add_expert", payload, "POST");
                setStatusMessage("Expert ajouté avec succès");
            }

            setStatusColor("green");
        } catch (error) {
            setStatusMessage("Erreur lors de l'ajout ou de la mise à jour de l'expert");
            setStatusColor("red");
            console.error("Error:", error);
        }
    };

    return (
        <div className="ficheRenforcement">
            <form className="form-control" onSubmit={handleSubmit}>
                <div className="ficheRenforcementForm">
                    <div>
                        <p>Nom de l'expert</p>
                        <input className="w-full p-1 input input-bordered	" value={nomExpert} onChange={(e) => setNomExpert(e.target.value)} />
                    </div>
                    <div>
                        <p>Url du logo</p>
                        <input className="w-full p-1 input input-bordered	" value={urlLogoExpert} onChange={(e) => setUrlLogoExpert(e.target.value)} />
                    </div>
                    <div>
                        <p>Url du site de l'expert</p>
                        <input className="w-full p-1 input input-bordered	" value={urlSiteExpert} onChange={(e) => setUrlSiteExpert(e.target.value)} />
                    </div>
                    <div>
                        <p>Description</p>
                        <textarea className="p-1 h-40 w-full input input-bordered" value={descriptionExpert} onChange={(e) => setDescriptionExpert(e.target.value)} />
                    </div>
                    <div>
                        <p>Type de structure</p>
                        <input className="w-full p-1 input input-bordered	" value={typeStructureExpert} onChange={(e) => setTypeStructureExpert(e.target.value)} />
                    </div>

                    <div>
                        <p>Epertises (5 max) </p>
                        <div className="flex">
                            <input
                                className="w-full p-1 input input-bordered"
                                value={newExpertiseExpert}
                                onChange={(e) => setNewExpertiseExpert(e.target.value)}
                            />
                            <button type="button"
                                onClick={handleAddExpertise}
                                className="bg-green-500 text-white px-2 py-1 rounded">
                                +
                            </button>
                        </div>
                        <div className="mt-2">
                            {expertisesExpert.map((keyword, index) => (
                                <button key={index} type="button" onClick={() => handleRemoveExpertise(index)}
                                    className="badge badge-success mr-2 p-2"

                                >
                                    X {keyword}
                                </button>

                            ))}
                        </div>
                    </div>

                    <div>
                        <p>Mots-clés</p>
                        <div className="flex">
                            <input
                                className="w-full p-1 input input-bordered"
                                value={newMotCle}
                                onChange={(e) => setNewMotCle(e.target.value)}
                            />
                            <button type="button"
                                onClick={handleAddMotCle}
                                className="bg-green-500 text-white px-2 py-1 rounded">
                                +
                            </button>
                        </div>
                        <div className="mt-2">
                            {motsCles.map((keyword, index) => (
                                <button key={index} type="button" onClick={() => handleRemoveMotCle(index)}
                                    className="badge badge-success mr-2 p-2"

                                >
                                    X {keyword}
                                </button>
                            ))}
                        </div>
                    </div>


                    <div>
                        <p>Solutions</p>
                        {solutions.map((solution, solutionIndex) => (
                            <div key={solutionIndex}  className="border-b-2 my-4">
                                <div className="underline">Solution {solutionIndex+1}</div>
                                <div className="flex">
                                    <div className="flex mb-2 flex-col w-full">
                                        <div className="flex mb-2">

                                            <input
                                                className="w-1/2 p-1 mr-2 input input-bordered"
                                                placeholder="titre"
                                                value={solution.titre}
                                                onChange={(e) => handleSolutionChange(solutionIndex, "titre", e.target.value)}
                                            />
                                            <input
                                                className="w-1/2 p-1 mr-2 input input-bordered"
                                                placeholder="type"
                                                value={solution.type}
                                                onChange={(e) => handleSolutionChange(solutionIndex, "type", e.target.value)}
                                            />
                                        </div>
                                        <div className="flex mb-2">
                                            <div className="w-1/3">
                                                <div className="flex mr-2">
                                                    <input
                                                        placeholder="Mots-clés"
                                                        className="w-full p-1 input input-bordered"
                                                        value={newMotsClesSolution[solutionIndex]}
                                                        onChange={(e) => {
                                                            const updatedNewMotsClesSolution = [...newMotsClesSolution];
                                                            updatedNewMotsClesSolution[solutionIndex] = e.target.value;
                                                            setNewMotsClesSolution(updatedNewMotsClesSolution);
                                                        }}
                                                    />
                                                    <button type="button" onClick={() => handleAddMotCleSolution(solutionIndex)} className="bg-green-500 text-white px-2 py-1 rounded">+</button>
                                                </div>
                                                <div className="mt-2">
                                                    {solution.mots_cles.map((keyword, motClesIndex) => (
                                                        <button key={motClesIndex} type="button" onClick={() => handleRemoveMotCleSolution(solutionIndex, motClesIndex)} className="badge badge-success mr-2 p-2">
                                                            X {keyword}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                            <input
                                                className="w-1/3 p-1 mr-2 input input-bordered"
                                                placeholder="url"
                                                value={solution.url}
                                                onChange={(e) => handleSolutionChange(solutionIndex, "url", e.target.value)}
                                            />
                                            <input
                                                className="w-1/3 p-1 mr-2 input input-bordered"
                                                placeholder="description du prix"
                                                value={solution.prix}
                                                onChange={(e) => handleSolutionChange(solutionIndex, "prix", e.target.value)}
                                            />
                                        </div>


                                    </div>
                                    <button
                                        type="button"
                                        className="bg-red-500 text-white px-2 py-1 rounded h-12"
                                        onClick={() => handleRemoveSolution(solutionIndex)}
                                    >
                                        -
                                    </button>
                                </div>
                                <textarea
                                    className="w-full h-24 p-1 mr-2 input input-bordered"
                                    placeholder="description"
                                    value={solution.description}
                                    onChange={(e) => handleSolutionChange(solutionIndex, "description", e.target.value)}
                                />
                            </div>
                        ))}
                        <button
                            type="button"
                            className="bg-green-500 text-white px-2 py-1 rounded"
                            onClick={handleAddSolutions}
                        >
                            +
                        </button>
                    </div>



                    {statusMessage && (
                        <div style={{ color: statusColor, marginTop: '10px' }}>
                            {statusMessage}
                        </div>
                    )}
                    <button type="submit" className="p-1 rounded-md cursor-pointer btn text-base border btn-primary">
                        {id ? "Modifier l'expert" : "Mettre en ligne"}

                    </button>
                </div>
            </form>
        </div>
    );
};

export default Expert;
