import React from "react";
import { useEffect } from "react";
import { useAppContext } from "../../../context/AppContext.js";
import moment from "moment";
import "moment/locale/fr";

const UserMessage = ({ message_id, index, content, createdAt }) => {
  const { selectedOption } = useAppContext();

  return (
    <div>
      {selectedOption?.class && message_id !== 0 ? (
        <>
          <p className="text-right mt-2 font-bold text-[#06094F]">Vous</p>
          <div className="bg-[#7FF9D8] p-3 mt-3 rounded-bl-lg rounded-tl-lg rounded-br-lg">
            {content.length > 0 && (
              <p
                className="text-center text-[#06094F]"
                dangerouslySetInnerHTML={{ __html: content }}
              ></p>
            )}
          </div>
        </>
      ) : (
        <>
          <p className="text-right mt-2 font-bold text-[#06094F]">Vous</p>
          <div className="bg-[#7FF9D8] p-3 mt-3 rounded-bl-lg rounded-tl-lg rounded-br-lg">
            {content.length > 0 && (
              <p
                className="text-right text-[#06094F]"
                dangerouslySetInnerHTML={{ __html: content }}
              ></p>
            )}
          </div>
        </>
      )}

      <div className={`text-right message__createdAt`}>
        {moment(createdAt).calendar()}
      </div>
    </div>
  );
};

export default UserMessage;
