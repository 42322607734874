const StepIndicator = ({ currentStep, setCurrentStep }) => {
    return (
        <div className="flex justify-center space-x-4 my-4">
            {Array.from({ length: 4 }, (_, i) => i + 1).map((step) => (
                <div
                    key={step}
                    onClick={() => currentStep >= step && setCurrentStep(step)}
                    className={`h-3 w-3 rounded-full ${currentStep >= step ? 'bg-[#06094F] cursor-pointer hover:bg-[#06094F] hover:opacity-50' : 'bg-white border-0'}`}
                />
            ))}
        </div>
    );
};

export default StepIndicator;
