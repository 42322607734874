import React, { useEffect } from "react";
import { useState } from "react";
import Markdown from "react-markdown";
import { useAppContext } from "../../context/AppContext";
const Cgus = ({ content, setCgus, signup }) => {
  const { cgus } = useAppContext();
  const [cgusMd, setCgusMd] = useState("");
  useEffect(() => {
    console.log(cgus);
    console.log(content);
    if (cgus && content) {
      setCgusMd(cgus[content].replace(/\\n/g, "\n"));
    }
  }, [cgus, content]);

  return (
    <>
      <div className="flex flex-col overflow-x-hidden">
        {signup && (
          <>
            <div className="flex w-full">
              <button
                onClick={() => setCgus("")}
                className="p-1 font-semibold text-orange-logo-color border border-orange-logo-color rounded hover:bg-orange-logo-color hover:text-white"
              >
                Retour à l'inscription
              </button>
            </div>

            <div className="text-2xl underline font-bold mb-4">{content}</div>
          </>
        )}
        <div className="cgus">
          {
            cgusMd == "" ?
             <div className="text-center">Chargement...</div>
             :
             <Markdown className={"space-y-4"}>{cgusMd}</Markdown>
          }
          
        </div>
      </div>
    </>
  );
};

export default Cgus;
