import React, { useState, useRef, useEffect } from "react";
import ChatMessage from "./chat_message/ChatMessage";
import { MdAdd, MdSend } from "react-icons/md";
import { FaStop, FaTelegramPlane } from "react-icons/fa";
import { useAppContext } from "../../context/AppContext";
import VersionSwitch from "./VersionSwitch";
import showSideBar from "../../assets/showSideBar.svg"
import { HiExclamationCircle } from "react-icons/hi";
import { doc, getDoc, getDocs, collection, query, where, serverTimestamp, addDoc, orderBy, limit, deleteDoc, runTransaction } from "firebase/firestore";
import { db } from "../../firebase.config";

import VsersionsDisponibles from "../auth/VersionsDisponibles";
import Modal from "../Modal";
// import logoInfogreffe from "../../assets/logoOliver_vanillaDark3-removebg-preview.png";
// import logoInfogreffe from "../../assets/MesFormalites.Legal4.png";
import logoInfogreffe from "../../assets/logoOliver_greffier.png";
import gestionObligationicon from "../../assets/gestionObligationicon.svg";
import procedureJuridique from "../../assets/procedureJuridique.svg";
import sinformerEntrepriseicon from "../../assets/sinformerEntrepriseicon.svg";
import offreAccompagnementicon from "../../assets/offreAccompagnementicon.svg";
import logo_mini from "../../assets/logoOliver_vanillaDark2_favicon.jpg";
import { updateMessage, update_last_system_message_tokens, createMessage } from '../../utils/conversation';
import { VscTriangleUp } from "react-icons/vsc";
import { GoSearch } from "react-icons/go";
import { BsExclamationCircle } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import UserDropdownMenu from "./UserDropdownMenu";
import Header from "./Header";
/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */
const ChatView = ({
  rightPanelOpen = false,
  sideBarOpen,
  setSideBarOpen,
  setRightPanelOpen,
  mobileMode,
  showPanel,
  setShowPanel,
  setModalLoginOpen
}) => {
  // Utilisation du contexte de l'application pour récupérer les états et les fonctions nécessaires.
  const {
    messages,
    experts,
    liensUtiles,
    writing,
    computingResponse,
    subdomain,
    currentSection,
    isFormality,
    subDomains,
    currentUser,
    isQuestionPersonnel,
    setIsQuestionPersonnel,
    setCurrentSection,
    clearChat,
    sendMessage,
    handleCloseWS,
    setMessages,
    setWriting,
    customOliver,
    setSelectedOption,
    setIsFormality,
    setCurrentConversationId,
    formalitieJuridique,

  } = useAppContext(); // Accès au contexte global de l'application pour récupérer des données et fonctions.

  // Références pour le défilement automatique et la gestion du focus.
  const messagesEndRef = useRef();
  const inputRef = useRef();

  // État local pour le contenu du formulaire et les suggestions de messages.
  const [formValue, setFormValue] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [suggestionFormalities, setSuggestionFormalities] = useState([]);
  const [suggestionMygreffe, setSuggestionMygreffe] = useState(null);


  // États pour gérer l'ouverture des versions et le titre du modal.
  const [versionsOpen, setVersionsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("Versions disponibles");
  const [isModalOpen, setModalOpen] = useState(false);
  const [rowsNumber, setRowsNumber] = useState(4);
  const [displayOptionsFormalite, setDisplayOptionsFormalite] = useState(true);

  // Effet pour définir les suggestions de messages en fonction du contexte de l'application.
  console.log("customOliver", customOliver);
  useEffect(() => {
    setSuggestion(customOliver["suggestions_questions"] || []);
    setSuggestionFormalities(customOliver["suggestions_questions_formalities"] || []);
    const data = {
      "Registre du commerce": [
        {
          "primaire": "Comment obtenir un kbis ?",
          "secondaire": "Et combien ça coute ?"
        },
        {
          "secondaire": "quand et comment le faire ?",
          "primaire": "Dépôt des comptes annuels"
        },
        {
          "primaire": "Ma facture n'a pas été payée",
          "secondaire": "comment recouvrer ma créance ?"
        },
        {
          "primaire": "Je souhaite immatriculer une société",
          "secondaire": "quelles sont les démarches à suivre ?"
        }
      ],
      "Registre du commerce3": [
        {
          "primaire": "Comment obtenir un kbis ?",
          "secondaire": "Et combien ça coute ?"
        },
        {
          "secondaire": "quand et comment le faire ?",
          "primaire": "Dépôt des comptes annuels"
        },
        {
          "primaire": "Ma facture n'a pas été payée",
          "secondaire": "comment recouvrer ma créance ?"
        },
        {
          "primaire": "Je souhaite immatriculer une société",
          "secondaire": "quelles sont les démarches à suivre ?"
        }
      ],
      "Registre du commerce2": [
        {
          "primaire": "Comment obtenir un kbis ?",
          "secondaire": "Et combien ça coute ?"
        },
        {
          "secondaire": "quand et comment le faire ?",
          "primaire": "Dépôt des comptes annuels"
        },
        {
          "primaire": "Ma facture n'a pas été payée",
          "secondaire": "comment recouvrer ma créance ?"
        },
        {
          "primaire": "Je souhaite immatriculer une société",
          "secondaire": "quelles sont les démarches à suivre ?"
        }
      ],
      "Registre du commerce1": [
        {
          "primaire": "Comment obtenir un kbis ?",
          "secondaire": "Et combien ça coute ?"
        },
        {
          "secondaire": "quand et comment le faire ?",
          "primaire": "Dépôt des comptes annuels"
        },
        {
          "primaire": "Ma facture n'a pas été payée",
          "secondaire": "comment recouvrer ma créance ?"
        },
        {
          "primaire": "Je souhaite immatriculer une société",
          "secondaire": "quelles sont les démarches à suivre ?"
        }
      ]
    }
    setSuggestionMygreffe(customOliver["suggestions_questions_mygreffe"] || []);
  }, [customOliver]);

  // Mettre à jour rowsNumber en fonction du nombre de sauts de ligne dans formValue
  useEffect(() => {
    const lineCount = (formValue.match(/\n/g) || []).length + 1; // +1 pour compter la première ligne
    setRowsNumber(lineCount);
  }, [formValue]);

  /** 
   * Gestionnaire pour envoyer des messages lorsque la touche "Entrée" est pressée.
   * La fonction vérifie quel'utilisateur n'est pas en train d'écrire,
   * que la valeur du formulaire n'est pas vide et qu'aucune réponse n'est en cours de calcul avant d'envoyer le message.
   */

  // Vérifier si la touche pressée est "Entrée", s'il n'y a pas d'écriture en cours, 
  // Vérifier si formValue a au moins 20 caractères
  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();  // Empêche l'action par défaut pour ne pas soumettre le formulaire
      setRowsNumber(prev => prev + 1);
      setFormValue(prev => prev + "\n"); // Ajoute une nouvelle ligne au texte
      return; // Quitte la fonction pour ne pas exécuter le reste du code
    }

    if ((e.key === "Enter" || e.type === "click") &&
      !writing && formValue.trim().length > 0 && !computingResponse) {
      if (formValue.trim().length < 20) {
        setModalOpen(true);
      } else {
        setSelectedOption({ displayOptionsFormalite: false });
        if (messages.length === 0 && currentUser) {
          await handleCreateConversation(currentUser.uid, [createMessage(formValue, "user", undefined, {}, -1)])
        }
        setShowPanel(true);
        sendMessage(formValue, setFormValue);
      }
    }
  };

  /** 
  * Fonction pour gérer les clics sur les suggestions de messages.
  */
  const handleSuggestions = async (message) => {
    const combinedMessage = `${message["primaire"]}\n${message["secondaire"] || ''}`;
    setFormValue(combinedMessage);
    setIsQuestionPersonnel(true);
    setSelectedOption({ displayOptionsFormalite: false });
    setShowPanel(true)
    sendMessage(combinedMessage, setFormValue);
    if (currentUser) {
      await handleCreateConversation(currentUser.uid, [createMessage(combinedMessage, "user", undefined, {}, -1)])
    }

  };

  const option_formalités_d_entreprise = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    const sentence = "Que souhaitez-vous faire ?".split(" ");

    const printWordByWord = async () => {
      for (const word of sentence) {
        // Generate a random timeout between 1 and 5 seconds (in milliseconds)
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();
  }

  const option_effectuer_formalites_d_entreprise = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);
    const sentence = "Quels type de formalité souhaitez-vous réaliser ?".split(" ");
    const printWordByWord = async () => {
      for (const word of sentence) {
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();
  }

  const normalizeString = (str) => str.trim().replace(/^ +/gm, '');

  async function getDocumentsIdsCreationByTag(tag) {
    const collectionRef = collection(db, `annuaire_formalites_creation`);
    const queryRef = query(collectionRef, where("tag", "==", tag));

    try {
      const snapshot = await getDocs(queryRef);
      const documents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      return documents;
    } catch (error) {
      console.error("Erreur lors de la récupération des documents par tag:", error);
      throw new Error(error);
    }
  }

  const option_creation_formalites = () => {
    setWriting(true);
    const newMsgSystem = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsgSystem, setMessages);

    getDocumentsIdsCreationByTag("Entrepreneur individuel")
      .then(statutaires => {
        const markdownLinksStatutaires = statutaires.map(doc =>
          `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
        ).join('\n');

        return getDocumentsIdsCreationByTag("Sociétés commerciales")
          .then(autres => {
            const markdownLinksAutres = autres.map(doc =>
              `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
            ).join('\n');

            return getDocumentsIdsCreationByTag("Sociétés civiles")
              .then(autres => {
                const markdownLinksSC = autres.map(doc =>
                  `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
                ).join('\n');

                return getDocumentsIdsCreationByTag("Autres sociétés")
                  .then(autres => {
                    const markdownLinksAutresSociete = autres.map(doc =>
                      `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
                    ).join('\n');

                    const markdownContent = `
                    Vous souhaitez immatricurer une :
                    
                    **Entrepreneur individuel :**
                    ${markdownLinksStatutaires}
                    
                    **Sociétés commerciales :**
                    ${markdownLinksAutres}

                    **Sociétés civiles :**
                    ${markdownLinksSC}

                    **Autres sociétés :**
                    ${markdownLinksAutresSociete}
                    `.split(" ")

                    const printWordByWord = async () => {
                      for (const word of markdownContent) {
                        const randomTimeout = Math.floor(Math.random() * 5) + 20;
                        await new Promise(resolve => setTimeout(resolve, randomTimeout));
                        update_last_system_message_tokens(`${word} `, setMessages);
                      }
                      setWriting(false);
                    };
                    printWordByWord();
                  });
              });
          });
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des documents:", error);
        setWriting(false);
      });
    // setWriting(true);
    // const newMsgSystem = createMessage("", "system", undefined, {}, -2);
    // updateMessage(newMsgSystem, setMessages);

    // getDocumentsIdsCreationByTag(children)
    //   .then(documentIds => {
    //     const markdownLinks = documentIds.map(id =>
    //       `- [${id.replace(/ /g, ' ')}](#formalite/${encodeURIComponent(id)})`
    //     ).join('\n');

    //     const markdownContent = `\n\n${markdownLinks}
    //     `.split(" ");

    //     const printWordByWord = async () => {
    //       for (const word of markdownContent) {
    //         const randomTimeout = Math.floor(Math.random() * 5) + 20;
    //         await new Promise(resolve => setTimeout(resolve, randomTimeout));
    //         update_last_system_message_tokens(`${word} `, setMessages);
    //         setWriting(false);
    //       }
    //     };
    //     printWordByWord();
    //   })
    //   .catch(error => {
    //     console.error("Erreur lors de la récupération des IDs des documents :", error);
    //   });
  };

  async function getDocumentsIdsModificationByTag(tag) {
    const collectionRef = collection(db, `annuaire_formalites_modification`);
    const queryRef = query(collectionRef, where("tag", "==", tag));

    try {
      const snapshot = await getDocs(queryRef);
      const documents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      return documents;
    } catch (error) {
      console.error("Erreur lors de la récupération des documents par tag:", error);
      throw new Error(error);
    }
  }

  const option_modification_formalites = () => {
    setWriting(true);
    const newMsgSystem = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsgSystem, setMessages);

    getDocumentsIdsModificationByTag("statutaires")
      .then(statutaires => {
        const markdownLinksStatutaires = statutaires.map(doc =>
          `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
        ).join('\n');

        return getDocumentsIdsModificationByTag("autres")
          .then(autres => {
            const markdownLinksAutres = autres.map(doc =>
              `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
            ).join('\n');

            const markdownContent = `
            Veuillez cliquer sur la formalité de modification que vous souhaitez effectuer :
            
            **Modifications statutaires :**
            ${markdownLinksStatutaires}
            
            **Autres modifications :**
            ${markdownLinksAutres}
            `.split(" ")

            const printWordByWord = async () => {
              for (const word of markdownContent) {
                const randomTimeout = Math.floor(Math.random() * 5) + 20;
                await new Promise(resolve => setTimeout(resolve, randomTimeout));
                update_last_system_message_tokens(`${word} `, setMessages);
              }
              setWriting(false);
            };
            printWordByWord();
          });
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des documents:", error);
        setWriting(false);
      });
  };

  const option_cessation_formalites = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    getAllDocumentIdsFromCategory("cessation")
      .then(documentIds => {
        const markdownLinks = documentIds.map(id =>
          `- [${id.replace(/ /g, ' ')}](#formalite/${encodeURIComponent(id)})`
        ).join('\n');

        const markdownContent = `
      Veuillez cliquer sur la formalité de cessation que vous souhaitez réaliser :\n\n${markdownLinks}
      `.split(" ");

        const printWordByWord = async () => {
          for (const word of markdownContent) {
            const randomTimeout = Math.floor(Math.random() * 5) + 20;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            update_last_system_message_tokens(`${word} `, setMessages);
            setWriting(false);
          }
        };
        printWordByWord();
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des IDs des documents :", error);
      });
  }
  async function getAllDocumentIdsFromCategory(category) {
    const collectionRef = collection(db, `annuaire_formalites_${category}`);
    try {
      const snapshot = await getDocs(collectionRef);
      const documentIds = snapshot.docs.map(doc => doc.id);
      return documentIds;
    } catch (error) {
      console.error(`Erreur lors de la récupération des IDs des documents pour ${category}:`, error);
    }
  }

  const option_secretariat_formalites = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    getAllDocumentIdsFromCategory("secretariat")
      .then(documentIds => {
        const markdownLinks = documentIds.map(id =>
          `- [${id.replace(/ /g, ' ')}](#formalite/${encodeURIComponent(id)})`
        ).join('\n');

        const markdownContent = `
      Veuillez cliquer sur la formalité de secrétariat juridique que vous souhaitez effectuer :\n\n${markdownLinks}
      `.split(" ");
        const printWordByWord = async () => {
          for (const word of markdownContent) {
            const randomTimeout = Math.floor(Math.random() * 5) + 20;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            update_last_system_message_tokens(`${word} `, setMessages);
            setWriting(false);
          }
        };
        printWordByWord();
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des IDs des documents :", error);
      });
  }

  const option_checkup_entreprise = () => {

    setWriting(true);
    var newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    const sentence = "Pour faire le checkup d'une entreprise, veuillez renseigner sa dénomination ou son numéro de Siren :".split(" ");

    const printWordByWord = async () => {
      for (const word of sentence) {
        // Generate a random timeout between 1 and 5 seconds (in milliseconds)
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();
  }


  const handleOptionClick = (option) => {
    // console.log(option);
    setDisplayOptionsFormalite(false);
    setIsFormality(true);
    setCurrentSection("Formalité juridique");
    const newMsg = createMessage(option.name, "user", undefined, {}, -1);
    updateMessage(newMsg, setMessages);
    setSelectedOption({ ...option, displayOptionsFormalite: false });

    switch (option.action) {
      case "formalities":
        option_formalités_d_entreprise();
        break;
      case "effectuer":
        option_effectuer_formalites_d_entreprise();
        break;
      case "suivre":
        option_checkup_entreprise();
        // setDisplayOptionsFormalite(true);
        break;
      case "creation":
        option_creation_formalites();
        // setDisplayOptionsFormalite(true);
        break;
      case "modification":
        option_modification_formalites();
        break;
      case "cessation":
        option_cessation_formalites();
        break;
      case "secretariat":
        option_secretariat_formalites();
        break;
      default:
        console.log(option);
    }
  };

  // const formalitieJuridique = () => {
  //   setIsFormality(true);
  //   setCurrentSection("Formalité juridique")
  //   const option = {
  //     'class': 'filter_button_suivre_formalite',
  //     'name': "Réaliser une formalité juridique",
  //     'action': 'formalities',
  //     'key': 0
  //   }
  //   const newMsg = createMessage(option.name, "user", undefined, {}, -1);
  //   updateMessage(newMsg, setMessages);
  //   setSelectedOption({ ...option, displayOptionsFormalite: false });

  //   setWriting(true);
  //   const newMsg1 = createMessage("", "system", undefined, {}, -2);
  //   updateMessage(newMsg1, setMessages);

  //   const sentence = "Que souhaitez-vous faire ?".split(" ");

  //   const printWordByWord = async () => {
  //     for (const word of sentence) {
  //       const randomTimeout = Math.floor(Math.random() * 10) + 60;
  //       await new Promise(resolve => setTimeout(resolve, randomTimeout));
  //       update_last_system_message_tokens(`${word} `, setMessages);
  //     }
  //     setWriting(false);
  //   };
  //   printWordByWord();

  // }
  const generateConversationTitle = (messages) => {
    let title = "";
    for (const message of messages) {
      if (title.length + message.content.length + 1 > 60) {
        // Couper le message si l'ajout dépasse 60 caractères
        title += message.content.slice(0, 60 - title.length);
        break;
      }
      title += (title.length > 0 ? " " : "") + message.content;
    }
    return title.substring(0, 60).trim(); // Assurer que le titre ne dépasse pas 60 caractères
  };

  async function addNewConversation(userId, messages) {

    try {
      // Vérifier le nombre de conversations existantes
      const userConversationsRef = collection(db, "users", userId, "conversations");
      const conversationsQuery = query(userConversationsRef, orderBy("createdAt", "asc"));
      const querySnapshot = await getDocs(conversationsQuery);

      // Si le nombre de conversations est >= 20, supprimer la plus ancienne
      if (querySnapshot.size >= 20) {
        const oldestConversation = querySnapshot.docs[0];
        await deleteDoc(oldestConversation.ref);
        console.log("Oldest conversation deleted:", oldestConversation.id);
      }

      // Ajouter la nouvelle conversation
      const title = "";
      const newConversationRef = await addDoc(userConversationsRef, {
        titre: title,
        createdAt: serverTimestamp(),
        lastActive: serverTimestamp(),
        messages: messages
      });

      console.log("New conversation added with ID:", newConversationRef.id);
      return newConversationRef.id;
    } catch (error) {
      console.error("Error managing conversations:", error);
      throw error;
    }
  }

  const handleCreateConversation = async (userId, messages) => {
    try {
      console.log("handleCreateConversation: ", userId, messages);
      const conversationId = await addNewConversation(userId, messages);
      setCurrentConversationId(conversationId);
      console.log("Conversation créée avec succès. ID:", conversationId, "messages: ", messages);
    } catch (error) {
      console.error("Erreur lors de la création de la conversation:", error);
    }
  }

  const newQuestion = async () => {
    clearChat();
    setIsQuestionPersonnel(true);
    setWriting(true);
    const newMsg1 = createMessage("", "system", undefined, {}, -1);
    updateMessage(newMsg1, setMessages);
    const messageSentence = "Bonjour, je suis votre nouvel assistant juridique, comment puis-je vous aider aujourd'hui ?"
    const sentence = messageSentence.split(" ");
    const printWordByWord = async () => {
      for (const word of sentence) {
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();

    if (currentUser) {
      console.log("newQuestion: ", currentUser.uid, [createMessage(messageSentence, "system", undefined, {}, -1)])
      await handleCreateConversation(currentUser.uid, [createMessage(messageSentence, "system", undefined, {}, -1)])
    }

  }
  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */
  useEffect(() => {
    const scroll = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    // Planifie le défilement pour après les mises à jour du DOM
    requestAnimationFrame(scroll);
  }, [messages, writing]);

  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  // useEffect(() => {
  //   inputRef.current.focus();
  // }, []);

  const handleSidebarToggle = () => {
    if (sideBarOpen) {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
      if (rightPanelOpen) {
        setRightPanelOpen(false);
      }
    }
  };

  const [showAllSuggestions, setShowAllSuggestions] = useState(false);

  const toggleShowAllSuggestions = () => {
    console.log(showAllSuggestions);
    setShowAllSuggestions(prevState => !prevState);
  };

  return (
    <div className={`flex flex-col duration-300 relative w-full dark:bg-light-grey h-[100%]`}
    // ${rightPanelOpen ? "h-0" : `${liensUtiles.length > 0 || experts.length > 0 ? "h-[90%]" : "h-[100%]"}`}
    // className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden"
    >
      <main
        // className="chatview__chatarea overflow-x-auto w-full"
        className="relative h-full w-full flex-1 overflow-hidden transition-width"
      >
        <div role="presentation" tabIndex="0" className="flex h-full flex-col focus-visible:outline-0">

          <Header
            sideBarOpen={sideBarOpen}
            setSideBarOpen={setSideBarOpen}
            showSideBar={showSideBar}
            messages={messages}
            currentSection={currentSection}
            clearChat={clearChat}
            setShowPanel={setShowPanel}
            isFormality={isFormality}
            newQuestion={newQuestion}
            currentUser={currentUser}
            setModalLoginOpen={setModalLoginOpen}
            showPanel={showPanel}
            liensUtiles={liensUtiles}
            experts={experts}
          />


          <div className={`flex-1 overflow-y-auto`}>
            <div className="relative h-full">
              {messages.length === 0 && (
                <div className="flex h-full flex-col items-center justify-start">
                  <div className="flex flex-col items-center justify-end">
                    <img src={logoInfogreffe} alt="Logo Oliver" className="mb-2 md:mb-4 w-30 h-28" />

                    <p className="text-center text-first-oliver">
                      L'assistant juridique boosté à l'IA <br /> des greffiers des tribunaux de commerce
                    </p>


                  </div>
                </div>
              )}

              {messages.length >= 1 && (
                <div className="flex flex-col text-sm pb-9">
                  <div className="flex flex-wrap relative mx-auto juice:gap-4 mb-2 juice:md:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] ">
                    {console.log("messages: ", messages)}
                    {messages.map((message, index) => (
                      <>
                        {/* {console.log("message: ", index, " : ", message)} */}
                        <ChatMessage
                          key={index}
                          index={index}
                          message={message}
                          writing={index === messages.length - 1 && writing}
                          setVersionsOpen={setVersionsOpen}
                          handleOptionClick={handleOptionClick}
                          sideBarOpen={sideBarOpen}
                          showPanel={showPanel}
                        />
                      </>
                    ))}
                    <span ref={messagesEndRef}></span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`w-full md:pt-0 dark:border-white/20 md:border-transparent ${sideBarOpen && 'overflow-hidden'} md:dark:border-transparent md:w-[calc(100%-.5rem)] juice:w-full`}>
            <div className="px-3 text-sm md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
              {messages.length === 0 && (
                // <div className={`flex flex-wrap items-center justify-center relative mx-auto juice:gap-4 mb-2 juice:md:gap-6 ${subDomains.includes(subdomain) && !isQuestionPersonnel ? 'md:max-w-4xl' : 'md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]'}`}>
                <div className={`flex flex-wrap items-center justify-center relative mx-auto juice:gap-4 mb-2 juice:md:gap-6 ${subDomains.includes(subdomain) && !isQuestionPersonnel && subdomain !== "infogreffe" ? 'md:max-w-4xl' : 'md:max-w-lg lg:max-w-[40rem] xl:max-w-[48rem]'}`}>
                  {subdomain === "infogreffe" ? (
                    <div className="flex justify-center border-b-[1px] mb-2 w-full gap-3">
                      <p className="text-[#06094F] text-center">
                        Vous souhaitez avoir un panorama fiable des démarches relatives aux formalités des entreprises ?

                      </p>
                      {isQuestionPersonnel && (
                        <div className="text-[#06094F] text-end"
                          onClick={() => setIsQuestionPersonnel(!isQuestionPersonnel)}
                        >
                          <span className="cursor-pointer font-extrabold hover:border-b hover:border-[#06094F]"> Revenir au guide </span>

                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col h-full">
                      {showAllSuggestions ? (
                        <div className="flex-1 overflow-y-auto max-h-[75vh] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
                          {Object.entries(suggestionMygreffe).map(([category, suggestions], categoryIndex) => (
                            <div key={categoryIndex} className="mb-4">
                              {categoryIndex === 0 && (
                                <div className="flex justify-end my-2 w-full">
                                  <div
                                    className="text-[#06094F] text-end cursor-pointer"
                                    onClick={toggleShowAllSuggestions}
                                  >
                                    <span className="hover:border-b hover:border-[#06094F]">
                                      <GrClose size={25} className="mr-4" />
                                    </span>
                                  </div>
                                </div>
                              )}
                              <h3 className="text-lg font-semibold text-[#06094F] top-0 p-2 shadow-sm">
                                {category}
                              </h3>
                              <div className="flex flex-wrap">
                                {suggestions.map((suggestion, index) => (
                                  <div
                                    className="md:w-1/2 w-full"
                                    key={index}
                                    onClick={() => handleSuggestions(suggestion)}
                                  >
                                    <div className="m-1 bg-[#C3F7E8] hover:bg-[#54E1BB] rounded-bl-lg rounded-tl-lg rounded-br-lg p-2 cursor-pointer transition-colors duration-200">
                                      <p className="text-[#06094F] text-start text-sm dark:text-slate-200 leading-none p-1">
                                        {suggestion.primaire}
                                      </p>
                                      <span className="text-sm text-start text-stone-500 p-1">
                                        {suggestion.secondaire}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex flex-wrap">
                          {suggestion.slice(0, 4).map((suggestion, index) => (
                            <div
                              className={`md:w-1/2 w-full`}
                              key={index}
                              onClick={() => handleSuggestions(suggestion)}
                            >
                              <div className="m-1 bg-[#C3F7E8] hover:bg-[#54E1BB] rounded-bl-lg rounded-tl-lg rounded-br-lg p-2 cursor-pointer transition-colors duration-200">
                                <p className="text-[#06094F] text-start text-sm dark:text-slate-200 leading-none p-1">
                                  {suggestion.primaire}
                                </p>
                                <span className="text-sm text-start text-stone-500 p-1">
                                  {suggestion.secondaire}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="mt-auto">
                        <div className="flex justify-end my-2 w-full">
                          <div
                            className="text-[#3ADAAF] text-end cursor-pointer"
                            onClick={toggleShowAllSuggestions}
                          >
                            <span className="hover:border-b hover:border-[#3ADAAF]">
                              {showAllSuggestions ? "" : "Afficher toutes les suggestions"}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b-[1px] my-2 w-full">
                          <p className="text-[#06094F] text-start">
                            Vous avez une question d'ordre juridique ou administratif ?
                          </p>
                        </div>
                      </div>
                    </div>
                  )}



                  {
                    subDomains.includes(subdomain) && !isQuestionPersonnel && (
                      <>

                        {subdomain === "mesformalites" && suggestionFormalities.map((suggestedMessage, index) => (
                          <div
                            className={`inline-block ${index > 1 ? "md:block hidden" : ""}`}
                            key={index}
                            onClick={() => handleSuggestions(suggestedMessage)}
                          >
                            <div className="m-1 bg-[#C3F7E8] hover:bg-[#54E1BB] rounded-bl-lg rounded-tl-lg rounded-br-lg p-2 cursor-pointer">
                              <p className="text-[#06094F] text-center text-[12.9px] dark:text-slate-200 leading-none p-1">
                                {suggestedMessage["primaire"]}
                              </p>
                            </div>
                          </div>
                        ))}

                        {subdomain === "infogreffe" ? (
                          <>
                            <div
                              className={`inline-block ${0 > 1 ? "md:block hidden" : ""}`}
                              key={0}
                            // onClick={() => handleSuggestions(suggestedMessage)}
                            >
                              <div className="m-1 bg-first-oliver-color  hover:bg-[#06084f90] rounded-lg p-2 cursor-pointer">
                                <p className="text-white text-center text-[12.9px] dark:text-slate-200 leading-none p-1">
                                  <a href="https://www.infogreffe.fr/guide-des-formalites/registre-du-commerce" target="_blank">

                                    Accéder au guichet des formalités infogreffe
                                  </a>

                                </p>
                              </div>
                            </div>

                            <div
                              className="inline-block"
                              onClick={() => setIsQuestionPersonnel(!isQuestionPersonnel)}
                            >
                              <div className="m-1 bg-white border border-[#06094F] hover:bg-[#06084f2a] rounded-lg p-2 cursor-pointer">
                                <p className="text-[#06094F] text-center font-bold text-xs dark:text-slate-200 leading-none p-1">
                                  Poser une question personnalisée
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            className="inline-block"
                            onClick={() => setIsQuestionPersonnel(!isQuestionPersonnel)}
                          >
                            <div className="m-1 bg-white border border-[#C3F7E8] hover:bg-[#C3F7E8] rounded-bl-lg rounded-tl-lg rounded-br-lg p-2 cursor-pointer">
                              <p className="text-[#06094F] text-center font-bold text-xs dark:text-slate-200 leading-none p-1">
                                Question personnalisée
                              </p>
                            </div>
                          </div>
                        )}

                      </>
                    )
                  }
                </div>
              )}
            </div>

            <div className={`text-base px-2 m-auto md:px-5 lg:px-1 ${sideBarOpen && 'overflow-hidden'} xl:px-5 ${subDomains.includes(subdomain) && !isQuestionPersonnel ? 'hidden' : ''}`}>
              <div className="mx-auto flex flex-1 gap-3 text-base juice:gap-4 juice:md:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                <form className="w-full">
                  <div className="absolute bottom-full left-0 right-0 z-20"><div className="relative h-full w-full"><div className="flex flex-col gap-3.5 pb-3.5 pt-2"></div></div></div>
                  <div className="flex items-center rounded-lg dark:bg-gray-700">
                    <textarea
                      rows={rowsNumber}
                      ref={inputRef}
                      autoFocus={false}
                      readOnly={writing}
                      value={formValue}
                      className={`block ${rowsNumber === 1 && "overflow-hidden"} mr-2 md:mr-4 py-3 px-5 w-full text-sm text-gray-900 input bg-white rounded-3xl  ${sideBarOpen && 'overflow-hidden'}`}
                      placeholder={`${window.innerWidth >= 768 ? "Posez une question claire en précisant le contexte..." : "Votre question..."}`}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => setFormValue(e.target.value)}
                    />
                    {writing ? (
                      <button
                        type="submit"
                        className="inline-flex justify-center p-2 text-white bg-gray-100 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCloseWS();
                        }}
                      >
                        <FaStop size={20} className="text-gray-700" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`inline-flex justify-center p-2 text-white ${formValue.trim().length >= 20 ? 'bg-[#06094F]' : 'bg-[#06094F] opacity-40'} rounded-full cursor-pointer dark:text-blue-500 dark:hover:bg-gray-600`}
                        disabled={formValue.trim().length < 20 || computingResponse}
                        onClick={(e) => {
                          e.preventDefault();
                          handleKeyDown(e);
                          // sendMessage(formValue, setFormValue);
                        }}
                      >
                        <FaTelegramPlane size={23} />
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="relative px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]"></div>
            {mobileMode && !rightPanelOpen && !writing && (liensUtiles.length > 0 || experts.length > 0 || messages[messages.length - 1]?.id === -4) && (
              <div className={`w-full flex justify-center relative border-b-0 border-[#06094F] z-0 ${rightPanelOpen ? "" : "bottom-[0px]"}`} >
                <div className={`w-full flex justify-center absolute z-0 ${rightPanelOpen ? "" : "top-[-14px]"}`} >
                  <div className={"w-20 flex"} onClick={() => setRightPanelOpen(!rightPanelOpen)}>
                    <VscTriangleUp
                      className={`w-20 border bg-[#06094F] border-[#06094F]  flex items-center justify-center cursor-pointer text-white ${rightPanelOpen ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
                {(experts.length > 0 || liensUtiles.length > 0) && (
                  <div
                    onClick={() => setRightPanelOpen(!rightPanelOpen)}
                    className="w-full h-full z-0 relative bg-[#06094F] border-b-0 border-[#06094F] py-2 flex"
                  >

                    {experts.length > 0 && (
                      <div className={`no-hidden flex items-center w-[65%] bg-[#06094F]  justify-center`}>
                        <div
                          className={`flex items-center justify-center h-8 w-8 rounded-full bg-white text-[#06094F]`}
                        >
                          <span className=" font-medium">{experts.length}</span>
                        </div>
                        <div
                          className={`leading-5 w-[75%] pl-4 font-medium text-white`}
                        >
                          Experts pour vous accompagner
                        </div>
                      </div>
                    )}
                    {liensUtiles.length > 0 && (
                      <div className="flex items-center w-[35%]  bg-[#06094F] justify-center">
                        <div
                          className={` flex items-center justify-center  h-8 w-8 rounded-full bg-white text-[#06094F]`}
                        >
                          <span className=" font-medium">{liensUtiles.length}</span>
                        </div>
                        <div
                          className={`leading-5 w-[70%] pl-4 font-medium text-white`}
                        >
                          Liens utiles
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {messages[messages.length - 1]?.id === -4 && (
                  <div className="flex justify-stretch w-full p-3 bg-[#06094F] border-b-2 border-[#06094F]"
                    onClick={() => setRightPanelOpen(!rightPanelOpen)}
                  >
                    <svg width="35" height="35" viewBox="0 0 40 48" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.8354 1.73444C11.2183 1.183 11.9943 1.03399 12.5688 1.40162C13.1432 1.76924 13.2984 2.51428 12.9155 3.06572L9.21104 8.40006H27.0398L23.3354 3.06572C22.9524 2.51428 23.1076 1.76925 23.682 1.40162C24.2565 1.034 25.0325 1.183 25.4155 1.73444L30.0446 8.40042C33.1147 8.4351 35.447 11.0879 34.9284 14.0051L34.1444 18.4156C33.3872 18.0749 32.584 17.8128 31.7461 17.6403L32.464 13.6013C32.7234 12.1423 31.5474 10.8001 29.9838 10.8001H6.26704C4.70341 10.8001 3.52744 12.1423 3.78679 13.6013L6.13316 26.8013C6.33766 27.952 7.3826 28.8001 8.61341 28.8001H18.1425C18.1897 29.6284 18.3342 30.4318 18.5651 31.2001H8.61341C6.17165 31.2001 4.07953 29.5158 3.66878 27.2051L1.32241 14.0051C0.803876 11.0879 3.13618 8.4351 6.20619 8.40042L10.8354 1.73444Z" fill="#fff" />
                      <path d="M33.444 26.5868C33.8965 26.0863 33.8408 25.3283 33.3195 24.8939C32.7981 24.4595 32.0086 24.513 31.5561 25.0135L28.2023 28.7225L27.2287 27.5461C26.7993 27.0272 26.013 26.9408 25.4725 27.353C24.9319 27.7654 24.8419 28.5202 25.2713 29.0391L27.181 31.3466C27.4123 31.626 27.761 31.7921 28.1326 31.7998C28.5042 31.8076 28.8601 31.6562 29.1037 31.3868L33.444 26.5868Z" fill="#fff" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 28.2C20 23.2294 24.1973 19.2 29.375 19.2C34.5527 19.2 38.75 23.2294 38.75 28.2C38.75 33.1705 34.5527 37.2 29.375 37.2C24.1973 37.2 20 33.1705 20 28.2ZM29.375 21.6C25.5781 21.6 22.5 24.5549 22.5 28.2C22.5 31.845 25.5781 34.8 29.375 34.8C33.1719 34.8 36.25 31.845 36.25 28.2C36.25 24.5549 33.1719 21.6 29.375 21.6Z" fill="#fff" />
                    </svg>
                    <h1 className="text-white font-bold p-1 "> Réserver ma formalité</h1>

                  </div>
                )}


              </div>
            )}
          </div>

        </div>
      </main>

      {/* Modal pour les versions disponibles du chat, s'affiche en fonction de l'état versionsOpen*/}
      <Modal
        title={modalTitle}
        modalOpen={versionsOpen}
        setModalOpen={setVersionsOpen}
        modalSize={"auto"}
      >
        {/* Composant enfant qui affiche les versions disponibles et permet de changer la version active du chat. */}
        <VsersionsDisponibles setModalVersions={setVersionsOpen} modalVersion={versionsOpen} setModalTitle={setModalTitle} />
      </Modal>

      <Modal
        title=""
        modalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        modalSize={"auto"}
      >
        <div className="flex flex-col items-center justify-center p-2">
          {/* <img src={logoInfogreffe} alt="Logo" className="w-40 mb-6" /> */}
          <BsExclamationCircle size={90} className="mb-6" />
          <p className="text-center mb-6">Vous devez renseigner minimum <span className="underline">20 caractères</span> pour déclencher une réponse.</p>
          <p className="text-center mb-6">Donnez plus de contexte et/ou soyez plus précis.</p>
          <button
            onClick={() => setModalOpen(false)}
            className="signupFormContainerContinuer text-white font-bold"
          >
            OK, j'ai compris
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ChatView;
