import React from 'react';

const ConfirmationPopup = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Confirmez votre adresse e-mail</h2>
                <p>Un e-mail de confirmation a été envoyé à votre adresse. Veuillez cliquer sur le lien dans l'e-mail pour activer votre compte.</p>
                <button onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
};

export default ConfirmationPopup;