import React from 'react';
import { GoSearch } from 'react-icons/go';
import { MdAdd } from 'react-icons/md';
import UserDropdownMenu from './UserDropdownMenu';

const Header = ({
    sideBarOpen,
    setSideBarOpen,
    showSideBar,
    messages,
    currentSection,
    clearChat,
    setShowPanel,
    isFormality,
    newQuestion,
    currentUser,
    setModalLoginOpen,
    showPanel,
    liensUtiles,
    experts
}) => {
    return (
        <div className="sticky top-0 z-10 w-full">
            <div className="flex items-center justify-between px-3 pt-4">
                {/* Menu de gauche */}
                <div className="flex-shrink-0">
                    {!sideBarOpen && (
                        <button
                            className="w-12 h-11 flex items-center justify-center hover:bg-blue-100 rounded-lg"
                            onClick={() => setSideBarOpen(!sideBarOpen)}
                        >
                            <img src={showSideBar} alt="Toggle Sidebar" />
                        </button>
                    )}
                </div>

                {/* Contenu central (breadcrumb et boutons) */}
                <div className="flex-grow flex justify-center">
                    <div className="w-full max-w-[48rem] flex items-center justify-between">
                        {/* Breadcrumb */}
                        {messages.length >= 1 && (
                            <>
                                <div className="flex items-center text-xs text-[#06094F]">
                                    <span className="cursor-pointer hover:border-b border-[#06094F]" onClick={() => { clearChat(); setShowPanel(true); }}>Accueil</span>
                                    {/* <span className="mx-1">&gt;</span>
                                    <span className="cursor-pointer hover:border-b border-[#06094F]">{currentSection}</span> */}
                                </div>

                                {/* Bouton (Nouvelle question ou Réinitialiser) */}
                                <button
                                    className="flex items-center gap-2 border border-[#06094F] hover:bg-[#06094F] text-[#06094F] hover:text-white px-2 py-1 mr-2 sm:mr-2 md:mr-4 lg:mr-10 rounded-md text-sm"
                                    onClick={() => {
                                        if (isFormality) {
                                            clearChat();
                                            // formalitieJuridique();
                                            setShowPanel(true);
                                        } else {
                                            newQuestion();
                                            setShowPanel(true);
                                        }
                                    }}
                                >
                                    {isFormality ? (
                                        "Réinitialiser"
                                    ) : (
                                        <>
                                            <MdAdd />
                                            <span>Nouvelle question</span>
                                        </>
                                    )}
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {!showPanel ? (
                    <div className="relative">
                        {/* UserDropdownMenu aligné horizontalement avec le sidebar */}
                        {currentUser ?
                            <div className="h-11 flex items-center">
                                <UserDropdownMenu currentUser={currentUser} />
                            </div>
                            :
                            <button
                                className="flex items-center gap-2 border-[3px] border-[#06094F] hover:bg-[#06094F] text-[#06094F] hover:text-white px-3 py-2 rounded-full text-sm"
                                onClick={() => setModalLoginOpen(true)}
                            >
                                <span>Se connecter</span>
                            </button>
                        }

                        {/* L'icône en dessous */}
                        <div className={`absolute top-full ${!currentUser ? "left-7" : "left-0"} mt-2`}>
                            <div className="w-12 h-11 cursor-pointer flex justify-center items-center rounded-lg opacity-50" onClick={() => setShowPanel(true)}>
                                {messages[messages.length - 1]?.id === -4 ? (
                                    <svg width="35" height="35" className="hover:bg-gray-200 rounded-md" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8354 1.73444C11.2183 1.183 11.9943 1.03399 12.5688 1.40162C13.1432 1.76924 13.2984 2.51428 12.9155 3.06572L9.21104 8.40006H27.0398L23.3354 3.06572C22.9524 2.51428 23.1076 1.76925 23.682 1.40162C24.2565 1.034 25.0325 1.183 25.4155 1.73444L30.0446 8.40042C33.1147 8.4351 35.447 11.0879 34.9284 14.0051L34.1444 18.4156C33.3872 18.0749 32.584 17.8128 31.7461 17.6403L32.464 13.6013C32.7234 12.1423 31.5474 10.8001 29.9838 10.8001H6.26704C4.70341 10.8001 3.52744 12.1423 3.78679 13.6013L6.13316 26.8013C6.33766 27.952 7.3826 28.8001 8.61341 28.8001H18.1425C18.1897 29.6284 18.3342 30.4318 18.5651 31.2001H8.61341C6.17165 31.2001 4.07953 29.5158 3.66878 27.2051L1.32241 14.0051C0.803876 11.0879 3.13618 8.4351 6.20619 8.40042L10.8354 1.73444Z" fill="#06094F" />
                                        <path d="M33.444 26.5868C33.8965 26.0863 33.8408 25.3283 33.3195 24.8939C32.7981 24.4595 32.0086 24.513 31.5561 25.0135L28.2023 28.7225L27.2287 27.5461C26.7993 27.0272 26.013 26.9408 25.4725 27.353C24.9319 27.7654 24.8419 28.5202 25.2713 29.0391L27.181 31.3466C27.4123 31.626 27.761 31.7921 28.1326 31.7998C28.5042 31.8076 28.8601 31.6562 29.1037 31.3868L33.444 26.5868Z" fill="#06094F" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 28.2C20 23.2294 24.1973 19.2 29.375 19.2C34.5527 19.2 38.75 23.2294 38.75 28.2C38.75 33.1705 34.5527 37.2 29.375 37.2C24.1973 37.2 20 33.1705 20 28.2ZM29.375 21.6C25.5781 21.6 22.5 24.5549 22.5 28.2C22.5 31.845 25.5781 34.8 29.375 34.8C33.1719 34.8 36.25 31.845 36.25 28.2C36.25 24.5549 33.1719 21.6 29.375 21.6Z" fill="#06094F" />
                                    </svg>
                                ) : (liensUtiles.length > 0 || experts.length > 0) ? (
                                    <>
                                        <div className='absolute right-[23px]'>{liensUtiles.length + experts.length}</div>
                                        <GoSearch color="#06094F" className="scale-110 rounded-md mt-1" size={80} />
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <span className="absolute bottom-full -mb-[5rem] right-8 max-w-[calc(100vw-3rem)] w-14 transform scale-0 rounded bg-[#06094F] p-1 text-xs text-white group-hover:scale-100 transition duration-150 ease-in-out">
                                Déployer
                            </span>
                        </div>
                    </div>
                ) : liensUtiles.length === 0 && experts.length === 0 && currentUser ? (

                    <div className="flex items-center h-11">
                        <UserDropdownMenu currentUser={currentUser} />
                    </div>
                ) : liensUtiles.length === 0 && experts.length === 0 && showPanel &&
                <button
                    className="h-11 flex items-center gap-2 border-2 border-[#06094F] hover:bg-[#06094F] text-[#06094F] hover:text-white px-3 py-1 rounded-full text-sm"
                    onClick={() => setModalLoginOpen(true)}
                >
                    <span>Se connecter</span>
                </button>
                }
            </div>
        </div>
    );
};

export default Header;