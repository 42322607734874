import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

const Faq = ({ }) => {
  const { customOliver, custom_MFL, subdomain,
    subDomains, } = useAppContext();
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    if (customOliver["faq"] != undefined) {
      if (subDomains.includes(subdomain)) {
        setFaq(custom_MFL["faq"]);
      } else {
        setFaq(customOliver["faq"]);
      }
    }
  }, [customOliver, custom_MFL]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  console.log("faq: ", faq);
  // Event handler to change the selected item
  const handleSelectItem = (index) => {
    setSelectedIndex(index);
  };

  const sortedFaq = faq.sort((a, b) => {
    // Assigner une valeur par défaut très élevée si 'order' n'est pas présent
    const orderA = a.order !== undefined ? a.order : 999;
    const orderB = b.order !== undefined ? b.order : 999;
    return orderA - orderB;
  });

  return (
    <>
      <div className="space-y-2">
        {sortedFaq.map((item, index) => (
          <div key={index} className="collapse collapse-plus border border-black hover:bg-slate-200">
            <input
              type="radio"
              name="my-accordion-3"
              checked={index === selectedIndex}
              onClick={() => handleSelectItem(index)}
              onChange={() => { }}
            />
            <div className="collapse-title text-xl font-medium">{item.q}</div>
            <div className="collapse-content">
              <p>{item.a}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Faq;
