import React, { useContext } from 'react';
import { Dropdown, DropdownHeader, DropdownItem, DropdownDivider } from "flowbite-react";
import { PiSignOutFill } from "react-icons/pi";
import { FaUser } from 'react-icons/fa6';
import { AuthContext } from '../../context/AuthContext';

// Composant Avatar personnalisé
const CustomAvatar = ({ initial }) => {
    return (
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#06094F] text-white">
            <span className="text-xl">{initial}</span>
        </div>
    );
};

const UserDropdownMenu = ({ currentUser }) => {
    const { handleLogout } = useContext(AuthContext);

    return (
        <Dropdown
            label={<CustomAvatar initial={currentUser.displayName[0].toUpperCase()} />}
            arrowIcon={false}
            inline
        >
            <DropdownHeader>
                <div className='flex justify-between gap-2'>
                    <div className='flex items-center justify-center'>
                        <FaUser size={25} />
                    </div>
                    <div>
                        <span className="block text-sm">{currentUser.displayName}</span>
                        <span className="block truncate text-sm font-medium">{currentUser.email}</span>
                    </div>
                </div>
            </DropdownHeader>
            <DropdownDivider />
            <DropdownItem
                icon={() => {
                    return <PiSignOutFill size={25} className="mr-2" />
                }}
                onClick={handleLogout}
            >
                Se déconnecter
            </DropdownItem>
        </Dropdown>
    );
};

export default UserDropdownMenu;