import Modal from "../Modal";
import { fetchData } from "../../utils/ask_api";
import { useState, useEffect } from "react";
import { useAppContext } from "../../context/AppContext";

const CreditAccount = ({setOpen}) => {
  const [currency, setCurrency] = useState("EUR");
  const [sliderAmount, setSliderAmount] = useState(20); // Start at 0
  const [unitPrice, setUnitPrice] = useState(0); // Unit price of one credit in cents
  const [quantity, setQuantity] = useState(0); // Quantity of credits

  const { currentUser, credits, configCreditOliver } = useAppContext();

  useEffect(() => {

    if (configCreditOliver) {
      setUnitPrice(configCreditOliver.unitAmount);
      setCurrency(configCreditOliver.currency);
    }




  }, [configCreditOliver]);

  useEffect(() => {
    if (unitPrice !== 0) {
      updateAmount(sliderAmount);
    }
  }, [unitPrice]);

  const handleSliderChange = (e) => {
    const selectedAmount = e.target.value;
    updateAmount(selectedAmount);
  };

  const handleInputChange = (e) => {
    const enteredAmount = e.target.value;
    updateAmount(enteredAmount);
  };

  const updateAmount = (newAmount) => {
    setSliderAmount(newAmount);
    // Calculate the quantity based on the new amount (converted to cents) and unit price
    const calculatedQuantity = Math.floor((newAmount * 100) / unitPrice);
    setQuantity(calculatedQuantity);
  };

  const handleCreateCheckoutSession = async (event) => {
    event.preventDefault();
    const payload = {
      quantity: quantity,
      customer_email: currentUser.email,
    };
    const response = await fetchData(
      "create-checkout-session",
      payload,
      "POST"
    );
    window.location.href = response.url;
  };

  return (
    <>
      
        <div className="w-full h-full md:px-16 pt-8">
          <section className="">
            <div className="mb-10">
              <p className="text-slate-500 text-lg">Montant à ajouter :</p>
            </div>
            <form>
              <div>
                <div className="w-full flex flex-col mb-5">
                  <input
                    type="range"
                    min={10}
                    max="160"
                    step="1"
                    value={sliderAmount}
                    className="range range-primary range-xs"
                    onChange={handleSliderChange}
                  />
                  <div className="w-full flex justify-between text-sm text-gray-300 ">
                    <p>10€</p>
                    <p>160€</p>
                  </div>
                </div>
                <div className="flex relative mb-8">
                  <div className="absolute left-[0.1rem] h-10 w-6 rounded-md rounded-r-none bg-[#EBEBF2] font-semibold items-center justify-center flex border border-gray-300">
                    <p>€</p>
                  </div>
                  <input
                    type="number"
                    value={sliderAmount}
                    className="pl-6 block w-full h-10 input input-sm border border-gray-300 rounded-md rounded-l-none ml-2 border-l-0"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="text-slate-500 mb-8">
                  <p>
                    Nombre de requêtes :{" "}
                    <span className="font-bold">{quantity}</span>{" "}
                    <span className="text-xs text-slate-300">
                      (soit 0.35 € / requête){" "}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <button
                  role="link"
                  id="submit"
                  type="button"
                  className="bg-blue-logo-color text-white hover:text-blue-logo-color hover:bg-white btn w-[70%] mb-4"
                  onClick={handleCreateCheckoutSession}
                >
                  Payer en ligne
                </button>
                { credits < 1 &&
                <p onClick={() => setOpen(false)} className="text-blue-logo-color text-sm cursor-pointer">Continuer avec la version basique</p>
                }
              </div>
            </form>
          </section>
        </div>
    </>
  );
};

export default CreditAccount;
