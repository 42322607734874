import React, { useEffect, useState } from 'react';
import { auth } from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';


const WaitingForConfirmation = () => {
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkEmailVerification = () => {
            const user = auth.currentUser;
            console.log('checkEmailVerification users...', user);
            if (user) {
                // console.log('users...');
                user.reload().then(() => {
                    // console.log('user.emailVerified...', user.emailVerified);
                    if (user.emailVerified) {
                        // console.log('OUIIIIIIIII user.emailVerified...', user.emailVerified);
                        setIsEmailVerified(true);
                        setTimeout(() => navigate('/'), 2000);
                    } else {
                        // console.log('NONOOOOOOOOOOOOOOOOOOOOOO user.emailVerified...', user.emailVerified);
                    }
                });
            }
        };

        const interval = setInterval(checkEmailVerification, 5000);

        return () => clearInterval(interval);
    }, [navigate]);


    return (
        <div className="flex flex-col items-center justify-center p-4 gap-6">
            <h1 className='font-bold text-lg'>Confirmation de l'adresse e-mail</h1>
            <BsExclamationCircle size={90} />

            {isEmailVerified ? (
                <p>Votre e-mail a été vérifié. Redirection en cours...</p>
            ) : (
                <div>
                    <p> <strong> Veuillez vérifier votre boîte de réception et cliquer sur le lien de confirmation. </strong>  </p>
                    <p>Cette page se mettra à jour automatiquement une fois votre e-mail confirmé.</p>
                </div>
            )}
        </div>
    );
}

export default WaitingForConfirmation;