import React, { useState } from 'react';
import { FaCheck, FaRegEdit } from 'react-icons/fa';

const GestionnaireDossier = ({ formality, updateFormality }) => {
  const [editMode, setEditMode] = useState(false);
  const [nomMsb, setNomMsb] = useState(formality.nom_msb || '');
  const [prenomMsb, setPrenomMsb] = useState(formality.prenom_msb || '');

  const handleInputChange = (e, field) => {
    if (field === 'nom_msb') {
      setNomMsb(e.target.value);
    } else if (field === 'prenom_msb') {
      setPrenomMsb(e.target.value);
    }
  };

  const handleSaveClick = () => {
    updateFormality(formality.id, nomMsb, prenomMsb);
    setEditMode(false);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  return (
    <td className='px-1 py-4 border'>
      {editMode ? (
        <div className='flex flex-wrap justify-start gap-1 px-1'>
          <input
            placeholder='Prénom'
            className='px-2 border border-1 border-gray-200 input'
            type="text"
            value={prenomMsb}
            onChange={(e) => handleInputChange(e, 'prenom_msb')}
          />
          <div className='inline-flex items-center justify-start gap-1 relative'>

            <input
              placeholder='Nom'
              className='px-2 border border-1 border-gray-200 input'
              type="text"
              value={nomMsb}
              onChange={(e) => handleInputChange(e, 'nom_msb')}
            />
            <FaCheck
              color='#0cde64'
              className='p-1 cursor-pointer rounded-md border hover:bg-gray-200'
              size={30}
              onClick={handleSaveClick}
            />
          </div>
        </div>
      ) : (
        <div className='flex justify-around items-center gap-1'>
          <span className='capitalize'>{prenomMsb || "Prénom"} <span className='uppercase'>{nomMsb || "Nom"}</span></span>
          <FaRegEdit
            className='p-1 cursor-pointer rounded-md border hover:bg-gray-200'
            size={30}
            onClick={handleEditClick}
          />
        </div>
      )}
    </td>
  );
};

export default GestionnaireDossier;

