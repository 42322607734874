import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAppContext();
  const location = useLocation();
  if (!currentUser) {
    sessionStorage.setItem('redirectPath', location.pathname);
    return <Navigate to="/auth/login" replace />;
  }

  return children;
}

export default ProtectedRoute;
