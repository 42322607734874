import React, { useState } from "react";
import FicheRenforcement from "./FicheRenforcement";
import ViewFiches from "./ViewFiches";
import Expert from "./Expert";
import ViewExperts from "./ViewExperts";
import UpdatePrompt from "./UpdatePrompt";
import ParamsOliver from "./ParamsOliver";
import Document from "./Document";
const AdminPanel = ({
}) => {
  const [tmpOption, setTmpOption] = useState("ajouter_fiche_renforcement");
  const [tmpId, setTmpId] = useState(null);

  return (
    <>
      <div className="adminPanelContainer">
        <div className="adminPanelOnglets overflow-x-auto">
          {/* <button className={`${tmpOption === "ajouter_fiche_renforcement" ? "border-blue-logo-color border-4	" : ""} btn`} onClick={() => setTmpOption("ajouter_fiche_renforcement")}>Ajouter une fiche de renforcement</button> */}
          <button
            className={`${tmpOption === "voir_fiches"
              ? "border-blue-logo-color border-4	"
              : ""
              } btn`}
            onClick={() => setTmpOption("voir_fiches")}
          >
            Voir les fiches
          </button>
          {/* <button className={`${tmpOption === "ajouter_expert" ? "border-blue-logo-color border-4	" : ""} btn`} onClick={() => setTmpOption("ajouter_expert")}>Ajouter un expert</button> */}
          <button
            className={`${tmpOption === "voir_experts"
              ? "border-blue-logo-color border-4	"
              : ""
              } btn`}
            onClick={() => setTmpOption("voir_experts")}
          >
            Voir les experts
          </button>
          <button
            className={`${tmpOption === "modifier_prompt"
              ? "border-blue-logo-color border-4	"
              : ""
              } btn`}
            onClick={() => setTmpOption("modifier_prompt")}
          >
            Modifier prompt
          </button>
          <button
            className={`${tmpOption === "params_oliver"
              ? "border-blue-logo-color border-4	"
              : ""
              } btn`}
            onClick={() => setTmpOption("params_oliver")}
          >
            Params. Oliver
          </button>
          <button
            className={`${tmpOption === "modifier_fiche"
              ? "border-blue-logo-color border-4	btn relative"
              : "hidden"
              }`}
            onClick={() => setTmpOption("ajouter_expert")}
          >
            Modification d'une fiche
          </button>
          <button
            className={`${tmpOption === "modifier_expert"
              ? "border-blue-logo-color border-4	btn relative"
              : "hidden"
              }`}
            onClick={() => setTmpOption("ajouter_expert")}
          >
            Modification d'un expert
          </button>
          <button
            className={`${tmpOption === "ajouter_document"
              ? "border-blue-logo-color border-4	"
              : ""
              } btn`}
            onClick={() => setTmpOption("ajouter_document")}
          >
            Ajouter document
          </button>
        </div>
        {tmpOption === "ajouter_fiche_renforcement" && (
          <div className="adminPanelOpyion">
            <FicheRenforcement />
          </div>
        )}
        {tmpOption === "voir_fiches" && (
          <div className="adminPanelOpyion">
            <ViewFiches setTmpId={setTmpId} setTmpOption={setTmpOption} />
          </div>
        )}
        {tmpOption === "modifier_fiche" && (
          <div className="adminPanelOpyion">
            <FicheRenforcement id={tmpId} />
          </div>
        )}
        {tmpOption === "ajouter_expert" && (
          <div className="adminPanelOpyion">
            <Expert />
          </div>
        )}
        {tmpOption === "voir_experts" && (
          <div className="adminPanelOpyion">
            <ViewExperts setTmpId={setTmpId} setTmpOption={setTmpOption} />
          </div>
        )}
        {tmpOption === "modifier_expert" && (
          <div className="adminPanelOpyion">
            <Expert id={tmpId} />
          </div>
        )}
        {tmpOption === "modifier_prompt" && (
          <div className="adminPanelOpyion">
            <UpdatePrompt />
          </div>
        )}
        {tmpOption === "params_oliver" && (
          <div className="adminPanelOpyion">
            <ParamsOliver />
          </div>
        )}
        {tmpOption === "ajouter_document" && (
          <div className="adminPanelOpyion">
            <Document />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminPanel;
