import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";
import { MdDelete } from "react-icons/md";
import { GrDocumentUpdate } from "react-icons/gr";
import { useAppContext } from "../../context/AppContext";

const ViewFiches = ({ setTmpId, setTmpOption }) => {
  const { parentIds } = useAppContext();

  const [docs, setDocs] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchDocs = async (nb_docs) => {
    setIsLoading(true);
    try {
      const response = await fetchData(
        "list_fiche_renforcement",
        {
          nb_docs: nb_docs, // Number of documents to fetch per request
          last_timestamp: lastTimestamp,
        },
        "POST"
      );
      const data = await response;
      setLastTimestamp(data.last_timestamp);
      if (data.fiches_renforcement.length > 0) {
        setDocs([...docs, ...data.fiches_renforcement]);
      }
    } catch (error) {
      console.error("Error fetching documents: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDelete = async (id) => {
    // Confirm before deleting
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette fiche?"
    );
    if (isConfirmed) {
      try {
        // Call the API to delete the document by ID
        await fetchData(
          "delete_fiche_renforcement",
          {
            doc_id: id,
          },
          "POST"
        );
        // Remove the document from the state
        setDocs(docs.filter((doc) => doc.id !== id));
      } catch (error) {
        alert("Erreur lors de la supression du doc: ", error);
      }
    }
  };

  const handleUpdate = async (id) => {
    try {
      setTmpId(id);
      setTmpOption("modifier_fiche");
    } catch (error) {
      alert("Erreur lors de la mise à jour du doc: ", error);
    }
  };

  useEffect(() => {
    fetchDocs("5"); // Initial fetch when the component mounts
    // Cleanup function
    return () => {
      // Cleanup tasks, if any
    };
  }, []); // Fetch documents whenever lastTimestamp changes

  const handleLoadMore = () => {
    if (lastTimestamp === "") {
      return;
    }
    // Fetch the next batch of documents when the "Load More" button is clicked
    fetchDocs(null);
  };

  return (
    <div className="flex flex-col px-4">
      <div><button onClick={() => setTmpOption('ajouter_fiche_renforcement')} className="bg-[#3ED400] p-1 px-2 text-white font-semibold rounded mb-2">AJOUTER</button></div>
      <div className=" space-y-4">
        {docs.map((doc, index) => (
          <div
            className="bg-white rounded-lg px-4 py-1 shadow-lg flex justify-between items-center"
            key={index}
          >
            <div>
              <h2 className="text-xl font-semibold mb-1">{doc.name}</h2>
              <p className="text-gray-600 mb-1">
                Date d'ajout: {new Date(doc.timestamp).toLocaleString()}
              </p>
              <p className="text-gray-600 mb-1">
                Id parent: {parentIds[doc.parent_id].titre}
              </p>
            </div>
            <div className="space-x-4 flex justify-center">
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={() => handleUpdate(doc.id)}
              >
                <GrDocumentUpdate />
              </button>
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() => handleDelete(doc.id)}
              >
                <MdDelete />
              </button>
            </div>
          </div>
        ))}
        {isLoading && <p>Loading...</p>}
        {!isLoading && (
          <button
            className="p-1 rounded-md cursor-pointer btn text-base"
            onClick={handleLoadMore}
            disabled={lastTimestamp === ""}
          >
            Charger tous
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewFiches;
