import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import { fetchData } from "../utils/ask_api";

const Contact = ({ contactOpen, setContactOpen }) => {
  const { currentUser, subdomain } = useAppContext();
  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [statusMessage, setStatusMessage] = useState("");
  const [statusMessageColor, setStatusMessageColor] = useState("");

  const displayStatusMessage = (message, color) => {
    setStatusMessage(message);
    setStatusMessageColor(color);
    setTimeout(() => {
      setStatusMessage("");
      setStatusMessageColor("");
    }, 5000);
  };

  const handleSendMessage = async () => {
    if (message.length > 0 && email.length > 0) {
      try {
        const data = await fetchData("contact", {
          email,
          message,
        }, "POST");
        displayStatusMessage(data.message, "success");
        setMessage("");
        setEmail("");
      } catch (err) {
        displayStatusMessage(
          "Une erreur est survenue, veuillez réessayer plus tard",
          "error"
        );
        console.log(err);
      }
    } else {
      displayStatusMessage("Veuillez remplir tous les champs", "error");
    }
  };

  return (
    <>
      <div className="">
        <p className="mb-4">Une question, un commentaire, une remarque ? N'hésitez pas à nous écrire</p>
        {!currentUser && (
          <input
            className="w-full p-1 mr-2 input input-bordered border-black mb-6"
            placeholder="Votre email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        <textarea
          className="w-full h-32 p-1 mr-2 input input-bordered bg-[#F4F4F4]  border-black mb-3 "
          placeholder="Votre message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        {<p className="mb-4">Pour obtenir les coordonnées d’un greffe du Tribunal de commerce, cliquer <a
          href="https://www.cngtc.fr/fr/rechercher-un-greffe.php"
          target="_blank" // Ouvre le lien dans un nouvel onglet
          rel="noopener noreferrer" // Sécurité pour les liens ouvrant un nouvel onglet
          style={{ color: 'blue', textDecoration: 'underline' }} // Style direct
        >
          ici
        </a>
          .</p>}

        <div className="flex justify-between items-center">
          <div
            className={`pl-2 text-sm ${statusMessageColor == "error" ? "text-red-500" : "text-green-500"
              }`}
          >
            {statusMessage}
          </div>
          <button
            className="bg-blue-logo-color text-white px-6 py-2 rounded-md"
            onClick={() => handleSendMessage(false)}
          >
            Envoyer
          </button>
        </div>
      </div>
    </>
  );
};

export default Contact;
