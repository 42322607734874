import React from "react";
import Login from "./Login";
import { FaCheck } from "react-icons/fa6";
import CreditAccount from "../user_profile/CreditAccount";
import { useState, useEffect } from "react";
import { useAppContext } from "../../context/AppContext";

const VsersionsDisponibles = ({
  setModalVersions,
  modalVersion,
  setModalTitle,
}) => {
  const { currentUser, credits, setVersion } = useAppContext();
  const [option, setOption] = useState("");

  useEffect(() => {
    if (modalVersion) {
      setOption("");
      setModalTitle("Versions disponibles");
    }
  }, [modalVersion]);
  return (
    <div className="w-full flex flex-col">
      {option == "login" && (
        <Login
          setModalLoginOpen={setModalVersions}
          message="need_to_be_connected"
        />
      )}

      {option == "credit" && <CreditAccount setOpen={setModalVersions} />}

      {option == "" && (
        <>
          <div className="w-full flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full border border-0 border-b-2 pb-4 md:p-0 md:border-none">
              <div>
                <p className="text-xl font-bold">Basique</p>
                <p className="font-semibold text-slate-400">Gratuit</p>
              </div>

              <button
                disabled={true}
                className="bg-slate-100 text-slate-300 p-2 rounded-md px-6 w-full md:w-[90%] my-3"
              >
                Votre sélection actuelle
              </button>
              <div className="pr-8 text-[0.85em] mb-4">
                Pour les personnes qui posent des questions sur des démarches
                courantes
              </div>

              <div className=" text-[0.85em] space-y-2">
                <div className="flex  items-center space-x-4">
                  <FaCheck />
                  <p>Modèle GPT 3.5</p>
                </div>

                <div className="flex  items-center space-x-4">
                  <FaCheck />
                  <p>Nombre de questions illimitées</p>
                </div>

                <div className="flex  items-center space-x-4">
                  <FaCheck />
                  {/* <p>Accès aux experts et aux liens utiles</p> */}
                  <p>Accès aux liens utiles</p>
                </div>
              </div>
            </div>

            <div className="md:w-1/2 w-full md:pt-0 pt-2">
              <div>
                <p className="text-xl font-bold">Avancée</p>
                <p className="font-semibold text-slate-400">
                  Paiement à la requête
                </p>
              </div>

              {currentUser && credits <= 0 && (
                <button
                  onClick={() => {
                    setModalTitle("Alimentation du compte");
                    setOption("credit");
                  }}
                  className="bg-orange-logo-color text-white p-2 rounded-md px-6 w-full md:w-[90%] my-3 font-semibold"
                >
                  Alimenter mon compte
                </button>
              )}
              {currentUser && credits > 0 && (
                <button
                  onClick={() => setVersion(1)}
                  className="bg-orange-logo-color text-white p-2 rounded-md px-6 w-full md:w-[90%] my-3 font-semibold"
                >
                  Utiliser la version avancée
                </button>
              )}
              {!currentUser && (
                <button
                  onClick={() => {
                    setModalTitle("");
                    setOption("login");
                  }}
                  className="bg-orange-logo-color text-white p-2 rounded-md px-6 w-full md:w-[90%] my-3 font-semibold"
                >
                  Essayer gratuitement
                </button>
              )}

              <div className="pr-8 text-[0.85em] mb-4">
                Pour les personnes qui posent des questions plus élaborées
              </div>

              <div className=" text-[0.85em] space-y-2">
                <p>
                  Tous ce que est inclus dans la version Basique, avec en plus :
                </p>
                <div className="flex  items-center space-x-4">
                  <FaCheck />
                  <p>Modèle GPT 4-Turbo</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VsersionsDisponibles;
