import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { IoIosSync } from 'react-icons/io';
import StepIndicator from './StepIndicat';

const CheckoutForm = ({
    currentStep,
    setCurrentStep,
    acceptTerms,
    acceptCgus,
    setAcceptTerms,
    setAcceptCgus,
    setModalTitle,
    setModalCguOpen,
    handleSubmitFormalitie,
    piecesRequises,
    billingInformation,
    validations,
    setMessageError,
    eDevisIsSand
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }
        if (!stripe || !clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Using updated billing info:', billingInformation);
        console.log('piecesRequises:', piecesRequises);

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href
            },
            redirect: 'if_required',
        });

        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            console.log('Paiement réussi', paymentIntent);
            await handleSubmitFormalitie(billingInformation, piecesRequises);
        }

        setIsLoading(false);
    };

    const options = {
        paymentElementOptions: {
            layout: 'tabs',
        },
    };

    return (
        <>
            <form id="payment-form">
                <PaymentElement id="payment-element" className='mb-4' options={options.paymentElementOptions} />
                {/* Affiche un indicateur de chargement si le paiement est en cours */}
                {isLoading && (
                    <div className="text-center">
                        <IoIosSync className="inline mr-2 w-8 h-8 text-gray-500 animate-spin" /> {/* Spinner de Tailwind CSS */}
                        <p className="inline">Veuillez patienter, la transaction est en cours...</p>
                    </div>
                )}
                <div>
                    <div className="text-xs">
                        <label>
                            <input
                                type="checkbox"
                                id="termsCheckbox"
                                checked={acceptTerms}
                                onChange={(e) => setAcceptTerms(e.target.checked)}
                                style={{ marginRight: "5px" }}
                            />
                            En cochant cette case, je consens au traitement de mes
                            données personnelles. Je dispose d'un droit d'accès, de
                            rectification et d'opposition aux données me concernant en
                            contactant Legaware. Pour plus d'informations, consultez
                            notre <span
                                onClick={() => { setModalTitle("Politique de confidentialité"); setModalCguOpen(true) }}
                                className="text-blue-500 underline cursor-pointer">POLITIQUE DE CONFIDENTIALITÉ.</span>{" "}
                        </label>
                    </div>
                    <div className="text-xs">
                        <input
                            type="checkbox"
                            id="cgusCheckbox"
                            className="mr-2"
                            checked={acceptCgus}
                            onChange={(e) => setAcceptCgus(e.target.checked)}
                        />
                        <label htmlFor="cgusCheckbox">
                            En cochant cette case, je reconnais avoir pris connaissance
                            et accepté les{" "}
                            <span
                                onClick={() => { setModalTitle("CGU"); setModalCguOpen(true) }}
                                className="text-blue-500 underline cursor-pointer">
                                CONDITIONS GÉNÉRALES DE VENTES ET D'UTILISATION DU SITE
                            </span> <span>
                                infogreffe.oliver.legal.
                            </span>
                        </label>
                    </div>
                </div>
                <div className={`flex ${eDevisIsSand ? 'justify-center' : 'justify-between'} p-2`}>
                    {!eDevisIsSand &&
                        <StepIndicator currentStep={currentStep} setCurrentStep={setCurrentStep} />
                    }
                    <button
                        className="p-2 rounded-lg px-8 bg-[#06094F] text-white hover:bg-[#3F83CA] hover:border-[#3F83CA] transition-colors duration-150"
                        onClick={(e) => {
                            e.preventDefault();
                            setMessageError('')
                            setMessage('')
                            const firstInvalidElementId = !billingInformation.prenom ? 'prenom-f'
                                : !billingInformation.nom ? 'nom-f'
                                    : !billingInformation.email ? 'email-f'
                                        : !billingInformation.tel ? 'tel-f'
                                            : !billingInformation.address ? 'adresse-f' : null;

                            if (firstInvalidElementId) {
                                const element = document.getElementById(firstInvalidElementId);
                                if (element) {
                                    console.log(element);
                                    const placeholder = element.placeholder;
                                    setMessageError(`Le champ ${placeholder} est obligatoire. Veuillez le compléter.`);
                                    element.focus();
                                    return;
                                }
                            } else {
                                setMessageError('')
                            }

                            if (!acceptTerms || !acceptCgus) {
                                setMessage("Pour continuer, veuillez accepter les conditions générales d'utilisation et la politique de confidentialité.");
                            }
                            else if (
                                billingInformation.prenom && billingInformation.nom &&
                                billingInformation.email && billingInformation.tel &&
                                billingInformation.address &&
                                validations.isFirstNameValid && validations.isLastNameValid &&
                                validations.isEmailValid && validations.isTelValid &&
                                validations.isAddressValid
                            ) {
                                handleSubmit(e);
                            }
                        }}
                        disabled={isLoading || !stripe || !elements}
                    >
                        Payer
                    </button>

                </div>
                {/* Show any error or success messages */}
                {message && <div id="payment-message" className='text-red-500'>{message}</div>}
            </form>
        </>
    );
}



export default CheckoutForm;