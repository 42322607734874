import React, { useState, useEffect } from 'react';

function useContinuousProgress(initialProgress, interval) {
    const [progress, setProgress] = useState(initialProgress);

    useEffect(() => {
        const timerId = setInterval(() => {
            setProgress(prevProgress => (prevProgress + 10) % 100);
        }, interval);

        return () => clearInterval(timerId);
    }, [interval]);

    return progress;
}

export default useContinuousProgress;