import FormalitiesAll from "../components/registreFormalites/FormalitiesAll";

const RegistreFormalites = () => {


  return (
    <FormalitiesAll />
  );
};
export default RegistreFormalites;
