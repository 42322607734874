import React, { useState } from "react";
import SelectedParentIds from "./SelectParentIds";
import SelectModel from "./SelectModel";
import { useAppContext } from "../../context/AppContext";
const Setting = ({ setModalOpen }) => {

  const { setSelectedParentIds } = useAppContext()
  const [option, setOption] = useState("base_de_connaissance");
  const [selectedParentIdsData, setSelectedParentIdsData] = useState({});


  const handleSubmit = () => {

    setModalOpen(false);
    function findTrueValues(obj) {
      let trueKeys = [];

      for (let key in obj) {
        if (typeof obj[key] === "object") {
          trueKeys = trueKeys.concat(findTrueValues(obj[key]));
        } else if (obj[key] === true) {
          trueKeys.push(key);
        }
      }

      return trueKeys;
    }
    setSelectedParentIds(findTrueValues(selectedParentIdsData));
  }

  return (
    <div className="w-full h-full">
      <div className="flex mb-3">
        <div className="w-1/2 px-2">
          <div
            className={`${option == "base_de_connaissance" && "font-bold bg-slate-100 "
              }  w-full text-center cursor-pointer p-2 `}
            onClick={() => setOption("base_de_connaissance")}
          >
            Base de connaissance
          </div>
        </div>
        <div className="w-1/2 px-2 border-l-2 border-black">
          <div
            className={`${option == "modeles" && "font-bold bg-slate-100 "
              }  w-full text-center cursor-pointer p-2 `}
            onClick={() => setOption("modeles")}
          >
            Modèles
          </div>
        </div>
      </div>
      <div>
        {
          option === "base_de_connaissance" ? (
            <SelectedParentIds selectedParentIdsData={selectedParentIdsData} setSelectedParentIdsData={setSelectedParentIdsData} />
          ) : (
            <SelectModel />
          )
        }
      </div>
      <div className="w-full flex justify-center">
        <button onClick={() => handleSubmit()} className="w-full max-w-xs btn btn-outline">Ok</button>
      </div>
    </div>
  );
};

export default Setting;
