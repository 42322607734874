import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { FiDelete, FiUpload } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { storage } from '../../firebase.config';
import { MdDelete } from 'react-icons/md';

const FileUploadComponent = ({
    docIndex,
    formalityId,
    existingFileUrl,
    existingFileName,
    existingUploadDate,
    isSent,
    onUploadSuccess,
    onUploadRemove,
    registerFormalaties,
    handleRemoveDocs,
    clientSendAllDocs,
    isFormalitesDone,
    canDelete,
    pathStorage = "documents" }) => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(existingFileUrl || null);
    const [uploading, setUploading] = useState(false);
    const [uploadDate, setUploadDate] = useState(() => {
        if (existingUploadDate) {
            if (existingUploadDate.seconds) {
                return new Date(existingUploadDate.seconds * 1000);
            }
            return new Date(existingUploadDate);
        }
        return null;
    });
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
            return;
        }
        setFile(file);
        uploadFile(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length && isValidFile(files[0])) {
            setFile(files[0]);
            uploadFile(files[0]);
        } else {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
        }
    };

    const isValidFile = (file) => {
        // La taille maximale autorisée en octets (20Mo = 20 * 1024 * 1024 octets)
        const maxSize = 20 * 1024 * 1024;
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        return file.size <= maxSize && allowedTypes.includes(file.type);
    };

    const uploadFile = (file) => {
        setUploading(true);
        const storageRef = ref(storage, `formalities/${formalityId}/${pathStorage}/${docIndex}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log(error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const date = new Date()
                    setUploadDate(date);
                    setFileUrl(downloadURL);
                    setUploading(false);
                    onUploadSuccess(downloadURL, file.name, date);
                });
            }
        );
    };

    const handleRemoveFile = () => {
        if (!fileUrl) return;
        const fileRef = ref(storage, fileUrl);
        deleteObject(fileRef).then(() => {
            console.log("File deleted successfully");
            setFile(null);
            setFileUrl(null);
            setUploadDate(null);
            onUploadRemove();
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleChangeTabTitle = () => {
        document.title = "MesFormalitesLegal - téléchargement";
    };

    const displayFormattedDate = (timestamp) => {
        console.log("displayFormattedDate : " + timestamp);
        if (!timestamp) {
            return 'Date non disponible';
        }

        try {
            // Vérifiez si timestamp a une méthode toDate (Firestore Timestamp)
            if (typeof timestamp.toDate === 'function') {
                const date = timestamp.toDate();
                return `Envoyé le ${date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })} à ${date.toLocaleTimeString('fr-FR')}`;
            }

            // Vérifiez si timestamp est un nombre (secondes Unix)
            if (typeof timestamp === 'number') {
                const date = new Date(timestamp * 1000);
                return `Envoyé le ${date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })} à ${date.toLocaleTimeString('fr-FR')}`;
            }

            // Traitez timestamp comme une chaîne de caractères ISO 8601 ou autre format compatible avec Date
            const date = new Date(timestamp);
            return `Envoyé le ${date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })} à ${date.toLocaleTimeString('fr-FR')}`;
        } catch (error) {
            return 'Date non disponible';
        }
    };




    if (isSent) {
        return (
            <div className="text-gray-500 italic">L'envoi des documents est désormais désactivé pour cette formalité.</div>
        );
    }

    return (
        <div className={`bg-gray-100 border-gray-300 shadow-md border rounded-lg px-2 pb-2 flex flex-col items-center`}>
            {/* && pathStorage === "documentsDeValidation" */}
            {canDelete && (registerFormalaties || pathStorage === "documentsDeValidation") && !isFormalitesDone && <MdDelete onClick={() => {
                handleRemoveDocs();
                // handleRemoveFile();
            }} color='red' className='self-end m-2 text-right cursor-pointer hover:scale-110' />}
            {!fileUrl && !uploading && !registerFormalaties && (
                <label
                    className="w-auto flex flex-col items-center px-4 py-4 text-first-oliver-color font-bold tracking-wide cursor-pointer hover:bg-blue text-center hover:text-blue-logo-color"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >

                    <span className="mt-2 text-base leading-normal">Faites glisser votre document</span>
                    <span className="my-2">OU</span>
                    <div className="flex justify-center items-center">
                        <span className="mt-2 text-base leading-normal flex items-center font-bold">
                            <FiUpload className="mr-2" /> Sélectionner un fichier
                        </span>
                    </div>
                    <input type="file" className="hidden" onChange={handleFileChange} />
                </label>
            )}

            {uploading && <p>Téléchargement en cours...</p>}

            {
                !clientSendAllDocs && registerFormalaties ? (
                    <div className="text-gray-500 italic">En attente d'envoi du document.</div>
                ) : (
                    fileUrl ? (
                        <div className={`${canDelete && (registerFormalaties || pathStorage === "documentsDeValidation") ? '' : 'mt-2'} w-full`}>
                            <div className="flex justify-start items-center">
                                <span className="text-base leading-normal flex items-center font-bold mr-2">
                                    <BsDownload className="mr-2" />
                                    {file ? file.name : existingFileName}
                                </span>
                                {uploadDate && (
                                    <span className="text-sm">
                                        {displayFormattedDate(uploadDate)}
                                    </span>
                                )}
                            </div>
                            <div className="mt-2 flex justify-start">

                                <a href={fileUrl} onClick={handleChangeTabTitle} className="text-blue-600 hover:text-blue-800 hover:underline mr-4" target="_blank" rel="noopener noreferrer">Télécharger</a>
                                {
                                    ((registerFormalaties === false && pathStorage === "documents" && !clientSendAllDocs) ||
                                        (pathStorage === "documentsDeValidation" && !isFormalitesDone)) && (
                                        <button
                                            onClick={handleRemoveFile}
                                            className="hover:underline hover:text-red-500"
                                        >
                                            Supprimer
                                        </button>
                                    )
                                }


                            </div>
                        </div>
                    ) : (
                        pathStorage === "documents" && registerFormalaties && <div className="text-gray-500 italic">En attente d'envoi du document.</div>
                    )

                )
            }










        </div>
    );
};

export default FileUploadComponent;
