import { useState } from "react";

const Evaluation = ({ starRating, onEvaluationSubmit }) => {
    const [message, setMessage] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusMessageColor, setStatusMessageColor] = useState("");

    const displayStatusMessage = (message, color) => {
        setStatusMessage(message);
        setStatusMessageColor(color);
        setTimeout(() => {
            setStatusMessage("");
            setStatusMessageColor("");
        }, 5000);
    };

    const handleSendMessage = async () => {
        if (message.length > 0) {
            try {
                onEvaluationSubmit(message);
                setMessage("");
            } catch (err) {
                displayStatusMessage(
                    "Une erreur est survenue, veuillez réessayer plus tard",
                    "error"
                );
                console.log(err);
            }
        } else {
            console.log(starRating)
            if (starRating > 2) {
                onEvaluationSubmit(message);
                setMessage("");
            } else {
                displayStatusMessage("Veuillez remplir tous les champs", "error");
            }
        }
    };

    return (
        <>
            <div className="">
                <p className="mb-4">Merci pour votre retour. Afin de valider votre évaluation et de nous aider à nous améliorer pouvez-vous expliquer en quoi la réponse n'était pas à la hauteur de vos attentes ?</p>
                <textarea
                    className="w-full h-32 p-1 mr-2 input input-bordered bg-[#F4F4F4]  border-black mb-3"
                    placeholder={`Votre message ${starRating > 2 ? '(optionnel)' : ''}`}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                <div className="flex justify-between items-center">
                    <div
                        className={`pl-2 text-sm ${statusMessageColor === "error" ? "text-red-500" : "text-green-500"
                            }`}
                    >
                        {statusMessage}
                    </div>
                    <button
                        className="bg-blue-logo-color text-white px-6 py-2 rounded-md"
                        onClick={() => handleSendMessage(false)}
                    >
                        Envoyer
                    </button>
                </div>
            </div>
        </>
    );
};

export default Evaluation;
