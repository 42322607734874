import { useAppContext } from "../../context/AppContext";
import { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { onSnapshot, doc } from "firebase/firestore";

const ViewInvoices = () => {
  const { currentUser, credits } = useAppContext();

  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    if (currentUser) {
      const unsubscribe = onSnapshot(
        doc(db, `invoices/${currentUser.uid}`),
        (doc) => {
          const data = doc.data();
          if (data) {
            const invoices = Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }));
            invoices.sort((a, b) => {
              return b.timestamp.seconds - a.timestamp.seconds  ||
                     b.timestamp.nanoseconds - a.timestamp.nanoseconds;
            });
            setInvoices(invoices);
            // let keys = Object.keys(data);
            // keys.map((key) => {
            //   setInvoices((prev) => [...prev, data[key]]);
            //   console.log(data[key]);
            //   console.log(key)<
            // })
          }
        }
      );

      // Unsubscribe from changes when the component is unmounted or dependencies change
      return () => unsubscribe();
    } else {
      setInvoices([]);
    }
  }, [db, currentUser]);

  // useEffect(() => {
  //   if (invoices) {
  //     invoices.map((invoice) => {
  //       console.log(invoice.id);
  //     });
  //   }
  // }, [invoices]);

  function formatDate(firebaseTimestamp) {
    const date = firebaseTimestamp.toDate(); // Convert to JavaScript Date
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function formatPrice(price) {
    return price / 100;

  }


  return (
    <>
    {invoices.length > 0 &&
      <div className="w-full h-full">
      <table className="min-w-full">
        <thead>
          <tr className="font-bold">
            <th className="text-left px-4 py-2">Facture(s)</th>
            <th className="text-left px-4 py-2">Date</th>
            <th className="text-left px-4 py-2">Montant</th>
            <th className="text-left px-4 py-2">Justificatif</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length > 0 &&
            invoices.map((invoice) => (
              <tr key={invoice.id} className="border-t">
                <td className="px-4 py-2">{invoice.id}</td>
                <td className="px-4 py-2">{formatDate(invoice.timestamp)}</td>
                <td className="px-4 py-2">{formatPrice(invoice.price)}€</td>
                <td className="px-4 py-2">
                  <a
                    href={invoice.url}
                    target="_blank"
                    className="text-blue-400 font-semibold underline cursor-pointer"
                  >
                    télécharger
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>}
    </>
  );
};

export default ViewInvoices;
