import React, { useEffect, useReducer, useState, useContext } from 'react';
import { BsDownload } from "react-icons/bs";
import { doc, updateDoc, getDoc, deleteField, onSnapshot } from "firebase/firestore";
import { useAppContext } from "../../context/AppContext";
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../firebase.config';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useWindowSize from '../../hooks/useWindowSize';
import { CiMenuKebab } from "react-icons/ci";
import FileUploadComponent from '../user_profile/FileUploadComponent';
import Modal from '../Modal';
import { fetchData } from '../../utils/ask_api';
import { IoDocumentTextSharp } from "react-icons/io5";
import logoOliver from "../../assets/logoOliver_vanillaDark3-removebg-preview.png";
import logoMesFormalites from "../../assets/MesFormalitesLegal.png";
import logoMesFormalitesClair from "../../assets/MesFormalites.Legal4.png";
import logoInfogreffe from "../../assets/logoOliver_vanillaDark3-removebg-preview.png";
import EditableeDevisTable from '../../components/registreFormalites/EditableeDevisTable';
import GestionnaireDossier from './GestionnaireDossier';
import { AuthContext } from "../../context/AuthContext";

import { MdLogout } from 'react-icons/md';


const RegistreFormalitiesTable = ({ formalities, setFormalities }) => {
    const { currentUser, subdomain, subDomains } = useAppContext();
    const [expandedRows, setExpandedRows] = React.useState({});
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalConfiramtionDelete, setModalConfiramtionDelete] = useState(false);

    const [isModalDossierOpen, setModalDossierOpen] = useState(false);
    const [selectedFormality, setSelectedFormality] = useState(null);
    const [file, setFile] = useState(null);
    const { handleLogout } = useContext(AuthContext);

    const handleRowClick = (formality) => {
        setSelectedFormality(formality);
        setModalDossierOpen(true);
        if (formality.status === "En attente du devis") {
            setSelectedOnglet("Éléments comptables")
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const filteredFormalities = formalities.filter(formality => {
            return (formality.nomEntreprise?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
                || (formality.titreFormalitie?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
                || (formality.nom_msb?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
                || (formality.prenom_msb?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
                || (formality.categorie?.toLowerCase().includes(searchQuery.toLowerCase()) || '');
        });
        setFormaliteFilterByStatus(filteredFormalities);
    }, [searchQuery, formalities]);




    const toggleExpand = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const Capitalize = (str) => {
        console.log(str);
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getStatusClass = (status) => {
        switch (status) {
            case "En attente du devis":
                return 'bg-yellow-200 text-yellow-600';
            case 'En attente de l’import des pièces et documents':
                return 'text-gray-500';
            case "Traitement en cours":
                return 'bg-yellow-200 text-yellow-600';
            case "Transmis au greffe":
                return 'text-gray-500';
            case "Validé par le greffe":
                return 'bg-yellow-200 text-yellow-600';
            case "Rejeté par le greffe":
                return 'bg-red-200 text-red-600'
            case "Dossiers refusés":
                return 'bg-red-200 text-red-600'
            case "Formalités clôtures":
                return 'bg-green-400 text-white'
            case "eDevis validés":
                return 'bg-green-400 text-green-700';
            default:
                return 'text-gray-500';
        }
    };

    const getbgMessageLeftMenu = (status) => {
        switch (status) {
            case "En attente du devis":
                return 'bg-yellow-200 text-yellow-600';
            case 'En attente de l’import des pièces et documents':
                return 'bg-white';
            case "Traitement en cours":
                return 'bg-yellow-200 text-yellow-600';
            case "Transmis au greffe":
                return 'bg-white';
            case "Validé par le greffe":
                return 'bg-yellow-200 text-yellow-600';
            case "Rejeté par le greffe":
                return 'bg-red-400'
            case "Dossiers refusés":
                return 'bg-red-200 text-red-600'
            case "Formalités clôtures":
                return 'bg-green-400'
            case "eDevis validés":
                return 'bg-green-400'
            default:
                return 'text-gray-500';
        }
    };

    const getStatusMessageLeftMenu = (status) => {
        switch (status) {
            case "En attente du devis":
                return 'eDevis à valider';
            case 'En attente de l’import des pièces et documents':
                return 'En attente des pièces';
            case "Traitement en cours":
                return 'Formalités à traiter';
            case "Transmis au greffe":
                return 'Formalités soumises au greffe'
            case "Validé par le greffe":
                return 'Formalités à clôturer'
            case "Rejeté par le greffe":
                return "Formalités rejetés"
            case "Dossiers refusés":
                return "Dossier refusé"
            default:
                return status;
        }
    }

    const getStatusMessage = (status) => {
        switch (status) {
            case "En attente du devis":
                return 'Demande eDevis';
            case 'En attente de l’import des pièces et documents':
                return 'En attente des pièces';
            case "Traitement en cours":
                return 'A traiter';
            case "Transmis au greffe":
                return 'Traitement en cours'
            case "Validé par le greffe":
                return 'Formalités à clôturer'
            case "Rejeté par le greffe":
                return "Rejeté"
            case "Dossiers refusés":
                return "Refusé"
            case "eDevis validés":
                return 'eDevis validés';
            default:
                return status;
        }
    };

    const [sortCriteria, setSortCriteria] = useState('dateReservation');
    const [sortDirection, setSortDirection] = useState('desc');

    const sortFormalities = (formalities) => {
        return formalities.sort((a, b) => {
            if (sortCriteria === 'dateReservation') {
                const dateA = new Date(a.dateReservation.seconds * 1000);
                const dateB = new Date(b.dateReservation.seconds * 1000);
                return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (sortCriteria === 'status') {
                return sortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
            }
            return 0;
        });
    };

    const handleSort = (criteria) => {
        if (sortCriteria === criteria) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCriteria(criteria);
            setSortDirection('asc');
        }
    };

    useEffect(() => {
        if (formalities) {
            setFormalities(formalities);
        }
    }, [formalities, setFormalities]);

    const handleFileUploadValidationDocumentsSuccess = async (formalityId, docId, titrePiece, fileUrl, fileName, uploadDate) => {
        console.log("START : handleFileUploadSuccess");
        console.log("docId: ", docId);
        console.log("fileUrl: ", fileUrl);
        console.log("fileName: ", fileName);
        console.log("uploadDate: ", uploadDate);

        setFormalities(currentFormalities => {
            return currentFormalities.map(formality => {
                if (formality.id === formalityId) {
                    const existingDocIndex = formality.validation_documents.findIndex(doc => doc.titrePiece === titrePiece);
                    let newValidationDocuments;

                    if (existingDocIndex !== -1) {
                        // Mise à jour du document existant
                        newValidationDocuments = formality.validation_documents.map((doc, index) =>
                            index === existingDocIndex
                                ? { ...doc, fileUrl, fileName, uploadDate, uploaded: true }
                                : doc
                        );
                    } else {
                        // Ajout d'un nouveau document
                        newValidationDocuments = [
                            ...formality.validation_documents,
                            {
                                docId,
                                titrePiece,
                                fileUrl,
                                uploaded: true,
                                fileName,
                                uploadDate,
                                canDelete: true,
                                required: true
                            }
                        ];
                    }

                    // Retourne un nouvel objet formality avec la liste mise à jour
                    return { ...formality, validation_documents: newValidationDocuments };
                }
                return formality; // Retourne la formalité non modifiée si l'ID ne correspond pas
            });
        });

        // Référence Firestore de la formalité de l'utilisateur
        const uid = formalityId.replace(/\d{14}$/, '');
        const userFormalityRef = doc(db, `formalities/${uid}`);
        try {
            const docSnap = await getDoc(userFormalityRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];

                const existingDocIndex = updatedFormality.validation_documents.findIndex(doc => doc.titrePiece === titrePiece);

                if (existingDocIndex !== -1) {
                    // Mise à jour du document existant
                    updatedFormality.validation_documents[existingDocIndex] = {
                        ...updatedFormality.validation_documents[existingDocIndex],
                        fileUrl,
                        fileName,
                        uploadDate,
                        uploaded: true
                    };
                    // alert("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH")
                } else {
                    // Ajout d'un nouveau document
                    updatedFormality.validation_documents.push({
                        docId,
                        titrePiece,
                        fileUrl,
                        uploaded: true,
                        fileName,
                        uploadDate,
                        canDelete: true,
                        required: true
                    });
                }
                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalityRef, {
                    [formalityId]: updatedFormality
                });
                updatedFormality["id"] = formalityId;
                setSelectedFormality(updatedFormality);

                console.log("Document mis à jour avec succès");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du document:", error);
        }
    };

    const handleFileUploadSuccess = async (formalityId, docId, titrePiece, fileUrl, fileName, uploadDate) => {
        console.log("START : handleFileUploadSuccess");
        console.log("docId: ", docId);
        console.log("fileUrl: ", fileUrl);
        console.log("fileName: ", fileName);
        console.log("uploadDate: ", uploadDate);
        console.log("titrePiece: ", titrePiece);

        // Mise à jour de l'état local
        setFormalities(currentFormalities => currentFormalities.map(formality => {
            if (formality.id === formalityId) {
                let updatedDocuments = formality.required_documents.slice(); // Crée une copie de la liste actuelle

                const existingDocIndex = updatedDocuments.findIndex(doc => doc.titrePiece === titrePiece);

                if (existingDocIndex !== -1) {
                    // Document exists, update it
                    const updatedDoc = {
                        ...updatedDocuments[existingDocIndex],
                        urlModel: fileUrl,
                        fileUrl: '',
                        uploaded: false,
                        fileName: '',
                        uploadDate: '',
                        required: true
                    };
                    updatedDocuments[existingDocIndex] = updatedDoc;
                } else {
                    // Document does not exist, add a new document
                    updatedDocuments.push({
                        titrePiece: titrePiece,
                        urlModel: fileUrl,
                        fileUrl: '',
                        uploaded: false,
                        fileName: '',
                        uploadDate: '',
                        required: true
                        // Assuming new documents are required by default
                    });
                }

                return { ...formality, required_documents: updatedDocuments };
            }
            return formality;
        }));



        // Mise à jour de Firestore
        try {
            const uid = formalityId.replace(/\d{14}$/, '');
            const userFormalityRef = doc(db, `formalities/${uid}`);
            // const userFormalityRef = doc(db, `formalities/${formalityId}`);
            const docSnap = await getDoc(userFormalityRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];

                let existingDocIndex = updatedFormality.required_documents.findIndex(doc => doc.titrePiece === docId);
                if (existingDocIndex !== -1) {
                    // Update existing document
                    updatedFormality.required_documents[existingDocIndex] = {
                        ...updatedFormality.required_documents[existingDocIndex],
                        fileUrl: fileUrl,
                        uploaded: true,
                        fileName: fileName,
                        uploadDate: uploadDate
                    };
                } else {
                    // Add new document
                    updatedFormality.required_documents.push({
                        titrePiece: titrePiece,
                        urlModel: fileUrl,
                        fileUrl: '',
                        uploaded: false,
                        fileName: '',
                        uploadDate: '',
                        required: true
                    });
                }

                // Update Firestore with the updated formality
                await updateDoc(userFormalityRef, {
                    [formalityId]: updatedFormality
                });
                updatedFormality["id"] = formalityId;
                setSelectedFormality(updatedFormality)
                console.log("Document updated successfully");
            }
        } catch (error) {
            console.error("Error updating document:", error);
            alert("An error occurred while updating the information. Please try again.");
        }
    };

    useEffect(() => {
        if (selectedFormality) {
            const uid = selectedFormality.id.replace(/\d{14}$/, '');
            const unsubscribe = onSnapshot(doc(db, `formalities/${uid}`), (doc) => {
                if (doc.exists()) {
                    const newFormalities = Object.entries(doc.data()).map(([id, data]) => ({
                        id,
                        ...data
                    }));
                    setFormalities(newFormalities);
                }
            });

            return () => unsubscribe(); // Désabonnement à la désactivation du composant
        }
    }, []);


    const handleFileUploadRemove = async (formalityId, docId) => {
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = "";

                let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
                    if (index === docId) {
                        fileUrlToRemove = document.fileUrl;
                        return { ...document, fileUrl: null, uploaded: false, fileName: "", uploadDate: null };
                    }
                    return document;
                });

                updatedFormality.required_documents = updatedDocuments;

                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                if (fileUrlToRemove) {
                    const filePath = new URL(fileUrlToRemove).pathname.split('/').slice(3).join('/');
                    const fileRef = ref(storage, filePath);
                    try {
                        await deleteObject(fileRef);
                        console.log("Fichier supprimé avec succès du stockage Firebase");
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, required_documents: updatedDocuments };
                        }
                        return formality;
                    });
                });

            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };

    const handleRemoveDocs = async (formalityId, docIndex, titrePiece) => {
        // Récupération de l'UID à partir de l'ID de la formalité
        const uid = formalityId.replace(/\d{14}$/, '');
        const userFormalitiesRef = doc(db, `formalities/${uid}`);

        // Demande de confirmation avant la suppression
        if (!window.confirm(`Êtes-vous sûr de vouloir supprimer le document "${titrePiece}" ?`)) {
            console.log("Suppression annulée par l'utilisateur.");
            return;
        }

        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = updatedFormality.required_documents[docIndex]?.fileUrl;

                // Mise à jour de la liste des documents requis en supprimant l'élément sélectionné
                let updatedDocuments = updatedFormality.required_documents.filter((_, index) => index !== docIndex);

                updatedFormality.required_documents = updatedDocuments;

                // Mise à jour du document Firestore avec les nouveaux détails
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                // Suppression du fichier de Firebase Storage si nécessaire
                if (fileUrlToRemove) {
                    const filePath = new URL(fileUrlToRemove).pathname.split('/').slice(3).join('/');
                    const fileRef = ref(storage, filePath);
                    try {
                        await deleteObject(fileRef);
                        console.log("Fichier supprimé avec succès du stockage Firebase");
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                // Mise à jour de l'état local si utilisé dans un contexte React
                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, required_documents: updatedDocuments };
                        }
                        return formality;
                    });
                });
                updatedFormality["id"] = formalityId;
                setSelectedFormality(updatedFormality)
                updateFormalities(formalityId)

            } else {
                console.log("Aucune donnée existante pour cet UID dans Firestore");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };


    const handleRemoveValidationDocs = async (formalityId, docIndex, titrePiece) => {
        const uid = formalityId.replace(/\d{14}$/, '');
        const userFormalitiesRef = doc(db, `formalities/${uid}`);

        if (!window.confirm(`Êtes-vous sûr de vouloir supprimer le document "${titrePiece}" ?`)) {
            console.log("Suppression annulée par l'utilisateur.");
            return;
        }

        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = "";

                // Suppression du document de la liste validation_documents
                const updatedDocuments = updatedFormality.validation_documents.filter((_, index) => {
                    if (index === docIndex) {
                        fileUrlToRemove = updatedFormality.validation_documents[index].fileUrl;
                        return false; // Supprime l'élément correspondant
                    }
                    return true;
                });

                updatedFormality.validation_documents = updatedDocuments;

                // Mise à jour de Firestore avec les nouveaux détails
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                // Suppression du fichier de Firebase Storage si nécessaire
                if (fileUrlToRemove) {
                    const fileRef = ref(storage, fileUrlToRemove);
                    try {
                        deleteObject(fileRef).then(() => {
                            console.log("Fichier supprimé avec succès du stockage");
                        }).catch((error) => {
                            console.log(error);
                        });;
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                // Mise à jour de l'état local
                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, validation_documents: updatedDocuments };
                        }
                        return formality;
                    });
                });
                updatedFormality["id"] = formalityId;
                setSelectedFormality(updatedFormality);
                updateFormalities(formalityId);
            } else {
                console.log("Aucune donnée existante pour cet UID dans Firestore");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };





    const handleFileUploadValidationDocumentsRemove = async (formalityId, docId, titrePiece) => {
        const uid = formalityId.replace(/\d{14}$/, '');
        const userFormalitiesRef = doc(db, `formalities/${uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = "";

                let updatedDocuments = updatedFormality.validation_documents.map((document, index) => {
                    if (index === docId) {
                        fileUrlToRemove = document.fileUrl;
                        return { ...document, fileUrl: null, uploaded: false, fileName: "", uploadDate: null };
                    }
                    return document;
                });

                updatedFormality.validation_documents = updatedDocuments;

                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                if (fileUrlToRemove) {
                    console.log("fileUrlToRemove handleFileUploadValidationDocumentsRemove : ", fileUrlToRemove);
                    const filePath = new URL(fileUrlToRemove).pathname.split('/').slice(3).join('/');
                    console.log("filePath  handleFileUploadValidationDocumentsRemove: ", filePath);
                    const fileRef = ref(storage, filePath);
                    try {
                        await deleteObject(fileRef);
                        console.log("Fichier supprimé avec succès du stockage Firebase");
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, validation_documents: updatedDocuments };
                        }
                        return formality;
                    });
                });

            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };

    const deleteformalite = async (formalityId) => {
        const uid = formalityId.replace(/\d{14}$/, '');
        const userFormalitiesRef = doc(db, `formalities/${uid}`);

        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                const formalityToDelete = userData[formalityId];

                // Supprimer les fichiers associés dans le stockage Firebase
                if (formalityToDelete && formalityToDelete.validation_documents) {
                    for (const document of formalityToDelete.validation_documents) {
                        if (document.fileUrl) {
                            const filePath = new URL(document.fileUrl).pathname.split('/').slice(3).join('/');
                            const fileRef = ref(storage, filePath);
                            try {
                                await deleteObject(fileRef);
                                console.log(`Fichier ${filePath} supprimé avec succès du stockage Firebase`);
                            } catch (error) {
                                console.error(`Erreur lors de la suppression du fichier ${filePath}:`, error);
                            }
                        }
                    }
                }

                // Supprimer la formalité de Firestore
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: deleteField()
                });

                console.log(`Formalité ${formalityId} supprimée avec succès de Firestore`);

                // Mettre à jour l'état local
                updateFormalities(formalityId);
                // setFormalities(currentFormalities =>
                //     currentFormalities.filter(formality => formality.id !== formalityId)
                // );

                setModalConfiramtionDelete(false)

            } else {
                console.log("Aucun document trouvé pour cet utilisateur");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de la formalité:", error);
            throw error; // Propager l'erreur pour la gestion dans le composant appelant
        }
    };


    // Fonction appelée lorsque l'utilisateur clique sur "Envoyer"
    const handleSendFormality = async (formalityId) => {
        // Référence au document de l'utilisateur dans Firestore
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            // Récupération du document de l'utilisateur
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                // Accès aux données de l'utilisateur
                let userData = docSnap.data();

                // Accès à la formalité spécifique à mettre à jour
                let updatedFormality = userData[formalityId];

                // Mise à jour du statut et de la date d'envoi de la formalité
                updatedFormality.status = "Traitement en cours";
                updatedFormality.sendDate = new Date(); // Utiliser Timestamp de Firestore si nécessaire

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                console.log("Formalité envoyée avec succès");
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la formalité:", error);
        }
    };

    const displayFormattedDate = (timestamp) => {
        // Si timestamp existe, convertissez-le en objet Date et formatez
        if (timestamp) {
            const date = timestamp.toDate();
            return `Envoyé le ${date.toLocaleDateString('fr-FR')}`;
        }
        // Gestion des cas où timestamp n'est pas disponible
        return 'Date non disponible';
    };

    const handleFileChange = (e, formalityId, docIndex, titrePiece, pathStorage) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
            return;
        }
        uploadFile(file, formalityId, docIndex, titrePiece, pathStorage);
    };

    const handleFileChangeValider = async (e, formalityId, titrePiece, urlModel) => {
        console.log("titrePiece: ", titrePiece);

        // Mise à jour de l'état local
        let flagTitleExist = false;
        setFormalities(currentFormalities => currentFormalities.map(formality => {
            if (formality.id === formalityId) {
                let updatedDocuments = formality.required_documents.slice(); // Crée une copie de la liste actuelle

                const existingDocIndex = updatedDocuments.findIndex(doc => doc.titrePiece === titrePiece);

                if (existingDocIndex !== -1) {
                    // Document exists, update it
                    flagTitleExist = true;
                } else {
                    // Document does not exist, add a new document
                    updatedDocuments.push({
                        titrePiece: titrePiece,
                        urlModel: urlModel || '',
                        fileUrl: '',
                        uploaded: false,
                        fileName: '',
                        uploadDate: '',
                        required: true,
                        canDelete: true
                        // Assuming new documents are required by default
                    });
                }

                return { ...formality, required_documents: updatedDocuments };
            }
            return formality;
        }));


        if (flagTitleExist) {
            alert("Un document avec le même titre existe déjà. Veuillez ajouter un autre titre.");
            return;
        }
        // Mise à jour de Firestore
        try {
            const uid = formalityId.replace(/\d{14}$/, '');
            const userFormalityRef = doc(db, `formalities/${uid}`);
            const docSnap = await getDoc(userFormalityRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];

                updatedFormality.required_documents.push({
                    titrePiece: titrePiece,
                    urlModel: urlModel || '',
                    fileUrl: '',
                    uploaded: false,
                    fileName: '',
                    uploadDate: '',
                    required: true,
                    canDelete: true
                });

                // Update Firestore with the updated formality
                updatedFormality.status = "En attente de l’import des pièces et documents";
                updatedFormality.sendDate = null;
                await updateDoc(userFormalityRef, {
                    [formalityId]: updatedFormality
                });
                updatedFormality["id"] = formalityId;
                setSelectedFormality(updatedFormality)
                console.log("Document updated successfully");
            }
        } catch (error) {
            console.error("Error updating document:", error);
            alert("An error occurred while updating the information. Please try again.");
        }

    };

    const uploadFile = (file, formalityId, docIndex, titrePiece, pathStorage) => {

        let storageRef;
        if (pathStorage === "documentsModele") {
            console.log(`templates/${file.name}`);
            storageRef = ref(storage, `templates/${file.name}`);
            let flagTitleExist = false;
            setFormalities(currentFormalities => currentFormalities.map(formality => {
                if (formality.id === formalityId) {
                    const existingDocIndex = formality.required_documents.findIndex(doc => doc.titrePiece === titrePiece);
                    if (existingDocIndex !== -1) {
                        flagTitleExist = true;
                    }
                    return formality;
                }
                return formality;
            }));
            if (flagTitleExist) {
                alert("Un document avec le même titre existe déjà. Veuillez ajouter un autre titre.");
                return;
            }
        } else if (pathStorage === "documentsDeValidation") {
            storageRef = ref(storage, `formalities/${formalityId}/${pathStorage}/${docIndex}-${titrePiece}/${file.name}`);
            let flagTitleExist = false;
            setFormalities(currentFormalities => currentFormalities.map(formality => {
                if (formality.id === formalityId) {
                    const existingDocIndex = formality.validation_documents.findIndex(doc => doc.titrePiece === titrePiece);
                    if (existingDocIndex !== -1) {
                        flagTitleExist = true;
                    }
                    return formality;
                }
                return formality;
            }));
            if (flagTitleExist) {
                alert("Un document avec le même titre existe déjà. Veuillez ajouter un autre titre.");

                return;
            }

        }
        else {
            console.log(`formalities/${formalityId}/${pathStorage}/${docIndex}-${titrePiece}/${file.name}`);
            storageRef = ref(storage, `formalities/${formalityId}/${pathStorage}/${docIndex}-${titrePiece}/${file.name}`);
        }

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const date = new Date()
                    // setFileUrl(downloadURL);
                    // setUploading(false);
                    // onUploadSuccess(downloadURL, file.name, date);
                    console.log("formalityId: ", formalityId);
                    console.log("docIndex: ", docIndex);
                    console.log("downloadURL: ", downloadURL);
                    console.log("file.name: ", file.name);
                    console.log("date: ", date);
                    if (pathStorage === "documents") {
                        handleFileUploadSuccess(formalityId, docIndex, titrePiece, downloadURL, file.name, date)
                    } if (pathStorage === "documentsModele") {
                        handleFileChangeValider(null, formalityId, titrePiece, downloadURL);
                    }
                    else {
                        handleFileUploadValidationDocumentsSuccess(formalityId, docIndex, titrePiece, downloadURL, file.name, date);
                    }

                });
            }
        );
    };

    const isValidFile = (file) => {
        // La taille maximale autorisée en octets (20Mo = 20 * 1024 * 1024 octets)
        const maxSize = 20 * 1024 * 1024;
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        return file.size <= maxSize && allowedTypes.includes(file.type);
    };

    const [documentTitles, setDocumentTitles] = useState({});

    const handleTitleChange = (id, value) => {
        // Mettre à jour le titre spécifique pour la formalité par son ID
        setDocumentTitles(prev => ({ ...prev, [id]: value }));
    };

    const [visibleMenu, setVisibleMenu] = useState(null);

    const toggleMenu = (formalityId) => {
        setVisibleMenu(visibleMenu === formalityId ? null : formalityId); // Toggle le menu visible
    };

    const handleCloseMenu = () => {
        console.log("handleCloseMenu: ", visibleMenu);
        setVisibleMenu(null); // Ferme le menu
    };

    const handleClosureSelected = (formality) => {
        console.log("Clôturer la formalité:", formality);
        setSelectedOnglet("Actes de validation");
        setMessage(messageToUser(formality));
        setModalDossierOpen(!isModalDossierOpen)
        handleCloseMenu(); // Ferme le menu après la sélection
        // handleOpenModal(formality);
    };

    const messageToUser = (formality) => {
        return `Bonjour ${formality.prenom} ${formality.nom},

Nous sommes ravis de vous informer que la formalité concernant ${formality.titreFormalitie} a été complétée avec succès. 

Votre récépissé du greffe a été correctement traité et est désormais disponible. Vous pouvez consulter et télécharger le document officiel directement depuis votre espace client.

Nous vous remercions de votre confiance et restons à votre disposition pour toute question supplémentaire ou pour toute nouvelle demande.

Cordialement,
L'équipe MesFormalités.legal`
    }
    const [message, setMessage] = useState("Message par défaut à transmettre au client");

    const updateFormalityStatus = async (formality, newStatus) => {
        const uid = formality.id.replace(/\d{14}$/, '');
        const formalityRef = doc(db, `formalities/${uid}`);

        try {
            // Récupérer la formalité existante
            const docSnap = await getDoc(formalityRef);

            if (docSnap.exists()) {
                // Mise à jour du statut de la formalité
                let userData = docSnap.data();
                let formalityData = userData[formality.id];

                formalityData.status = newStatus;
                await updateDoc(formalityRef, { [formality.id]: formalityData });
                console.log("Le statut de la formalité a été mis à jour avec succès.");
            } else {
                console.error("La formalité spécifiée n'existe pas dans Firestore.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut de la formalité dans Firestore:", error);
        }
    };

    const forwardToGreffe = (formality) => {
        updateFormalityStatus(formality, "Transmis au greffe")
    }

    const rejectedByGreffe = (formality) => {
        updateFormalityStatus(formality, "Rejeté par le greffe")
    }

    const validedByGreffe = async (formality) => {
        const uid = formality.id.replace(/\d{14}$/, '');
        const formalityRef = doc(db, `formalities/${uid}`);
        try {
            // Récupérer la formalité existante
            const docSnap = await getDoc(formalityRef);
            if (docSnap.exists()) {
                // Mise à jour du statut de la formalité
                let userData = docSnap.data();
                let formalityData = userData[formality.id];

                formalityData.status = "Validé par le greffe";
                const validationDocuments = [
                    {
                        required: true, titrePiece: 'Récipissé du greffe', uploaded: false, fileUrl: '', fileName: '', uploadDate: null
                    }
                    // { required: true, titrePiece: 'Extrait KBIS à jour' },
                    // { titrePiece: 'Extrait RBE à jour' }  // `required` is not specified, it will default to undefined
                ];
                formalityData["validation_documents"] = validationDocuments;
                await updateDoc(formalityRef, { [formality.id]: formalityData });

                console.log("Le statut de la formalité a été mis à jour avec succès.");
            } else {
                console.error("La formalité spécifiée n'existe pas dans Firestore.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut de la formalité dans Firestore:", error);
        }

    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const areAllValidationDocumentsUploaded = (documents) => {
            return documents.every(doc => doc.fileUrl);
        };

        console.log("Formalité terminée avec:", {
            file,
            message,
            formality: selectedFormality
        });
        const payload = {
            email_contact: selectedFormality.email_contact,
            message: message,
            // file: file,
        };
        console.log("payload: ", payload)
        if (selectedFormality && selectedFormality.validation_documents) {
            const allValidationDocsUploaded = areAllValidationDocumentsUploaded(selectedFormality.validation_documents);

            if (!allValidationDocsUploaded) {
                alert("Avant de compléter la formalité, vous devez télécharger tous les actes de validation requis.");
                return;
            }

            fetchData('formality-completed', payload, 'POST', true)
                .then(data => {
                    console.log('Success:', data);
                    updateFormalityStatus(selectedFormality, "Formalités clôtures")
                    setModalOpen(true);

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            console.log("Avant de completé la formalité. Vous devez telecharger les actes de validations requis");
            alert("Avant de completé la formalité. Vous devez telecharger les actes de validations requis");
        }


        // handleCloseModal();
    };
    const order = [
        'En attente du devis',
        'eDevis validés',
        'En attente de l’import des pièces et documents',
        'Traitement en cours',
        'Transmis au greffe',
        'Validé par le greffe',
        'Rejeté par le greffe',
        'Formalités clôtures'
    ];

    const countOccurrences = (formalities) => {
        const counts = {};
        order.forEach(status => {
            counts[status] = 0;
        });

        formalities.forEach((formality) => {
            if (counts.hasOwnProperty(formality.status)) {
                counts[formality.status] += 1;
            }
        });

        let entries = Object.entries(counts);

        console.log("countOccurrences: ", entries);
        return entries;
    };


    const companyCounts = countOccurrences(formalities);
    const [formaliteFilterByStatus, setFormaliteFilterByStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(companyCounts.length > 0 ? companyCounts[0][0] : 'Toutes les formalités');
    const [selectedOnglet, setSelectedOnglet] = useState("Eléments reçus");
    const [selectedOngletElementsContable, setSelectedOngletElementsContable] = useState("eDevis");

    // useEffect(() => {
    //     const unsubscribe = onSnapshot(collection(db, "formalities"), (snapshot) => {
    //         const formalities = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //         setFormalities(formalities);
    //     });

    //     return () => unsubscribe(); // Nettoyage du composant
    // }, []);

    useEffect(() => {

        if (selectedFormality?.status === "En attente du devis" || selectedFormality?.status === "eDevis validés") {
            setSelectedOngletElementsContable("eDevis");
        } else {
            setSelectedOngletElementsContable("Facture")
        }

    }, [selectedFormality])

    const updateFormalities = (formalityId) => {
        setFormalities(currentFormalities => {
            const updatedFormalities = currentFormalities.filter(formality => formality.id !== formalityId);
            // Re-apply filter and sorting if necessary
            applyFilterAndSorting(updatedFormalities);
            return updatedFormalities;
        });
    };

    const applyFilterAndSorting = (formalities) => {
        const filtered = filterFormalities(selectedStatus, formalities);
        const sorted = sortFormalities(filtered);
        setFormaliteFilterByStatus(sorted);
    };

    const filterFormalities = (status, formalities) => {
        if (status !== 'Toutes les formalités') {
            return formalities.filter(f => f.status === status);
        }
        return formalities;
    };

    const filterFormalites = (status) => {
        if (status !== 'Toutes les formalités') {
            const filteredFormalites = formalities.filter(f => f.status === status);
            setFormaliteFilterByStatus(filteredFormalites);
        } else {
            setFormaliteFilterByStatus(formalities);
        }
        setSelectedStatus(status);
    };

    useEffect(() => {
        if (selectedStatus) {
            filterFormalites(selectedStatus);
        }
    }, [selectedStatus]);

    const handleValidation = () => {
        // Logique pour valider la modification
        console.log("Modification validée !");
        // Tu peux ajouter ici la logique pour envoyer les données à un serveur, etc.
    };
    const [editMode, setEditMode] = useState({});

    const handleEditClick = (formalityId) => {
        const currentFormality = formalities.find(f => f.id === formalityId);
        setEditFields({
            ...editFields,
            [formalityId]: {
                nom_msb: currentFormality.nom_msb,
                prenom_msb: currentFormality.prenom_msb
            }
        });
        setEditMode({ ...editMode, [formalityId]: true });
        console.log("currentFormality: ", currentFormality, "\neditMode: ", editMode);
    };

    const [updateComplete, setUpdateComplete] = useState(false);

    useEffect(() => {
        if (updateComplete) {
            setUpdateComplete(false);  // Réinitialisez pour les futures mises à jour
        }
    }, [updateComplete]);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const updateFormality = async (formalityId, newName, newSurname) => {
        // Utilisez directement l'ID sans manipulation inutile si formalityId est déjà correct
        const uid = formalityId.replace(/\d{14}$/, '');
        const formalityRef = doc(db, `formalities/${uid}`);

        try {
            // Mise à jour directe du document sans récupération préalable
            const docSnap = await getDoc(formalityRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let formalityData = userData[formalityId];
                console.log("newName: ", newName, "\nnewSurname: ", newSurname);

                formalityData["nom_msb"] = newName;
                formalityData["prenom_msb"] = newSurname;

                // Enregistrement des mises à jour
                console.log("formalityData: ", formalityData);
                if (typeof newName === 'undefined' || typeof newSurname === 'undefined') {
                    alert('Les valeurs de nom ou prénom sont manquantes.');
                    return;
                }
                await updateDoc(formalityRef, { [formalityId]: formalityData });
                console.log("Le nom et le prénom de la formalité ont été mis à jour avec succès.");
                // forceUpdate()
                updateLocalState(formalityId, newName, newSurname);
                // alert('Mise à jour réussie !');
            } else {
                console.error("La formalité spécifiée n'existe pas dans Firestore.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du nom de la formalité dans Firestore:", error);
            alert('Erreur lors de la mise à jour !');
        }
    };

    // Fonction de callback pour mettre à jour l'état local
    const updateLocalState = (formalityId, newName, newSurname) => {
        const updatedFormalities = formalities.map(formality => {
            if (formality.id === formalityId) {
                return { ...formality, nom_msb: newName, prenom_msb: newSurname };
            }
            return formality;
        });

        setFormalities(updatedFormalities);
        setEditMode(prev => ({ ...prev, [formalityId]: false }));
        setEditFields(prev => {
            const updatedFields = { ...prev };
            delete updatedFields[formalityId];
            return updatedFields;
        });

        alert('Mise à jour réussie !');
    };
    const [editFields, setEditFields] = useState({});

    const handleInputChange = (formalityId, value, type) => {
        setEditFields(prev => ({
            ...prev,
            [formalityId]: {
                ...prev[formalityId],
                [type]: value
            }
        }));
        console.log("handleInputChange editMode: ", editMode);
    };


    console.log("######## selectedFormality: ", selectedFormality)

    if (isMobile && false) {
        // Rendu pour smartphones
        return (
            <div className="mt-6">
                {formalities.map((formality, index) => (
                    <div key={`formality-${index}`} className="mb-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="col-span-2 md:col-span-1 font-bold">Date de demande:</div>
                            <div className="col-span-2 md:col-span-1">{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString()}</div>
                            <div className="col-span-2 font-bold">Formalité:</div>
                            <div className="col-span-2">{formality.categorie} {">"} {formality.titreFormalitie}</div>
                            <div className="col-span-2 font-bold">Dénomination:</div>
                            <div className="col-span-2">{formality.nomEntreprise}</div>
                            <div className="col-span-2 font-bold">Montant HT:</div>
                            <div className="col-span-2">{formality.montantHT} €</div>
                            <div className="col-span-2 font-bold">Contact :</div>
                            <div className="col-span-2">
                                {formality.prenom} {formality.nom}<br />
                                {formality.email_contact}
                            </div>

                            <div className="col-span-2 font-bold">Facture :</div>
                            <div className="col-span-2">
                                <a href={formality.invoice_pdf_url} className="text-blue-600 hover:text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger</a>
                            </div>

                            <div className="col-span-2 font-bold">Statut :</div>
                            <div className={`col-span-2 ${getStatusClass(formality.status)}`}>
                                {formality.status}
                            </div>

                            {formality.sendDate && (
                                <span className="text-blue-logo-color"> {displayFormattedDate(formality.sendDate)}</span>
                            )}

                            <button onClick={() => toggleExpand(formality.id)} className="col-span-2 flex justify-between items-center text-blue-logo-color">
                                <span>Documents & pièces requis ({formality?.upload_number_file || 0} / {formality.required_documents.length})</span>
                                {expandedRows[formality.id] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </button>

                            {/* Contenu dépliable */}
                            {expandedRows[formality.id] && (
                                <div className="col-span-2">
                                    <div className="mt-4">
                                        {formality.required_documents.map((doc, docIndex) => (
                                            <div key={docIndex} className="mb-8">
                                                <div className="mb-2 flex flex-col items-start">
                                                    <label htmlFor={`file-input-${docIndex}`} className="block text-sm bg-white font-bold text-blue-logo-color dark:text-white mb-1">
                                                        {doc.titrePiece}
                                                        {doc.required && <span className="text-red-500">*</span>}
                                                        {doc.urlModel && (
                                                            <a href={doc.urlModel} className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline">
                                                                <BsDownload className='w-5 h-5 mr-2' />
                                                                Télécharger modèle
                                                            </a>
                                                        )}
                                                    </label>
                                                </div>
                                                <FileUploadComponent
                                                    docIndex={docIndex + '-' + doc.titrePiece}
                                                    formalityId={formality.id}
                                                    existingFileUrl={doc.fileUrl}
                                                    existingFileName={doc.fileName}
                                                    existingUploadDate={doc.uploadDate}
                                                    isSent={!!formality.sendDate}
                                                    onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                        console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                        handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
                                                    }}
                                                    onUploadRemove={() => {
                                                        console.log(`Fichier a été supprimé avec succès`);
                                                        handleFileUploadRemove(formality.id, docIndex)
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        // Rendu pour PC
        return (
            <div className="flex transition duration-500 ease-in-out w-full h-screen" onClick={() => { visibleMenu && handleCloseMenu() }}>
                <div className="min-w-72 bg-slate-950 border-l mr-2 border-[#EDF5FE] flex flex-col justify-between h-full">
                    <div>
                        <div className='flex text-white justify-center text-center items-center p-5 font-bold'>
                            {subdomain === "mesformalites" ? (
                                <img src={logoMesFormalites} alt="Logo Oliver" className="mb-2 md:mb-4 w-30 h-20" />
                            ) : (
                                <img src={logoOliver} alt="Logo Oliver" className="mb-2 md:mb-4 w-30 h-20" />
                            )}
                        </div>
                        <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                            <div className={`py-3 px-4 flex justify-between hover:bg-blue-400 ${selectedStatus === "Toutes les formalités" ? "bg-blue-500" : ""}`}
                                onClick={() => filterFormalites("Toutes les formalités")}
                            >
                                <button className="text-white font-bold">Toutes les formalités</button>
                                <button className="text-[#06094F] w-5 h-5 rounded-sm bg-white">{formalities.length}</button>
                            </div>

                            {companyCounts.map(([status, count]) => (
                                <div key={`${status}-${count}`} className={`py-3 px-4 flex justify-between hover:bg-blue-400 ${selectedStatus === status ? "bg-blue-500" : ""}`}
                                    onClick={() => filterFormalites(status)}
                                >
                                    <button className="text-white font-bold">{getStatusMessageLeftMenu(status)}</button>
                                    <button className={`text-[#06094F] w-5 h-5 rounded-sm ${getbgMessageLeftMenu(status)}`}> {count}</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="py-3 px-4 flex justify-between items-center" onClick={handleLogout}>
                        <div className='flex justify-evenly'>
                            <MdLogout fill="#fff" size={30} />
                            <button className="text-white rounded-sm">Se déconnecter</button>
                        </div>
                    </div>
                </div>

                <div className="overflow-auto flex-1 p-2">
                    <h1 className='p-2 text-blue-800 font-bold text-3xl'> Formalités</h1>
                    <div className='p-2'>
                        <input
                            type="search"
                            placeholder="Votre Recherche"
                            className='p-2 input border my-2 w-80 border-slate-500 rounded-lg'
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>


                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-2 rounded-lg overflow-x-hidden bg-white">
                        <thead className="text-xs text-gray-700 border uppercase  bg-white dark:bg-gray-700 dark:text-gray-400">
                            <tr>

                                <th scope="col" className="px-6 py-3 border"></th>
                                <th scope="col" className="px-6 py-3 border">Type de dossier</th>
                                <th scope="col" className="px-6 py-3 border">Nom de la société</th>
                                <th scope="col" className="px-6 py-3 border">Greffe</th>
                                <th scope="col" className="px-6 py-3 border">
                                    Date
                                    <button onClick={() => handleSort('dateReservation')}>🔽</button>
                                </th>
                                {/* <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Montant HT</th> */}
                                <th scope="col" className="px-6 py-3 border">
                                    Statut
                                    <button onClick={() => handleSort('status')}>🔽</button>
                                </th>
                                <th scope="col" className="px-6 py-3 border">Gestionnaire du dossier MSB</th>
                                {/* <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Facture</th> */}
                                <th scope="col" className="px-6 py-3 border">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {sortFormalities(formaliteFilterByStatus).map((formality, index) => (
                                    <React.Fragment key={`fragment-${formality.dateReservation}-${index}`}>
                                        <tr key={`${formality.id}-${index}`} className="bg-white border-4 border-gray-600 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-2 py-2 border cursor-pointer" onClick={() => { handleRowClick(formality) }}><IoDocumentTextSharp size={25} color='#000' /></td>
                                            <td className="px-3 py-2 border cursor-pointer" onClick={() => { handleRowClick(formality) }}>{Capitalize(formality.categorie)} {">"} {Capitalize(formality.titreFormalitie)}</td>
                                            <td className="px-3 py-2 border cursor-pointer" onClick={() => { handleRowClick(formality) }}>{formality.nomEntreprise}</td>
                                            <td className="px-3 py-4 border cursor-pointer" onClick={() => { handleRowClick(formality) }}>Nanterre</td>
                                            <td className="px-3 py-2 border cursor-pointer" onClick={() => { handleRowClick(formality) }}>{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString("fr-FR", {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: false
                                            })}</td>
                                            <td className={`px-3 py-4 border cursor-pointer`} onClick={() => { handleRowClick(formality) }}> <div className={`border font-bold rounded-md p-1 text-center ${getStatusClass(formality.status)}`}>{getStatusMessage(formality.status)}</div></td>
                                            {/* <td className="px-1 py-4 border">
                                                <div className='flex flex-wrap justify-center gap-1 px-1'>
                                                    <div className='relative inline-flex items-center'>
                                                        <input placeholder='Nom' className='px-2 border border-1 border-gray-200 input'
                                                            value={formality?.nom_msb || inputs[formality.id]?.nom_msb || ''}
                                                            onChange={(e) => handleNomChange(formality.id, e.target.value)}
                                                        />
                                                        {!formality?.nom_msb ? (
                                                            <FaCheck color='#0cde64' className='absolute p-1 right-2 cursor-pointer rounded-md border hover:bg-gray-200' size={30} onClick={() => updateFormalityNomMsb(formality, inputs[formality.id]?.nom_msb)} style={{ top: '50%', transform: 'translateY(-50%)' }} />
                                                        ) : (
                                                            <FaRegEdit color='#0cde64' className='absolute p-1 right-2 cursor-pointer rounded-md border hover:bg-gray-200' size={30} onClick={() => resetNomMsb(formality)} style={{ top: '50%', transform: 'translateY(-50%)' }} />
                                                        )}
                                                    </div>
                                                    <div className='inline-flex items-center relative'>
                                                        <input placeholder='Prenom' className='px-2 border border-1 border-gray-200 input'
                                                            value={formality?.prenom_msb || inputs[formality.id]?.prenom_msb || ''}
                                                            onChange={(e) => handlePrenomChange(formality.id, e.target.value)} />
                                                        {!formality?.prenom_msb ? (
                                                            <FaCheck color='#0cde64' className='absolute p-1 right-2 cursor-pointer rounded-md border hover:bg-gray-200' size={30} onClick={() => updateFormalityPrenomMsb(formality, inputs[formality.id]?.prenom_msb)} />
                                                        ) : (
                                                            <FaRegEdit color='#0cde64' className='absolute p-1 right-2 cursor-pointer rounded-md border hover:bg-gray-200' size={30} onClick={() => resetPrenomMsb(formality)} style={{ top: '50%', transform: 'translateY(-50%)' }} />
                                                        )}
                                                    </div>
                                                </div>
                                            </td> */}
                                            <GestionnaireDossier
                                                formality={formality}
                                                updateFormality={updateFormality}
                                            />

                                            {/* <td className="px-6 py-4">
                                            {(<a href={formality.invoice_pdf_url} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger</a>)}
                                            </td> */}
                                            <td className="px-3 py-4 border relative">
                                                <button onClick={() => toggleMenu(formality.id)}>
                                                    <CiMenuKebab size={25} />
                                                </button>
                                                {visibleMenu === formality.id && getStatusMessage(formality.status) === "A traiter" && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                                        <ul className="text-gray-700">
                                                            <li>
                                                                <button
                                                                    onClick={() => forwardToGreffe(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Transmis au greffe
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => rejectedByGreffe(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Rejecter le dossier
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        setSelectedFormality(formality);
                                                                        setModalConfiramtionDelete(true);
                                                                        console.log('supprimer');
                                                                    }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Supprimer
                                                                </button>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {visibleMenu === formality.id && getStatusMessage(formality.status) === "En attente des pièces" && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                                        <ul className="text-gray-700">
                                                            <li>
                                                                <button
                                                                    // onClick={() => handleClosureSelected(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Relancer le client
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => rejectedByGreffe(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Rejecter le dossier
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        setSelectedFormality(formality);
                                                                        setModalConfiramtionDelete(true);
                                                                        console.log('supprimer');
                                                                    }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Supprimer
                                                                </button>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {visibleMenu === formality.id && getStatusMessage(formality.status) === "Demande eDevis" && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                                        <ul className="text-gray-700">
                                                            <li>
                                                                <button
                                                                    onClick={() => { handleRowClick(formality) }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Valider le eDevis
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => { setSelectedFormality(formality); setModalConfiramtionDelete(true); console.log('supprimer'); }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Supprimer
                                                                </button>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {visibleMenu === formality.id && getStatusMessage(formality.status) === "eDevis validés" && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                                        <ul className="text-gray-700">
                                                            <li>
                                                                <button
                                                                    onClick={() => { handleRowClick(formality) }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Valider le eDevis
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => { setSelectedFormality(formality); setModalConfiramtionDelete(true); console.log('supprimer'); }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Supprimer
                                                                </button>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {visibleMenu === formality.id && getStatusMessage(formality.status) === "Formalités à clôturer" && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                                        <ul className="text-gray-700">
                                                            <li>
                                                                <button
                                                                    onClick={() => handleClosureSelected(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Clôturer la formalité
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => { setSelectedFormality(formality); setModalConfiramtionDelete(true); console.log('supprimer'); }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Supprimer
                                                                </button>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {visibleMenu === formality.id && getStatusMessage(formality.status) === "Traitement en cours" && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                                        <ul className="text-gray-700">
                                                            <li>
                                                                <button
                                                                    onClick={() => validedByGreffe(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Validé par le greffe
                                                                </button>

                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => rejectedByGreffe(formality)}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Rejeté par le greffe
                                                                </button>

                                                            </li>

                                                            <li>
                                                                <button
                                                                    onClick={() => { setSelectedFormality(formality); setModalConfiramtionDelete(true); console.log('supprimer'); }}
                                                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                                >
                                                                    Supprimer
                                                                </button>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>

                                        </tr>

                                    </React.Fragment>
                                ))
                                }
                            </>
                        </tbody >
                    </table >
                    {modalConfiramtionDelete &&
                        (
                            <Modal
                                title=""
                                modalOpen={modalConfiramtionDelete}
                                setModalOpen={setModalConfiramtionDelete}
                                modalSize={"auto"}
                            >
                                <div className="flex flex-col items-center justify-center p-4">
                                    <img src={`${subDomains.includes(subdomain) ? logoMesFormalitesClair : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />

                                    <p className="text-center mb-6"> Etes-vous sûr de vouloir supprimer cette formalité ?</p>
                                    <div className='flex justify-between gap-4'>

                                        <button
                                            onClick={() => {
                                                setModalConfiramtionDelete(false)
                                            }}
                                            className="signupFormContainerContinuer text-white bg-red-500 font-bold"
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            onClick={() => {
                                                deleteformalite(selectedFormality.id)
                                            }}
                                            className="signupFormContainerContinuer text-white font-bold"
                                        >
                                            Oui
                                        </button>
                                    </div>
                                </div>
                            </Modal>

                        )
                    }
                    {isModalDossierOpen && (
                        <>
                            <Modal
                                title={`[${selectedFormality.nomEntreprise}]  ${selectedFormality.titreFormalitie}`}
                                modalOpen={isModalDossierOpen}
                                setModalOpen={setModalDossierOpen}
                                modalSize={"large"}
                            >
                                <div className="flex flex-col w-full">
                                    <div className="bg-slate-950 border-b border-[#EDF5FE]">
                                        <div className="flex justify-start text-white">
                                            <div className={`py-3 px-4 hover:bg-blue-400 cursor-pointer ${selectedOnglet === "Général" ? "bg-blue-500" : "bg-slate-950"} flex items-center`}
                                                onClick={() => setSelectedOnglet("Général")}
                                            >
                                                <button className="font-bold">Général</button>
                                            </div>
                                            <div className={`py-3 px-4 hover:bg-blue-400 cursor-pointer ${selectedOnglet === "Eléments reçus" ? "bg-blue-500" : "bg-slate-950"} flex items-center`}
                                                onClick={() => setSelectedOnglet("Eléments reçus")}
                                            >
                                                <button className="font-bold">Éléments reçus</button>
                                            </div>
                                            <div className={`py-3 px-4 hover:bg-blue-400 cursor-pointer ${selectedOnglet === "Éléments comptables" ? "bg-blue-500" : "bg-slate-950"} flex items-center`}
                                                onClick={() => setSelectedOnglet("Éléments comptables")}
                                            >
                                                <button className="font-bold">Éléments comptables</button>
                                            </div>
                                            {(getStatusMessage(selectedFormality.status) === "Formalités à clôturer" || getStatusMessage(selectedFormality.status) === "Formalités clôtures") && (
                                                <>
                                                    <div className={`py-3 px-4 hover:bg-blue-400 cursor-pointer ${selectedOnglet === "Actes de validation" ? "bg-blue-500" : "bg-slate-950"} flex items-center`}
                                                        onClick={() => { setSelectedOnglet("Actes de validation"); }}
                                                    >
                                                        <button className="font-bold">Actes de validation</button>
                                                    </div>
                                                    {getStatusMessage(selectedFormality.status) === "Formalités à clôturer" && (
                                                        <div className={`py-3 px-4 hover:bg-blue-400 cursor-pointer ${selectedOnglet === "Message de clôture" ? "bg-blue-500" : "bg-slate-950"} flex items-center`}
                                                            onClick={() => { setSelectedOnglet("Message de clôture"); setMessage(messageToUser(selectedFormality)); }}
                                                        >
                                                            <button className="font-bold">Message de clôture</button>
                                                        </div>
                                                    )
                                                    }
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`overflow-x-auto px-4 w-full ${selectedOnglet === "Éléments comptables" ? "" : "flex justify-center  mt-6"}`}>
                                        {selectedOnglet === "Général" && (
                                            <div className='py-4 px-2 w-full mx-auto'>
                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-2 rounded-lg overflow-x-hidden bg-white">
                                                    <thead className="text-xs text-gray-700 border uppercase  bg-white dark:bg-gray-700 dark:text-gray-400">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3 border">Nom de la société</th>
                                                            <th scope="col" className="px-6 py-3 border">Type de dossier</th>
                                                            <th scope="col" className="px-6 py-3 border">Greffe</th>
                                                            <th scope="col" className="px-6 py-3 border">
                                                                Date
                                                                <button onClick={() => handleSort('dateReservation')}>🔽</button>
                                                            </th>
                                                            {/* <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Montant HT</th> */}
                                                            <th scope="col" className="px-6 py-3 border">
                                                                Statut
                                                                <button onClick={() => handleSort('status')}>🔽</button>
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 border">Contact</th>
                                                            {/* <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Facture</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <React.Fragment key={`fragment-${selectedFormality.dateReservation}`}>
                                                            <tr key={`${selectedFormality.id}`} className="bg-white border-4 border-gray-600 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                                <td className="px-3 py-2 border cursor-pointer">{selectedFormality.nomEntreprise}</td>
                                                                <td className="px-3 py-2 border cursor-pointer">{Capitalize(selectedFormality.categorie)} {">"} {Capitalize(selectedFormality.titreFormalitie)}</td>
                                                                <td className="px-3 py-4 border cursor-pointer">Nanterre</td>
                                                                <td className="px-3 py-2 border cursor-pointer">{new Date(selectedFormality.dateReservation.seconds * 1000).toLocaleDateString("fr-FR", {
                                                                    day: '2-digit',
                                                                    month: '2-digit',
                                                                    year: 'numeric',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    second: '2-digit',
                                                                    hour12: false
                                                                })}</td>
                                                                <td className={`px-3 py-4 border cursor-pointer`}> <div className={`border font-bold rounded-md p-1 text-center ${getStatusClass(selectedFormality.status)}`}>{getStatusMessage(selectedFormality.status)}</div></td>
                                                                <td className="px-1 py-4 border">
                                                                    {selectedFormality.prenom} {selectedFormality.nom}<br />
                                                                    {selectedFormality.email_contact}<br />
                                                                    {selectedFormality.telephone}
                                                                </td>
                                                            </tr>

                                                        </React.Fragment>
                                                    </tbody>
                                                </table >
                                            </div>
                                        )}
                                        {selectedOnglet === "Eléments reçus" && (
                                            <div className='py-4 px-2 w-full mx-auto'>
                                                {selectedFormality.required_documents.map((doc, docIndex) => (
                                                    <div key={docIndex} className="mb-8">
                                                        <div className="mb-2 flex flex-col items-start">
                                                            <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                                {doc.titrePiece}
                                                                {doc.required && <span className="text-red-500">*</span>}
                                                                {doc.urlModel && (
                                                                    <a href={doc.urlModel}
                                                                        className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        <BsDownload className='w-5 h-5 mr-2' />
                                                                        Télécharger modèle
                                                                    </a>
                                                                )}
                                                            </label>
                                                        </div>
                                                        <FileUploadComponent
                                                            docIndex={docIndex + '-' + doc.titrePiece}
                                                            formalityId={selectedFormality.id}
                                                            existingFileUrl={doc.fileUrl}
                                                            existingFileName={doc.fileName}
                                                            existingUploadDate={doc.uploadDate}
                                                            isSent={false}
                                                            canDelete={doc?.canDelete}
                                                            isFormalitesDone={getStatusMessage(selectedFormality.status) === "Formalités clôtures"}
                                                            registerFormalaties={true}
                                                            clientSendAllDocs={selectedFormality?.sendDate}
                                                            onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                                console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                                handleFileUploadSuccess(selectedFormality.id, docIndex, downloadURL, fileName, uploadDate)
                                                            }}
                                                            onUploadRemove={() => {
                                                                console.log(`Fichier a été supprimé avec succès`);
                                                                handleFileUploadRemove(selectedFormality.id, docIndex)
                                                            }}
                                                            handleRemoveDocs={() => {
                                                                handleRemoveDocs(selectedFormality.id, docIndex, doc.titrePiece)
                                                            }}
                                                            pathStorage={"documents"}
                                                        />
                                                    </div>
                                                ))}

                                                {["A traiter", "En attente des pièces", "Traitement en cours"].includes(getStatusMessage(selectedFormality.status)) && (

                                                    <>
                                                        <label className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                            Nouveau document
                                                        </label>
                                                        <div key={selectedFormality.id} className='flex w-full items-center gap-2'>
                                                            <input
                                                                type='text'
                                                                style={{ flex: '3' }}
                                                                value={documentTitles[selectedFormality.id] || ''} // Utiliser l'ID de la formalité pour différencier les états
                                                                onChange={(e) => handleTitleChange(selectedFormality.id, e.target.value)}
                                                                placeholder='Titre du document...'
                                                                className='bg-white border-slate-400 border-2 rounded-lg p-3 text-black flex-grow mr-2'
                                                                id={`inputTitreDocument-${selectedFormality.id}`}
                                                            />
                                                            <label
                                                                className="bg-blue-500 text-white rounded-lg tracking-wide cursor-pointer hover:bg-blue-300 text-center flex-shrink-0 p-3"
                                                                style={{ flex: '1' }}
                                                                onClick={(e) => {
                                                                    const titrePiece = documentTitles[selectedFormality.id];
                                                                    handleFileChangeValider(e, selectedFormality.id, titrePiece, null);
                                                                }}
                                                            >
                                                                <span className="text-base leading-normal">Valider </span>

                                                            </label>
                                                            <label
                                                                className="bg-blue-500 text-white rounded-lg tracking-wide cursor-pointer hover:bg-blue-300 text-center flex-shrink-0 p-3"
                                                                style={{ flex: '1' }}>
                                                                <span className="text-base leading-normal">Télécharger un modèle</span>
                                                                <input
                                                                    type="file"
                                                                    className="hidden"
                                                                    disabled={!documentTitles[selectedFormality.id]}
                                                                    onChange={(e) => {
                                                                        const titrePiece = documentTitles[selectedFormality.id];
                                                                        handleFileChange(e, selectedFormality.id, selectedFormality.required_documents.length, titrePiece, "documentsModele");
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </>
                                                )}


                                            </div>
                                        )}
                                        {getStatusMessage(selectedFormality.status) === "Formalités à clôturer" && selectedOnglet === "Message de clôture" && (
                                            <div className='py-4 px-2 w-full mx-auto'>
                                                <div className="flex flex-col items-center justify-center p-4">
                                                    <textarea
                                                        className="w-full h-96 p-1 mr-2 input input-bordered border-black mb-3"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                    <button type="submit"
                                                        className="text-white my-3 rounded-lg border cursor-pointer text-center flex-shrink-0 p-3 flex items-center bg-green-500 hover:bg-green-600"
                                                        onClick={handleFormSubmit}

                                                    >Envoyer le message et clôturer la formalité</button>

                                                </div>
                                            </div>
                                        )}

                                        {(getStatusMessage(selectedFormality.status) === "Formalités à clôturer" || getStatusMessage(selectedFormality.status) === "Formalités clôtures") && selectedOnglet === "Actes de validation" && (
                                            <div className='py-4 px-2 w-full mx-auto'>
                                                {console.log("selectedFormality.validation_documents: ", selectedFormality.validation_documents)}
                                                {selectedFormality.validation_documents.map((doc, docIndex) => (
                                                    <div key={docIndex} className="mb-8">
                                                        <div className="mb-2 flex flex-col items-start">
                                                            <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                                {doc.titrePiece}
                                                                {doc.required && <span className="text-red-500">*</span>}
                                                            </label>
                                                        </div>
                                                        <FileUploadComponent
                                                            docIndex={docIndex + '-' + doc.titrePiece}
                                                            formalityId={selectedFormality.id}
                                                            existingFileUrl={doc.fileUrl}
                                                            existingFileName={doc.fileName}
                                                            existingUploadDate={doc.uploadDate}
                                                            isSent={false}
                                                            registerFormalaties={false}
                                                            canDelete={doc?.canDelete}
                                                            isFormalitesDone={getStatusMessage(selectedFormality.status) === "Formalités clôtures"}
                                                            onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                                console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                                handleFileUploadValidationDocumentsSuccess(selectedFormality.id, docIndex, doc.titrePiece, downloadURL, fileName, uploadDate)
                                                            }}
                                                            onUploadRemove={() => {
                                                                console.log(`Fichier a été supprimé avec succès: ${selectedFormality.id}`);
                                                                handleFileUploadValidationDocumentsRemove(selectedFormality.id, docIndex, doc.titrePiece)
                                                            }}
                                                            handleRemoveDocs={() => {
                                                                handleRemoveValidationDocs(selectedFormality.id, docIndex, doc.titrePiece)
                                                            }}
                                                            pathStorage={"documentsDeValidation"}
                                                        />
                                                    </div>
                                                ))}

                                                {["Formalités à clôturer"].includes(getStatusMessage(selectedFormality.status)) && (
                                                    <>

                                                        <label className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                            Nouveau document
                                                        </label>
                                                        <div key={selectedFormality.id} className='flex w-full items-center'>
                                                            <input
                                                                type='text'
                                                                style={{ flex: '3' }}
                                                                value={documentTitles[selectedFormality.id] || ''} // Utiliser l'ID de la formalité pour différencier les états
                                                                onChange={(e) => handleTitleChange(selectedFormality.id, e.target.value)}
                                                                placeholder='Titre du document...'
                                                                className='bg-white border-slate-400 border-2 rounded-lg p-3 text-black flex-grow mr-2'
                                                                id={`inputTitreDocument-${selectedFormality.id}`}
                                                            />
                                                            <label
                                                                className="bg-blue-500 text-white rounded-lg tracking-wide cursor-pointer hover:bg-blue-300 text-center flex-shrink-0 p-3"
                                                                style={{ flex: '1' }}>
                                                                <span className="text-base leading-normal">Chercher ...</span>
                                                                <input
                                                                    type="file"
                                                                    className="hidden"
                                                                    disabled={!documentTitles[selectedFormality.id]}
                                                                    onChange={(e) => {
                                                                        const titrePiece = documentTitles[selectedFormality.id];
                                                                        console.log("titrePiece:", titrePiece);
                                                                        handleFileChange(e, selectedFormality.id, selectedFormality.validation_documents.length, titrePiece, "documentsDeValidation");
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </>
                                                )}


                                            </div>
                                        )}
                                        {selectedOnglet === "Éléments comptables" && (
                                            <>
                                                <nav class="items-start">
                                                    <div class="max-w-screen-xl px-4 py-3 mx-auto">
                                                        <div class="flex items-center">
                                                            <ul class="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
                                                                <li>
                                                                    <button
                                                                        className={`text-xl font-semibold text-blue-600 hover:border-b `}
                                                                        onClick={() => {
                                                                            setSelectedOngletElementsContable("eDevis");
                                                                            // if (getStatusMessage(selectedFormality.status) === "Demande eDevis" ||
                                                                            //     getStatusMessage(selectedFormality.status) === "eDevis validés") {

                                                                            // }
                                                                        }}>
                                                                        eDevis
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button
                                                                        className={`text-xl font-semibold ${getStatusMessage(selectedFormality.status) !== "Demande eDevis" ? "text-blue-600 hover:border-b " : "text-gray-400 cursor-not-allowed"}`}
                                                                        onClick={() => {
                                                                            if (getStatusMessage(selectedFormality.status) !== "Demande eDevis") {
                                                                                setSelectedOngletElementsContable("Facture")
                                                                            }
                                                                        }}>
                                                                        Facture
                                                                    </button>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </nav>
                                                <div className="flex flex-col p-1 px-4 bg-white rounded-lg">
                                                    {selectedOngletElementsContable === "eDevis" && getStatusMessage(selectedFormality.status) === "Demande eDevis" &&
                                                        (
                                                            <EditableeDevisTable
                                                                key={`${getStatusMessage(selectedFormality.status)}-${selectedFormality.id}`}
                                                                formality={selectedFormality}
                                                                setModalDossierOpen={setModalDossierOpen}
                                                                updateFormalityStatus={updateFormalityStatus}
                                                                eDevisIsSand={false}
                                                                updateFormalities={updateFormalities}
                                                            />
                                                        )
                                                    }
                                                    {selectedOngletElementsContable === "eDevis" && getStatusMessage(selectedFormality.status) !== "Demande eDevis" &&
                                                        (
                                                            <EditableeDevisTable
                                                                key={`${getStatusMessage(selectedFormality.status)}-${selectedFormality.id}`}
                                                                rowsAccompanyingCostsA={selectedFormality?.eDevis?.rowsAccompanyingCosts}
                                                                rowsLegalCostsA={selectedFormality?.eDevis?.rowsLegalCosts}
                                                                formality={selectedFormality}
                                                                updateFormalityStatus={updateFormalityStatus}
                                                                eDevisIsSand={true}
                                                            />
                                                        )
                                                    }

                                                    {selectedOngletElementsContable === "Facture" && (
                                                        <>
                                                            {selectedFormality.invoice_pdf_url ? (
                                                                <>
                                                                    <div className="text-base text-gray-500">Facture de vente générée automatiquement suite à la souscription </div>
                                                                    <div className={`bg-gray-100 rounded-lg p-2 flex flex-col items-center`}>
                                                                        <div className="mt-1 w-full">
                                                                            <div className="flex justify-start items-center">
                                                                                <span className="text-base leading-normal flex items-center font-bold mr-2">
                                                                                    <BsDownload className="mr-2" />
                                                                                    {selectedFormality.invoice_pdf_url.split('/')[selectedFormality.invoice_pdf_url.split('/').length - 1]}
                                                                                </span>
                                                                                {/* <span className="text-sm">
                                                                    (importé le {uploadDate ? uploadDate.toLocaleDateString() : existingUploadDate.toLocaleDateString()} à {uploadDate ? uploadDate.toLocaleTimeString() : existingUploadDate.toLocaleTimeString()})
                                                                </span> */}
                                                                            </div>
                                                                            <div className="mt-2 flex justify-start">
                                                                                <a href={selectedFormality.invoice_pdf_url} className="text-blue-600 hover:text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">
                                                                                    Télécharger
                                                                                </a>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="text-base text-gray-500">En attente ...</div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </div>
                            </Modal>

                            <Modal
                                title=""
                                modalOpen={isModalOpen}
                                setModalOpen={setModalOpen}
                                modalSize={"auto"}
                            >
                                <div className="flex flex-col items-center justify-center p-4">
                                    <img src={`${subDomains.includes(subdomain) ? logoMesFormalitesClair : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />

                                    <p className="text-center mb-6"> Formalité clôturée avec succès </p>
                                    <button
                                        onClick={() => {
                                            setModalDossierOpen(false)
                                            setModalOpen(false)
                                        }}
                                        className="signupFormContainerContinuer text-white font-bold"
                                    >
                                        OK
                                    </button>
                                </div>
                            </Modal>
                        </>
                    )}


                </div >
            </div >
        );
    }
};



export default RegistreFormalitiesTable;
