import React, { useState, useEffect } from "react";
import _ from "lodash";
import { fetchData } from "../../utils/ask_api";
import { AiOutlineLoading } from "react-icons/ai";
import formatInfos from "../../utils/anpi";

import {
  createMessage,
  update_last_system_message_tokens,
  updateMessage,
} from "../../utils/conversation";
import { useAppContext } from "../../context/AppContext";

function InfosInput() {
  const [selected, setSelected] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { setMessages } = useAppContext();

  const printWordByWord = async (sentence) => {
    const words = sentence.split(" ");
    for (const word of words) {
      // Generate a random timeout between 1 and 5 seconds (in milliseconds)
      const randomTimeout = Math.floor(Math.random() * 10) + 15;
      await new Promise((resolve) => setTimeout(resolve, randomTimeout));
      update_last_system_message_tokens(`${word} `, setMessages);
    }
  };
  
  function filter_data(data) {
    const results = [];
    data.forEach((t) => {
      try {
        var r = "";
        if ("personneMorale" in t.formality.content) {
          r =
            t.formality.content.personneMorale.identite.entreprise.denomination;
        } else if ("exploitation" in t.formality.content) {
          r = t.formality.content.exploitation.identite.entreprise.denomination;
        } else {
          const entrepreneur =
            t.formality.content.personnePhysique.identite.entrepreneur
              .descriptionPersonne;
          r = entrepreneur.nom + " " + entrepreneur.prenoms[0];
        }
        results.push({ denomination: r, siren: t.formality.siren });
      } catch (error) {
        console.log(t);
      }
    });
    return results;
  }

  const handleSelect = (result, filtered_result) => {
    setSelected(true);

    var newMsg = createMessage(`${filtered_result.denomination}, ${filtered_result.siren}`, "user", undefined, {}, -2);
    updateMessage(newMsg, setMessages);
    
    newMsg = createMessage("", "system", undefined, {"company_siren" : filtered_result.siren}, -3);
    updateMessage(newMsg, setMessages);
    const formated_data = formatInfos(result);
    printWordByWord(formated_data);
    console.log(formated_data);
  };

  const fetchResults = async () => {
    setIsLoading(true);
    if (inputValue) {
      // Replace with your API call logic
      const payload = { query: inputValue };
      const data = await fetchData("get_compagnie_infos", payload, "POST");
      setResults(data);
    } else {
      setResults([]);
    }
    setIsLoading(false);
  };

  // Debounce the fetchResults function
  const debouncedFetchResults = _.debounce(fetchResults, 500); // 500ms delay

  useEffect(() => {
    debouncedFetchResults();

    // Cleanup the debounce function on component unmount
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [inputValue]); // Effect runs when inputValue changes

  // Handle input field changes
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      { !selected &&
        <div className="w-full py-4 flex justify-center">
          <div className="lg:w-2/3 md:w-full w-[90%]">
            <div className="relative w-full flex flex-col">
              <input
                className="lg:h-10 lg:input-lg input rounded-lg w-full italic-placeholder"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Dénomination ou Siren de l'entreprise..."
              />
              {isLoading && (
                <div className="absolute right-0 top-0 h-full flex items-center pr-3">
                  <AiOutlineLoading className="animate-spin" />
                </div>
              )}
              <ul className="absolute w-full top-full left-0 max-h-[200px] mt-1 overflow-x-hidden overflow-y-auto rounded-lg bg-white">
                {filter_data(results).map((result, index) => (
                  <li
                    className="w-full py-2 border border-x-0 hover:bg-slate-200 lg:px-6 px-4 cursor-pointer"
                    key={index}
                    onClick={() => handleSelect(results[index], result)}
                  >
                    <div>
                      <p className="text-sm">{result.denomination}</p>
                      <p className="italic text-xs">{result.siren}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      }
    </>
  );
}



export default InfosInput;
