import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import Modal from '../../components/Modal';
import FileUploadKnowledgebase from "./Knowledgebase";
import { MdOutlineDriveFolderUpload } from 'react-icons/md';
import logoInfogreffe from "../../assets/logoOliver_vanillaDark3.jpg";
import logoMesFormalites from "../../assets/MesFormalites.Legal4.png";


const NestedCheckboxHelper = ({ nodes, ancestors, onBoxChecked, names }) => {
    const { isAdmin, subDomains, subdomain } = useAppContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalLabel, setModalLabel] = useState('');

    const formatDate = (date) => {
        if (!date) return "";
        return new Date(date.seconds * 1000).toLocaleDateString();
    };

    const handleOpenModal = (label) => {
        setModalLabel(label);
        setModalOpen(true);
    };

    const prefix = ancestors.join(".");
    const whiteListParentId = [
        "economiegouvfr__fiches_pratiques_entreprises",
        "economiegouvfr__fiches_pratiques_particuliers",
        "travailemploigouvfr",
        "ecologiegouvfr__politiques_publiques",
        "service_public",
        "jurisprudence_anil",
        "legifrance",
        "legitext000006074069",
        "legitext000006075116",
        "legitext000006073984",
        "legitext000006074234",
        "legitext000020908868",
        "legitext000006070721",
        "legitext000037701019",
        "legitext000005634379",
        "legitext000006070162",
        "legitext000006070300",
        "legitext000006069565",
        "legitext000006074096",
        "legitext000006071307",
        "legitext000006074232",
        "legitext000006071188",
        "legitext000006070208",
        "legitext000006074235",
        "legitext000006074237",
        "legitext000006071570",
        "legitext000006071645",
        "legitext000006071191",
        "legitext000006070239",
        "legitext000023983208",
        "legitext000006070158",
        "legitext000006074220",
        "legitext000006074224",
        "legitext000006072637",
        "legitext000025244092",
        "legitext000044416551",
        "legitext000006070299",
        "legitext000006070633",
        "legitext000006069577",
        "legitext000006069568",
        "legitext000006069569",
        "legitext000006069574",
        "legitext000006069576",
        "legitext000044595989",
        "legitext000006070666",
        "legitext000006070249",
        "legitext000006070933",
        "legitext000006071360",
        "legitext000039086952",
        "legitext000006071007",
        "legitext000006069583",
        "legitext000006071785",
        "legitext000023501962",
        "legitext000006072026",
        "legitext000006074067",
        "legitext000006071164",
        "legitext000006074236",
        "legitext000006070719",
        "legitext000045476241",
        "legitext000006070302",
        "legitext000006074066",
        "legitext000006074068",
        "legitext000006074233",
        "legitext000006070987",
        "legitext000006070716",
        "legitext000006071154",
        "legitext000025024948",
        "legitext000006069414",
        "legitext000006071190",
        "legitext000031366350",
        "legitext000006074228",
        "legitext000006071366",
        "legitext000006071367",
        "legitext000006072665",
        "legitext000025503132",
        "legitext000006073189",
        "legitext000006071335",
        "legitext000006071318",
        "legitext000006074073",
        "legitext000023086525",
        "legitext000006072052",
        "legitext000006072051",
        "legitext000006074075",
        "legitext000006070667",
        "dgccrf",
        "impotsgouvfr_actualite",
        "impotsgouvfr_questions",
        "avis_de_coordination_du_registre_du_commerce_et_des_societes_ccrcs",
        "guide_des_formalites_infogreffe",
        "signatures-service-client_infogreffe"
    ];
    console.log("NestedCheckboxHelper: ", nodes);
    // console.log(ancestors);
    // console.log(onBoxChecked);
    // console.log(names);
    // console.log("NestedCheckboxHelper: FIN");
    return (
        <ul>
            {nodes.map(({ label, checked, childrenNodes }) => {
                const id = `${prefix}.${label}`;
                const children = childrenNodes.length > 0 && (
                    <NestedCheckboxHelper
                        nodes={childrenNodes}
                        ancestors={[...ancestors, label]}
                        onBoxChecked={onBoxChecked}
                        names={names}
                    />
                );

                return (
                    <li key={id} className="pl-[10px]">
                        <input
                            type="checkbox"
                            name={id}
                            id={id}
                            value={label}
                            checked={checked}
                            onChange={(e) => onBoxChecked(e.target.checked, label, ancestors)}
                        />
                        <label className="pl-2 w-[90%]" htmlFor={id}>
                            {names[label]?.titre}
                            {isAdmin && whiteListParentId.includes(label) && (
                                <span className="text-xs ml-2 whitespace-nowrap italic overflow-hidden text-ellipsis">
                                    Dernière mise à jour le {formatDate(names[label]?.date_update)}
                                </span>
                            )}
                        </label>
                        {isAdmin && whiteListParentId.includes(label) && (
                            <>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenModal(label);
                                    }}
                                    className="ml-8"
                                    style={{ transform: 'translateY(6px)' }}
                                >
                                    <MdOutlineDriveFolderUpload size={25} />
                                </button>
                                <Modal
                                    title=""
                                    modalOpen={isModalOpen}
                                    setModalOpen={setModalOpen}
                                    modalSize="large"
                                >
                                    <div className="flex flex-col items-center justify-center p-4">
                                        <img src={`${subDomains.includes(subdomain) ? logoMesFormalites : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />
                                        <p className="m-2">Source pour {names[modalLabel]?.titre}</p>
                                        <FileUploadKnowledgebase label={modalLabel} infosFile={names[modalLabel]} />
                                    </div>
                                </Modal>
                            </>
                        )}
                        {children}
                    </li>
                );
            })}
        </ul>
    );
};

export default NestedCheckboxHelper;
