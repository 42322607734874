import { useState, useEffect } from 'react';
import NestedCheckboxHelper from './NestedCheckboxHelper';

const transform = (data, parent) => {
    return Object.keys(data).map((key) => {
        const value = data[key];
        const node = {
            label: key,
            checked: false,
            childrenNodes: [],
            parent: parent,
        };

        if (typeof value === "boolean") {
            node.checked = value;
        } else {
            const children = transform(value, node);
            node.childrenNodes = children;
            if (children.every((node) => node.checked)) {
                node.checked = true;
            }
        }

        return node;
    });
};

const transformToOriginalStructure = (nodes) => {
    const rebuildObject = (nodes, result = {}) => {
        nodes.forEach(node => {
            if (node.childrenNodes && node.childrenNodes.length > 0) {
                // If the node has children, recursively rebuild the object
                result[node.label] = rebuildObject(node.childrenNodes);
            } else {
                // If the node has no children, it is a leaf node
                result[node.label] = node.checked;
            }
        });
        return result;
    };

    return rebuildObject(nodes);
};

const updateAncestors = (node) => {
    if (!node.parent) {
        return;
    }

    const parent = node.parent;
    if (parent.checked && !node.checked) {
        parent.checked = false;
        updateAncestors(parent);
        return;
    }

    if (!parent.checked && node.checked) {
        if (parent.childrenNodes.every((node) => node.checked)) {
            parent.checked = true;
            updateAncestors(parent);
            return;
        }
    }

    return;
};

const toggleDescendants = (node) => {
    const checked = node.checked;

    node.childrenNodes.forEach((node) => {
        node.checked = checked;
        toggleDescendants(node);
    });
};

const findNode = (nodes, label, ancestors) => {
    let node = undefined;
    if (ancestors.length === 0) {
        return nodes.filter((node) => node.label === label)[0];
    }

    for (let ancestor of ancestors) {
        const candidates = node ? node.childrenNodes : nodes;
        node = candidates.filter((node) => node.label === ancestor)[0];
    }
    return node?.childrenNodes.filter((node) => node.label === label)[0];
};


const NestedCheckbox = ({ data, setData, names }) => {
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        const newData = transform(data);
        setNodes(newData);
    }, [data]);

    const handleBoxChecked = (checked, value, ancestors) => {
        const node = findNode(nodes, value, ancestors);

        node.checked = checked;
        toggleDescendants(node);
        updateAncestors(node);

        const newNodes = [...nodes];
        setNodes(newNodes);

        const newData = transformToOriginalStructure(newNodes);
        setData(newData);
    };

    return (
        <NestedCheckboxHelper
            nodes={nodes}
            ancestors={[]}
            onBoxChecked={handleBoxChecked}
            names={names}
        />
    );
};

export default NestedCheckbox;
